import Prismic from "@prismicio/client";
import { RawPrismicApp } from "../types";
import { useEffect, useState } from "react";

const PRISMIC_API_ENDPOINT = "https://screencloud.cdn.prismic.io/api/v2";
export const PrismicClient = Prismic.client(PRISMIC_API_ENDPOINT);

const fetchPrismicData = async (predicate) => {
  try {
    const apps = await PrismicClient.query(predicate, {
      pageSize: 120,
      page: 1,
    });
    return apps.results;
  } catch (err) {
    console.error(`unable to fetch prismic data ${err}`);
    throw err;
  }
};

export const fetchPrismicApps = () =>
  fetchPrismicData([
    Prismic.Predicates.any("document.type", ["screencloud_apps_item"]),
  ]) as Promise<RawPrismicApp[]>;

export const usePrismicAppBySlug = (slug: string) => {
  const [app, setApp] = useState<RawPrismicApp | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchApp = async () => {
      setLoading(true);
      try {
        const response = await fetchPrismicData([
          Prismic.Predicates.at("my.screencloud_apps_item.uid", slug),
        ]);
        setApp(response[0] as RawPrismicApp);
      } catch (err) {
        console.error(`Unable to fetch Prismic app by slug: ${err}`);
        setError(err instanceof Error ? err : new Error(String(err)));
        setApp(null);
      } finally {
        setLoading(false);
      }
    };

    fetchApp();
  }, [slug]);

  return { app, loading, error };
};
