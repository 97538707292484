import styled from "styled-components";

export const CardContainer = styled.div<{ borderRadius: string }>`
  display: flex;
  width: 182px;
  height: 100px;
  padding: 0px var(--spacing-xl, 24px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  border-right: 1px solid var(--color-gray-100, #e2e3e5);
  border-bottom: 1px solid var(--color-gray-100, #e2e3e5);
  background: #fff;

  border-radius: ${(props) => props.borderRadius};
`;

export const IconGroup = styled.div`
  display: flex;
  width: 366px;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  border-radius: 16px;
  border-left: 1px solid var(--color-gray-100, #e2e3e5);
  border-top: 1px solid var(--color-gray-100, #e2e3e5);
`;
