import { useEffect, useState } from "react";
import { ApolloQueryResult, useApolloClient } from "@apollo/client";
import cloneDeep from "lodash/cloneDeep";
import uniqBy from "lodash/uniqBy";
import { UUID } from "@screencloud/uuid";
import { AllAppsDocument, AllAppsQuery, AllAppsQueryResult } from "src/types.g";
import { fetchPrismicApps } from "src/pages/Apps/AppStore/api/prismic";
import { PrismicAppPost } from "src/pages/Apps/AppStore/components/DiscoverAppPost";
import {
  AppFeature,
  FAQ,
  ParsedPrismicAppData,
  RawPrismicApp,
} from "src/pages/Apps/AppStore/types";
import {
  ADDITIONAL_INFO_PRO,
  DISCOVER_APPS_TAG,
  POPULAR_APPS_TAG,
  SPOTLIGHT_APPS_TAG,
} from "src/pages/Apps/AppStore/constants";
import {
  filterUnavailableAppsOut,
  getAppId,
  getFilteredAndSortedApps,
  sortByPublicationDate,
} from "src/pages/Apps/AppStore/utils";
import { useAppContext } from "src/hooks/useAppContext";

// The app name maps to the readableId from Project Parity
const appNameMapping = {
  "Air Quality": "air-quality",
  Awardco: "awardco",
  "Ask a Question": "ask-a-question",
  Bonusly: "bonusly",
  "Building Directory": "building-directory",
  "Business & Finance News": "business-and-finance-news",
  "Calendar for Google": "googlecalendar",
  Canva: "canva",
  Canvas: "canvas",
  Clock: "clock",
  "Count Up Timer": "count-up",
  "Countdown Timer": "count-down",
  Currencies: "currencies",
  Date: "date",
  "Digital Menu Board": "digital-menu-board",
  "Digital Signs": "digital-signs",
  "Dropbox Gallery": "dropbox-gallery",
  "Embed App": "embed",
  "Emergency Alerts": "emergency-alerts",
  Engage: "engage",
  "Entertainment & Lifestyle News": "entertainment-news",
  Environment: "environment",
  "Facebook Recommendations": "facebook-recommendations",
  "Flight Schedules": "flight-schedules",
  Firstup: "first-up",
  "Follow Us": "follow-us",
  "Gallery for Instagram Business": "instagram-business",
  "Gallery for Instagram": "instagram",
  GeckoBoard: "geckoboard",
  "Google Alerts": "google-alerts",
  "Google Reviews": "google-reviews",
  "Google Sheets": "google-sheets",
  "Google Slides": "google-slides",
  "Google Traffic": "google-traffic",
  "Google Trends": "google-trends",
  "Headline News": "news",
  "Inspirational Quotes": "inspirational-quotes",
  "Live News": "live-news",
  "Meet The Team": "meet-the-team",
  "Meeting Room": "meeting-room",
  "Microsoft Excel": "microsoft-excel",
  "Microsoft OneDrive": "onedrive",
  "Microsoft Outlook Calendar": "outlook-calendar",
  "Microsoft Power BI": "microsoftpowerbi1",
  "Microsoft SharePoint": "microsoft-sharepoint",
  "Microsoft Teams": "microsoft-teams",
  "Microsoft Teams Rooms": "microsoft-teams-rooms",
  "Microsoft Viva Engage": "microsoft-viva-engage",
  "Microsoft Yammer (Legacy app)": "yammer",
  Monday: "monday",
  "Natural World News": "natural-world-news",
  "Noticeboard 2.0": "noticeboard",
  "On This Day": "on-this-day",
  "Follow Us On Facebook": "facebook-page-likes",
  "Page Scroll": "pagescroll",
  Playgrounds: "playgrounds",
  "Poster My Wall": "poster-my-wall",
  "Quick Post": "quick-post",
  Quote: "quote",
  "RSS Feed": "rss-feed",
  "ScreenCloud Broadcast": "live-streams",
  Slack: "slack",
  "Soccer Scores": "soccer-scores",
  "Business Feed For Facebook": "facebook-social-feed",
  "Sports Live Scores": "sports-live-scores",
  "Sports News": "sports-news",
  "Staffbase News": "staffbasenews",
  Stocks: "stocks",
  "Strea.ma": "strea-ma",
  "Taggbox Display": "taggbox-display",
  // "Team News Uploader", ??
  "Team News": "team-news-self-serve",
  "Team News (Legacy)": "team-news",
  "Tech News": "technology-news",
  Transport: "transport",
  TripAdvisor: "trip-advisor",
  Unily: "unily",
  Vengo: "vengo",
  Vimeo: "vimeo",
  "Walls.io": "walls-io",
  Weather: "weather",
  Webhooks: "webhooks",
  Wordpress: "wordpress",
  "World Clock": "world-clock",
  Yelp: "yelp-reviews",
  "YouTube Live": "youtube-live",
  YouTube: "youtube",
  "Zendesk Reports": "zendesk",
  "Zoom Rooms": "zoom-rooms",
};

export function getAppSlug(appName: string) {
  return appNameMapping[appName];
}

function getGuideUrl(link) {
  return link.link_type === "Web" ? link.url : undefined;
}

export function mapPrismicAppDataToStudioApps(
  allApps: AllAppsQuery["allApps"] | undefined,
  prismicAppsData: ParsedPrismicAppData,
): AllAppsQuery["allApps"] {
  if (!allApps) {
    return { __typename: "AppsConnection", nodes: [] };
  }

  // The app name maps to the readableId from Project Parity
  const _allApps = cloneDeep(allApps);
  _allApps?.nodes.forEach((app, index) => {
    const appSlug = appNameMapping[app.name];
    if (appSlug && prismicAppsData[appSlug]) {
      // override app data from prismic
      _allApps.nodes[index].name = prismicAppsData[appSlug].name;
      _allApps.nodes[index].iconUrl = prismicAppsData[appSlug].iconUrl;
      _allApps.nodes[index].categories = [
        ...prismicAppsData[appSlug].categories,
      ];
      const storeData = _allApps.nodes[index].appVersionByAppId?.storeData;
      if (storeData) {
        storeData.appGuideUrl = prismicAppsData[appSlug].appGuideUrl;
        storeData.description = prismicAppsData[appSlug].describe;
        storeData.howToUrl = prismicAppsData[appSlug].howToUrl;
        storeData.screenshotUrls =
          prismicAppsData[appSlug].screenshotUrls.filter(Boolean);
        storeData.shortDescription = prismicAppsData[appSlug].shortDescription; // for display in card view in app store
        storeData.additionalInfo = prismicAppsData[appSlug].additionalInfo; // for pro apps filled with "Included with Pro & Enterprise plans"
        storeData.features = prismicAppsData[appSlug].features; // for about app section
        storeData.faqs = prismicAppsData[appSlug].faqs; // for faq section
        storeData.schemeColor = prismicAppsData[appSlug].schemeColor;
      }
    }
  });

  // Make sure nodes are unique by name
  _allApps.nodes = uniqBy(_allApps.nodes, "name");

  // if no match uid and appName then use data from studio db
  return _allApps;
}

function parsePrismicAppsIntoStudioFormat(rawPrismicApps: RawPrismicApp[]) {
  return rawPrismicApps.reduce<ParsedPrismicAppData>((result, currVal) => {
    const key = currVal.uid ?? "unknown";

    const guideUrl = getGuideUrl(currVal.data.help_guide_url)
      ? currVal.data.help_guide_url.url
      : undefined;

    result[key] = {
      categories: currVal.data.app_categories.map(
        (category) => category.app_category,
      ),
      iconUrl: currVal.data.app_image.url,
      appGuideUrl: guideUrl,
      howToUrl: guideUrl,
      describe: currVal.data.item_description[0].text,
      screenshotUrls: currVal.data.carousel_images.map(
        (item) => item.carousel_image.url,
      ),
      name: currVal.data.item_name[0].text,
      shortDescription:
        currVal.data.short_description.length > 0
          ? currVal.data.short_description[0].text
          : "",
      additionalInfo: currVal.data.item_additional_info,
      features: currVal.data.feature_list.map((feature) => ({
        title: feature.title[0].text,
        description: feature.description[0].text,
      })),
      faqs: currVal.data.faqs
        .filter((faq) => faq.question && faq.answer)
        .map((faq) => ({
          question: faq.question,
          answer: faq.answer,
        })),
      schemeColor: currVal.data.scheme_color,
    };

    return result;
  }, {});
}

function getStudioAppsWithPrismicData(
  rawPrismicApps: RawPrismicApp[],
  studioApps: AllAppsQuery["allApps"],
) {
  const parsedPrismicApps = parsePrismicAppsIntoStudioFormat(
    rawPrismicApps ?? [],
  );

  return mapPrismicAppDataToStudioApps(studioApps, parsedPrismicApps);
}

function getLatestDiscoveryPosts(
  rawPrismicApps: RawPrismicApp[],
): PrismicAppPost[] {
  return rawPrismicApps
    .filter(
      ({ tags, data: { help_guide_url } }) =>
        tags.includes(DISCOVER_APPS_TAG) && help_guide_url.link_type === "Web",
    )
    .sort(
      ({ first_publication_date: dateA }, { first_publication_date: dateB }) =>
        new Date(dateB as string).getTime() -
        new Date(dateA as string).getTime(),
    )
    .slice(0, 5)
    .map(
      ({
        data: {
          help_guide_url,
          item_name,
          app_image,
          short_description,
          carousel_images,
          on_screen_image,
          discover_post_background_image,
        },
      }) => ({
        appGuideUrl: help_guide_url.url,
        name: item_name[0].text,
        iconUrl: app_image.url,
        shortDescription: short_description[0].text,
        onScreenImageUrl: on_screen_image.url,
        screenshots: carousel_images.map((item) => item.carousel_image.url),
        backgroundImage: discover_post_background_image.url,
      }),
    );
}

export type ProAppFeatures = {
  appId: UUID;
  image: string;
  iconUrl: string;
  appGuideUrl: string;
  name: string;
  shortDescription: string;
  categories: string[];
  longDescription;
  screenshots: string[];
  color: string;
  onScreenImageUrl: string;
  features: AppFeature[];
  faqs: FAQ[];
};

function getProAndEnterpriseApps(
  rawPrismicApps: RawPrismicApp[],
  allApps: AllAppsQuery["allApps"],
): ProAppFeatures[] {
  return rawPrismicApps
    .filter(({ data }) => data.item_additional_info === ADDITIONAL_INFO_PRO)
    .map(({ data }) => ({
      appId: getAppId(data.item_name[0].text, allApps),
      image: data.meta_og_image.url,
      iconUrl: data.app_image.url,
      appGuideUrl: data.help_guide_url.url,
      name: data.item_name[0].text,
      shortDescription: data.short_description[0].text,
      categories: data.app_categories.map((category) => category.app_category),
      longDescription: data.item_description[0].text,
      screenshots: data.carousel_images.map((item) => item.carousel_image.url),
      color: data.scheme_color,
      onScreenImageUrl: data.on_screen_image.url,
      features: data.feature_list.map((feature) => ({
        title: feature.title[0].text,
        description: feature.description[0].text,
      })),
      faqs: data.faqs
        .filter((faq) => faq.question && faq.answer)
        .map(({ question, answer }) => ({ question, answer })),
    }))
    .sort((a, b) => a.name.localeCompare(b.name));
}

const getSpotlightApps = (rawPrismicApps: RawPrismicApp[]) =>
  getFilteredAndSortedApps(
    rawPrismicApps,
    ({ tags }) => tags.includes(SPOTLIGHT_APPS_TAG),
    sortByPublicationDate,
  );

const getPopularApps = (rawPrismicApps: RawPrismicApp[]) =>
  getFilteredAndSortedApps(
    rawPrismicApps,
    ({ tags }) => tags.includes(POPULAR_APPS_TAG),
    sortByPublicationDate,
  );

async function getAllApps(client) {
  const [allAppsQueryResult, rawPrismicApps]: [
    ApolloQueryResult<AllAppsQuery>,
    RawPrismicApp[],
  ] = await Promise.all([
    client.query({
      fetchPolicy: "network-only",
      query: AllAppsDocument,
    }),
    fetchPrismicApps(),
  ]);

  const { data, loading } = allAppsQueryResult;

  const appsWithPrismicData = getStudioAppsWithPrismicData(
    rawPrismicApps,
    data.allApps,
  );

  const latestDiscoveryPosts = getLatestDiscoveryPosts(rawPrismicApps);
  const proApps = getProAndEnterpriseApps(rawPrismicApps, data.allApps);
  const spotlightApps = getSpotlightApps(rawPrismicApps);
  const popularApps = getPopularApps(rawPrismicApps);
  return {
    data: appsWithPrismicData,
    appDiscovery: latestDiscoveryPosts,
    proApps,
    loading,
    spotlightApps,
    popularApps,
  };
}

export type UseGetAllAppsReturnType = {
  allAvailableApps: AllAppsQuery["allApps"] | undefined;
  allApps: AllAppsQuery["allApps"] | undefined;
  appDiscoveryData: PrismicAppPost[] | undefined;
  loading: boolean | undefined;
  proAppFeatures: ProAppFeatures[] | undefined;
  spotlight: string[] | undefined;
  popularApps: string[] | undefined;
};

export function useGetAllApps(): UseGetAllAppsReturnType {
  const client = useApolloClient();
  const [allAvailableApps, setAllAvailableApps] =
    useState<AllAppsQuery["allApps"]>();
  const [allApps, setAllApps] =
    useState<
      NonNullable<
        NonNullable<AllAppsQueryResult["data"]>["allApps"]
      >["nodes"][0][]
    >();
  const [appDiscoveryData, setAppDiscoveryData] = useState<PrismicAppPost[]>(
    [],
  );
  const [proAppFeatures, setProAppFeatures] = useState<ProAppFeatures[]>([]);
  const [spotlight, setSpotlight] = useState<string[]>([]);
  const [popularApps, setPopularApps] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>();
  const context = useAppContext();
  useEffect(() => {
    const getData = async () => {
      const apps = [] as NonNullable<
        NonNullable<AllAppsQueryResult["data"]>["allApps"]
      >["nodes"][0][];

      const {
        data,
        appDiscovery,
        loading,
        proApps,
        spotlightApps,
        popularApps,
      } = await getAllApps(client);

      data?.nodes.forEach((app) => {
        if (filterUnavailableAppsOut({ context, app, criteria: "" })) {
          apps.push(app);
        }
      });

      setAllAvailableApps({ ...data, nodes: apps } as AllAppsQuery["allApps"]); // apps are filtered out by filterUnavailableAppsOut()
      setAllApps(data?.nodes); // apps are not filtered out with any criteria
      setLoading(loading);
      setAppDiscoveryData(appDiscovery);
      setProAppFeatures(proApps);
      setSpotlight(spotlightApps);
      setPopularApps(popularApps);
    };
    getData();
  }, []);

  return {
    allApps: { __typename: "AppsConnection", nodes: allApps ?? [] },
    allAvailableApps,
    appDiscoveryData,
    loading,
    proAppFeatures,
    spotlight,
    popularApps,
  };
}
