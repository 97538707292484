import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

export const Styled = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: 2;
  padding: 0 40px;
  min-height: 311px;
  user-select: none;
  gap: 40px;

  &.illustration_studio_secure_dashboards {
    height: 75vh;

    img {
      width: 368.9px;
      height: 266px;
    }
  }

  &.empty-state {
    text-align: center;

    &.empty-channel,
    &.empty-playlist,
    &.empty-link,
    &.empty-sites,
    &.empty-channel-detail,
    &.empty-playlist-detail,
    &.empty-search-channels,
    &.empty-canvas-instances {
      img {
        max-width: 400px;
      }
    }

    &.empty-media,
    &.empty-search-media {
      align-self: center;
    }

    &.empty-themes,
    &.empty-fonts {
      img {
        max-width: 240px;
      }
    }

    &.empty-themes,
    &.empty-fonts-ads {
      margin: 90px auto;
      img {
        max-width: 340px;
      }
    }

    &.empty-operating-hours {
      img {
        max-width: 240px;
      }
    }

    &.empty-user-group {
      img {
        width: 174px;
      }
      h3 {
        margin-top: -15px;
        font-weight: 700;
      }
    }

    &.empty-search-logs {
      height: 500px;

      img {
        max-width: 240px;
      }
    }

    &.empty-playback-logs {
      height: 500px;

      img {
        max-width: 240px;
      }
    }

    &.empty-proof-of-play-ads {
      padding: 40px 0;
      img {
        max-width: 340px;
      }
    }

    &.empty-space-detail {
      height: 150px;

      img {
        width: 174px;
      }

      h3 {
        margin-top: -15px;
        font-weight: 700;
      }
    }

    &.empty-canvas-instances-with-published-template,
    &.empty-canvas-templates-with-published-template {
      .stack-images-wrapper {
        position: relative;
        width: 360px;
        height: 270px;
        img {
          position: absolute;
          width: 265px;
          height: 150px;
          top: calc(50% - 75px);
          left: calc(50% - 132.5px);
          border-radius: var(--radius-md);
        }
        .empty-1 {
          z-index: 1;
          transform: rotate(10deg);
        }
        .empty-2 {
          z-index: 2;
          transform: rotate(-10deg);
        }
        .empty-3 {
          z-index: 3;
        }
      }
    }

    img {
      min-height: 140px;
    }

    .tagline {
      max-width: 600px;
      gap: 10px;
      display: flex;
      width: 100%;
      flex-direction: column;
      text-align: center;

      p {
        color: var(--color-grey80);
        margin: 0;
      }

      &.trialist-tagline-before-image {
        display: none;
      }
    }

    h3 {
      font-size: 1.5em;
      line-height: 1.25;
      margin: 0;
      font-weight: 900;
    }

    p {
      max-width: 600px;
    }

    .button {
      align-self: center;
      margin: 20px auto 0;

      span {
        line-height: 45px;
      }
    }

    .add-first-screen-button-container {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px auto 0;

      .add-first-screen-button {
        z-index: 1;
        margin: 0;
      }

      .add-first-screen-star {
        min-height: unset;
        width: 26px;
        height: 26px;
        position: absolute;
        z-index: 0;
        transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.2);

        @media screen and (max-width: ${Theme.break.small}) {
          display: none;
        }
      }

      .star-3 {
        width: 18px;
        height: 18px;
      }

      &:hover {
        .star-1 {
          transform: translate(105px, 5px) rotate(6deg);
        }

        .star-2 {
          transform: translate(-100px, 3px) rotate(347deg);
        }

        .star-3 {
          transform: translate(0px, -34px) rotate(353deg);
        }

        .star-4 {
          transform: translate(30px, 35px) rotate(178deg);
        }
      }
    }

    &.empty-screen-new {
      img {
        width: auto;
        height: 270px;
      }

      @media screen and (max-width: ${Theme.break.small}) {
        padding-left: 5px;
        padding-right: 5px;
      }
    }

    &.empty-channel-detail,
    &.empty-playlist-detail {
      .tagline {
        max-width: 480px;
      }
    }

    &.empty-playlist-detail {
      .arrow {
        max-width: 100px;
        position: absolute;
        right: 30px;
        top: calc(50% - 140px);
      }

      .button {
        margin: 20px auto 0;
        display: block;
      }
    }

    &.empty-channel-detail {
      min-height: 561px;
    }

    &.empty-group-list,
    &.empty-themes,
    &.empty-fonts {
      min-height: 575px;
    }

    &.empty-invite-people {
      h3 {
        margin-top: 20px;
      }
    }

    &.empty-new-group-member {
      min-height: 240px;

      img {
        max-width: 174px;
        min-height: unset;
      }

      h3 {
        font-weight: 700;
      }
    }

    &.empty-new-group-member {
      height: 250px;
    }

    &.empty-owner {
      min-height: 0;
    }
  }

  @media screen and (max-width: ${Theme.break.mediumAlt}) {
    &.empty-state {
      &.empty-channel-detail,
      &.empty-playlist-detail {
        min-height: auto;
        padding-right: 70px;
      }
      &.empty-playlist-detail {
        position: relative;

        .arrow {
          display: none;
        }
      }
    }
  }

  @media screen and (max-width: ${Theme.break.small}) {
    &.empty-state {
      height: 100%;

      &.empty-channel,
      &.empty-link,
      &.empty-playlist,
      &.empty-channel-detail,
      &.empty-playlist-detail {
        img {
          margin-left: 0;
        }
      }

      &[class*="empty-search-"] {
        top: 45px !important;

        h3 {
          margin-top: 30px;
        }
      }

      p {
        font-size: 0.875em;
        line-height: 1.357;
        margin: 0;
      }

      .tagline {
        &.trialist-tagline-after-image {
          display: none;
        }
        &.trialist-tagline-before-image {
          display: flex;
        }
      }

      &.trialist {
        gap: 0px;
      }

      .button {
        display: none;
      }

      &.empty-playlist {
        margin-top: 0;
      }

      &.empty-channel-detail,
      &.empty-playlist-detail {
        padding-right: 55px;

        .ui.button {
          display: block;
          margin: 20px auto 0;
        }
      }

      &.empty-playlist-detail {
        padding-right: 15px;
      }

      &.empty-link {
        &.ad-visible {
          margin: 40px 0 0 0;
        }
      }
    }
  }

  &.empty-screen-picker {
    max-height: 100%;
    @media only screen and (min-width: ${Theme.break
        .small}) and (max-width: ${Theme.break.mediumAlt}) {
      height: 140px !important;
      h3 {
        margin: 10px 0;
      }
    }
    @media only screen and (min-width: ${Theme.break
        .medium}) and (max-width: ${Theme.break.largeHD}) {
      height: 250px !important;
    }
    @media only screen and (min-width: ${Theme.break
        .largeHD}) and (max-width: ${Theme.break.large}) {
      height: 340px !important;
    }
  }

  &.empty-media-picker,
  &.empty-playlist-picker,
  &.empty-link-picker,
  &.empty-app-picker {
    height: calc(100% - 40px);
    width: 100%;
    min-height: 0;
  }

  &.empty-media-sidebar,
  &.empty-link-sidebar,
  &.empty-app-sidebar {
    padding: 40px;
    p {
      font-size: 0.875em;
      color: var(--color-gray-700);
    }
  }
`;
