import {
  ModalSize,
  Notification,
} from "@screencloud/screencloud-ui-components";
import { UUID } from "@screencloud/uuid";
import { FormattedMessage } from "react-intl";
import { AppContextType } from "src/AppContextProvider/type";
import AddScreen from "src/components/AddScreen";
import { ReplaceDevice } from "src/components/ReplaceDevice";
import SingleScreenPicker from "src/components/ScreenPicker/SingleScreenPicker";
import { useAppContext } from "src/hooks/useAppContext";
import {
  ScreenByIdDocument,
  ScreenListDocument,
  useReplaceScreenDeviceMutation,
} from "src/types.g";

/**
 * Custom hook for managing replace device functionality.
 *
 * @returns {Object} An object containing functions to manage replace device feature.
 */
export default function useReplaceDevice() {
  const context: AppContextType = useAppContext();
  const [replaceScreenDevice] = useReplaceScreenDeviceMutation({
    refetchQueries: [ScreenByIdDocument, ScreenListDocument],
  });

  /**
   * Handles the device replacement logic.
   *
   * @param {string} pairingCode - The pairing code of the device.
   */
  const replaceDevice = async (pairingCode: string, screenId: UUID) => {
    // Replace device logic
    return replaceScreenDevice({
      variables: {
        input: {
          currentScreenId: screenId,
          pairingCode,
          spaceId: context.currentSpace?.id,
        },
      },
    })
      .then((res) => {
        Notification.success({
          message: context.intl.formatMessage({
            defaultMessage: "Device successfully replaced",
            id: "ui_component.replace_device.success",
          }),
        });
        return res;
      })
      .catch((err) => {
        console.log(err);
        Notification.error({
          message: context.intl.formatMessage({
            defaultMessage: "Unable to replace Device",
            id: "ui_component.replace_device.error",
          }),
        });
      });
  };

  /**
   * Opens the "Replace Device" modal.
   *
   * @param {UUID} id - The ID of the screen to be replaced.
   */
  const openReplaceDeviceModal = async (screenId: UUID) => {
    try {
      // higher order function for replace device with screenid
      const replaceDeviceByScreenId = async (pairingCode: string) => {
        return replaceDevice(pairingCode, screenId);
      };
      // Open modal with fetched screen's name
      context.modal.openModal(
        <ReplaceDevice onReplaceDeviceCallback={replaceDeviceByScreenId} />,
        <FormattedMessage
          id="ui_component.common.label.replace_device"
          defaultMessage="Replace Device"
        />,
        {
          opts: {
            disableTitle: false,
            overflow: false,
            size: ModalSize.LARGE,
          },
        },
      );
    } catch (error) {
      console.error("Error opening modal:", JSON.stringify(error));
    }
  };

  const openSelectScreenForReplaceDeviceModal = (pairingCode) => {
    // callback when user select a screen and clicks confirm
    const onConfirm = (selectedScreenId: UUID) => {
      if (selectedScreenId) {
        replaceDevice(pairingCode, selectedScreenId);
        context.modal.closeModals();
      }
    };

    // callback when user clicks back
    const onBack = () => {
      context.modal.openModal(
        <AddScreen defaultPairingCode={pairingCode} />,
        "New Screen",
      );
    };

    const footerHelpText = (
      <FormattedMessage
        id="ui_component.replace_device.replace_device_info"
        defaultMessage="The selected screen settings will be seamlessly transferred to your new device."
      />
    );

    // select screen modal
    context.modal.openModal(
      <SingleScreenPicker
        footerHelpText={footerHelpText}
        onBack={onBack}
        onConfirm={onConfirm}
        isReplaceDevice
      />,
      <FormattedMessage
        id="ui_component.common.label.replace_device_screen_select"
        defaultMessage="Select Screen to replace"
      />,
      {
        opts: {
          disableTitle: false,
          overflow: false,
          size: ModalSize.LARGE,
        },
      },
    );
  };

  return {
    openReplaceDeviceModal,
    replaceDevice,
    openSelectScreenForReplaceDeviceModal,
  };
}
