import { PrimaryButton } from "src/helpers/whiteLabel";
import screenIcon from "src/components/EmptyState/NewTrialEmptyScreenSection/images/screen-layout.svg";
import { Button, Icon } from "@screencloud/screencloud-ui-components";
import {
  PairScreenCardStyled,
  Title,
  Description,
  VirtualScreenCardStyled,
  VirtualScreenCardContent,
  VirtualScreenTitle,
  VirtualScreenDescription,
  IconWrapper,
  Wrapper,
} from "src/components/EmptyState/NewTrialEmptyScreenSection/components/ContentCard/styles";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";

type PairScreenCardProps = {
  onClick: () => void;
  className?: string;
};

export const PairScreenCard = ({ className, onClick }: PairScreenCardProps) => (
  <PairScreenCardStyled className={className}>
    <Title>
      <FormattedMessage
        id="new-trial-screen-empty-state.dark-card.title"
        defaultMessage="I know what I'm doing"
      />
    </Title>
    <Description>
      <FormattedMessage
        id="new-trial-screen-empty-state.dark-card.description"
        defaultMessage="I have a screen displaying a pairing code and I'm ready to connect"
      />
    </Description>
    <PrimaryButton className="card-button" onClick={onClick}>
      <FormattedMessage
        id="new-trial-screen-empty-state.dark-card.button"
        defaultMessage="Pair your screen now"
      />
    </PrimaryButton>
  </PairScreenCardStyled>
);

type VirtualScreenCardProps = {
  className?: string;
  onClick: () => void;
};

export const VirtualScreenCard = ({
  className,
  onClick,
}: VirtualScreenCardProps) => (
  <VirtualScreenCardStyled className={className}>
    <Wrapper>
      <VirtualScreenCardContent>
        <VirtualScreenTitle>
          <FormattedMessage
            id="new-trial-screen-empty-state.purple-card.title"
            defaultMessage="I just want to experiment with it"
          />
        </VirtualScreenTitle>
        <VirtualScreenDescription>
          <FormattedHTMLMessage
            id="new-trial-screen-empty-state.purple-card.description"
            defaultMessage="<b>No screen, no problem?</b> While you consider your hardware options, you can launch a virtual screen to pair and display content on."
          />
        </VirtualScreenDescription>
      </VirtualScreenCardContent>
      <IconWrapper>
        <img src={screenIcon} alt="Screen icon" />
        <Button className="card-button launch-button" onClick={onClick}>
          <div className="button-content">
            <Icon name="screen" />{" "}
            <FormattedMessage
              id="new-trial-screen-empty-state.purple-card.button"
              defaultMessage="Launch a Virtual Screen"
            />
          </div>
        </Button>
      </IconWrapper>
    </Wrapper>
  </VirtualScreenCardStyled>
);
