import { useScreens } from "src/billinglatest/hooks/useScreens";
import { ssm } from "../../state/session/ssm";
import {
  GetPrimaryScreenGroupQueryResult,
  PairScreenMutationFn,
  useGetPrimaryScreenGroupQuery,
  usePairScreenMutation,
  useUpdateOrgPixiTrialistDataMutation,
  UpdateOrgPixiTrialistDataMutation,
  UpdateOrgPixiTrialistDataMutationVariables,
} from "../../types.g";
import { compose } from "../../utils/compose";
import { useCanPairScreen } from "../../billinglatest/hooks/useCanPairScreen";
import { useEffect } from "react";
import {
  UseSubscription,
  useSubscription,
} from "src/billinglatest/hooks/useSubscription";
import { CanPairScreenResponseCode, Screen } from "src/billinglatest/types";
import { MutationFunction } from "@apollo/client";

export interface ApolloProps {
  pairScreen: PairScreenMutationFn;
  data: GetPrimaryScreenGroupQueryResult;
  canPairScreen?: CanPairScreenResponseCode;
  screenLicenses: Screen;
  subscription: UseSubscription;
  updateOrgPixiTrialistData: MutationFunction<
    UpdateOrgPixiTrialistDataMutation,
    UpdateOrgPixiTrialistDataMutationVariables
  >;
}

export const withData = compose(
  (Component) => (props: ApolloProps) => {
    const screens = useScreens();
    const [pairScreen] = usePairScreenMutation();
    const variables = { spaceId: ssm.current.settings.spaceId };
    const canPairScreen = useCanPairScreen();
    const subscription = useSubscription({ cache: true });
    useEffect(() => {
      const fetch = async () => {
        await canPairScreen.fetch();
      };
      fetch();
    }, []);

    useEffect(() => {
      const fetch = async () => {
        try {
          await subscription.fetch();
        } catch (error) {
          console.error("Fetch failed for subscription");
        }
      };
      fetch();
    }, []);

    const getPrimaryScreenGroup = useGetPrimaryScreenGroupQuery({
      variables,
      fetchPolicy: "cache-and-network",
    });

    useEffect(() => {
      const fetch = async () => {
        await screens.fetch();
      };
      fetch();
    }, []);

    return (
      <Component
        {...props}
        pairScreen={pairScreen}
        data={getPrimaryScreenGroup}
        canPairScreen={canPairScreen.get()}
        screenLicenses={screens.get()}
        subscription={subscription}
      />
    );
  },
  (Component) => (props: ApolloProps) => {
    const [updateOrgPixiTrialistData] = useUpdateOrgPixiTrialistDataMutation();
    return (
      <Component
        {...props}
        updateOrgPixiTrialistData={updateOrgPixiTrialistData}
      />
    );
  },
);
