import {
  CapsuleLabelStyled,
  Styled,
} from "src/components/AppPicker/components/TagLabelList/styles";

const CapsuleLabel = (props: {
  tag: string;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
}): JSX.Element => {
  return (
    <CapsuleLabelStyled onClick={props.onClick}>{props.tag}</CapsuleLabelStyled>
  );
};

type Props = {
  tags: (string | null)[];
  onClickHandler: (tag: string) => void;
};

export const TagLabelList = (props: Props): JSX.Element => {
  const { tags, onClickHandler } = props;

  return (
    <Styled>
      {tags.map((tag) => (
        <CapsuleLabel
          tag={tag!}
          onClick={(e) => {
            e.stopPropagation();
            onClickHandler(tag!);
          }}
          key={tag}
        />
      ))}
    </Styled>
  );
};
