export interface Question {
  id: string;
  question: string;
  yesText: string;
  noText: string;
  backText: string;
  previousQuestion?: string;
  rightComponent?: JSX.Element;
}

export interface Step {
  index: number;
  title: string;
  buttonText: string;
  leftElement: React.ReactNode;
  visibleCssProperty?: React.CSSProperties;
  beforeVisibleCssProperty?: React.CSSProperties;
  afterVisibleCssProperty?: React.CSSProperties;
  description?: string;
  progressStepTitle?: string;
  extraElement?: React.ReactNode;
}

export enum PairingMethodEnum {
  HARDWARE = "HARDWARE",
  SMART_TV = "SMART_TV",
  MOBILE = "MOBILE",
}

export interface Methods {
  [PairingMethodEnum.HARDWARE]: Step[];
  [PairingMethodEnum.SMART_TV]: Step[];
  [PairingMethodEnum.MOBILE]: Step[];
}

export enum HardwareQuestionId {
  HARDWARE = "hardware",
  SCREEN = "screen",
  SMART_TV = "smartTV",
}

export interface Answers {
  hasCorrectHardware: boolean;
  hasScreenNearby: boolean;
  isSmartTV: boolean;
}

export interface PairingStep {
  title: string;
  description: string;
  buttonText: string;
  id?: string;
  image?: string;
}

export enum PairingProgressStepTitle {
  HARDWARE = "Hardware",
  DOWNLOAD_PLAYER = "Download Player",
  PAIR_DEVICE = "Pair Device",
}
