import { useEffect, useState, useCallback } from "react";
import { debounce } from "lodash";
import { DEBOUNCE_TIMEOUT_MS } from "src/constants/constants";
export interface UseTagSelection {
  onClickTag: (tagId: string) => void;
  onSetelectTags: (tagIds: string[]) => void;
  isTagIdSelected: (tagId: string) => boolean;
  onClearTags: () => void;
  hasSelectedTags: boolean;
  selectedTagForQuery: string[];
  selectedTags: string[];
}

export function useTagSelection(): UseTagSelection {
  // This is used to query
  const [selectedTagForQuery, setSelectedTagForQuery] = useState(
    [] as string[],
  );
  // This is to be displayed on UI
  const [selectedTagsSet, setSelectedTagsSet] = useState(new Set<string>());

  const isTagIdSelected = (tag: string) => {
    return selectedTagsSet.has(tag);
  };

  const toggleSelection = (tag: string) => {
    if (isTagIdSelected(tag)) {
      const newSelectedTagsSet = new Set(selectedTagsSet);
      newSelectedTagsSet.delete(tag);
      setSelectedTagsSet(newSelectedTagsSet);
    } else {
      const newSelectedTagsSet = new Set(selectedTagsSet);
      newSelectedTagsSet.add(tag);
      setSelectedTagsSet(newSelectedTagsSet);
    }
  };

  const onSetelectTags = (tags: string[]) => {
    const newSelectedTagsSet = new Set(tags);
    setSelectedTagsSet(newSelectedTagsSet);
  };

  const onClickTag = (tag: string) => {
    toggleSelection(tag);
  };

  const onClearTags = () => {
    setSelectedTagsSet(new Set<string>());
    setSelectedTagForQuery([]);
  };

  const setSelectedTagQueryWithDebounce = useCallback(
    debounce((selectedTagsSet) => {
      setSelectedTagForQuery(Array.from(selectedTagsSet));
    }, DEBOUNCE_TIMEOUT_MS),
    [],
  );

  useEffect(() => {
    setSelectedTagQueryWithDebounce(selectedTagsSet);
  }, [selectedTagsSet]);

  const hasSelectedTags = selectedTagsSet.size > 0;

  return {
    onClickTag,
    onSetelectTags,
    isTagIdSelected,
    selectedTagForQuery,
    onClearTags,
    selectedTags: Array.from(selectedTagsSet),
    hasSelectedTags,
  };
}
