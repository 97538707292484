import { cloneDeep, findIndex } from "lodash";
import { DataValue } from "@apollo/client/react/hoc";
import { UUID } from "@screencloud/uuid";
import { Typenames } from "src/constants/constants";
import {
  Screen,
  ScreenGroup,
  ScreenListDocument,
  ScreenListQuery,
  ScreenListQueryVariables,
  UpdateScreenListByScreenIdMutationVariables,
  useUpdateScreenByIdMutation,
} from "src/types.g";
import { ScreenDropResult } from "src/components/ScreensGroup";
import { useAppContext } from "src/hooks/useAppContext";

type CallbackFunctionType = null | undefined | ((isSuccess: boolean) => void);

export const useUpdateScreen = (props: {
  hasSelectedTags: boolean;
  selectedTagForQuery: string[];
}) => {
  const context = useAppContext();
  const [updateScreenById] = useUpdateScreenByIdMutation();

  const refetchScreenList = () => {
    const variable: ScreenListQueryVariables = {
      spaceId: context.user.settings.spaceId,
      filter: props.hasSelectedTags
        ? {
            and: [{ tags: { contains: props.selectedTagForQuery } }],
          }
        : {},
      endCursor: null,
    };
    return {
      query: ScreenListDocument,
      variables: variable,
    };
  };

  const handleUpdateScreen = async (
    screenId: UUID,
    screenData: Partial<Screen>,
    screenInput: UpdateScreenListByScreenIdMutationVariables["input"],
    callback: CallbackFunctionType = null,
    dropResult?: ScreenDropResult,
  ): Promise<void> => {
    const screenInputVariables: UpdateScreenListByScreenIdMutationVariables = {
      input: {
        ...screenInput,
        id: screenId,
      },
    };

    const result = await updateScreenById({
      optimisticResponse: {
        __typename: Typenames.UpdateScreenByIdMutation,
        updateScreenById: {
          __typename: Typenames.UpdateScreenByIdPayload,
          screen: {
            videoSource: null,
            highResolutionImage: null,
            ...screenData,
            __typename: Typenames.Screen,
          },
        },
      } as any,
      update: (proxy, result) => {
        const cacheData = cloneDeep(
          proxy.readQuery(refetchScreenList()),
        ) as DataValue<ScreenListQuery>;

        if (
          cacheData?.spaceById?.screenGroupsBySpaceId &&
          cacheData.defaultScreenGroupBySpaceId
        ) {
          const updatedScreen = result.data?.updateScreenById!.screen as Screen;
          // Update regular screen groups
          (
            cacheData.spaceById.screenGroupsBySpaceId.nodes ??
            ([] as ScreenGroup[])
          ).map((group) => {
            if (!group.screensByScreenGroupIdAndOrgId) return;

            const currScreenIndex = findIndex(
              group.screensByScreenGroupIdAndOrgId.nodes,
              (screen) => screen.id === updatedScreen!.id,
            );

            if (dropResult) {
              handleDropResult(
                group,
                updatedScreen!,
                dropResult,
                currScreenIndex,
              );
            } else {
              handleRegularUpdate(group, updatedScreen!, currScreenIndex);
            }
          });

          // Update default screen group
          if (
            cacheData.defaultScreenGroupBySpaceId.screensByScreenGroupIdAndOrgId
          ) {
            const defaultGroup = cacheData.defaultScreenGroupBySpaceId;
            const currScreenIndex = findIndex(
              defaultGroup.screensByScreenGroupIdAndOrgId.nodes,
              (screen) => screen.id === updatedScreen!.id,
            );

            if (dropResult) {
              handleDropResult(
                defaultGroup,
                updatedScreen!,
                dropResult,
                currScreenIndex,
              );
            } else {
              handleRegularUpdate(
                defaultGroup,
                updatedScreen!,
                currScreenIndex,
              );
            }
          }

          proxy.writeQuery({
            ...refetchScreenList(),
            data: cacheData,
          });
        }
      },
      variables: screenInputVariables,
    });

    const data = result?.data;
    if (data?.updateScreenById && callback) {
      callback(data.updateScreenById.screen !== null);
    }
  };

  const handleDropResult = (
    group: any,
    updatedScreen: Screen,
    dropResult: ScreenDropResult,
    currScreenIndex: number,
  ) => {
    if (
      updatedScreen.screenGroupId === group.id &&
      dropResult.source.screenGroupId === updatedScreen.screenGroupId
    ) {
      // reorder in the same group
      const [removed] = group.screensByScreenGroupIdAndOrgId.nodes.splice(
        dropResult.source.index,
        1,
      );
      group.screensByScreenGroupIdAndOrgId.nodes.splice(
        dropResult.destination.index,
        0,
        removed,
      );
    } else {
      // drag and drop between groups
      if (currScreenIndex !== -1) {
        // remove screen from current group
        group.screensByScreenGroupIdAndOrgId.nodes.splice(currScreenIndex, 1);
        group.screensByScreenGroupIdAndOrgId.totalCount! -= 1;
      }

      if (
        dropResult.source.screenGroupId !==
          dropResult.destination.screenGroupId &&
        updatedScreen.screenGroupId === group.id
      ) {
        // add screen to destination group
        group.screensByScreenGroupIdAndOrgId.nodes.splice(
          dropResult.destination.index,
          0,
          updatedScreen as any,
        );
        group.screensByScreenGroupIdAndOrgId.totalCount! += 1;
      }
    }
  };

  const handleRegularUpdate = (
    group: any,
    updatedScreen: Screen,
    currScreenIndex: number,
  ) => {
    if (updatedScreen.screenGroupId === group.id && currScreenIndex !== -1) {
      // update screen
      group.screensByScreenGroupIdAndOrgId.nodes[currScreenIndex] =
        updatedScreen as any;
    } else if (
      updatedScreen.screenGroupId !== group.id &&
      currScreenIndex !== -1
    ) {
      // remove screen from current group
      group.screensByScreenGroupIdAndOrgId.nodes.splice(currScreenIndex, 1);
      group.screensByScreenGroupIdAndOrgId.totalCount! -= 1;
    } else if (
      updatedScreen.screenGroupId === group.id &&
      currScreenIndex === -1
    ) {
      // add screen to destination group
      group.screensByScreenGroupIdAndOrgId.nodes.push(updatedScreen as any);
      group.screensByScreenGroupIdAndOrgId.totalCount! += 1;
    }
  };

  return {
    updateScreen: handleUpdateScreen,
  };
};
