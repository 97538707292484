import mixpanel from "mixpanel-browser";
import { appConfig } from "src/appConfig";
import { AppContextState } from "src/AppContextProvider";

export const shouldDoMixpanel = () => {
  return (
    appConfig.studioPlayerEnvironment !== "development" &&
    appConfig.studioPlayerEnvironment !== "edge"
  );
};

export const initMixpanel = (context: AppContextState) => {
  if (shouldDoMixpanel()) {
    mixpanel.init(appConfig.mixpanelToken, {
      autocapture: false,
      debug: true,
    });
    // as we init mixpanel once user login, so we can just track the event once here too
    trackMixpanelEvent({
      event: "User Login",
      context,
    });
  }
};

export const trackMixpanelEvent = ({
  event,
  properties,
  context,
}: {
  event: string;
  properties?: Record<string, any>;
  context: AppContextState;
}) => {
  if (shouldDoMixpanel()) {
    mixpanel.track(event, {
      ...properties,
      distinct_id: context.currentUser?.id,
      org_id: context.currentOrg?.id,
      env: appConfig.studioPlayerEnvironment,
    });
  }
};
