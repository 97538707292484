import DefaultTvDisplay from "../images/default-tv-display.svg";
import SmartTvDisplay from "../images/smart-tv-display.svg";

export const TVDisplay = (props: {
  isSmartTv: boolean;
  width?: number;
  height?: number;
  content?: React.ReactNode;
  styles?: React.CSSProperties;
  className?: string;
}) => {
  return (
    <div
      style={{
        width: props.width ?? 720,
        height: props.height ?? 454,
        ...props.styles,
      }}
    >
      <img
        className={`${props.className}`}
        src={props.isSmartTv ? SmartTvDisplay : DefaultTvDisplay}
        alt="TV Display"
        width={props.width ?? 720}
        height={props.height ?? 454}
      />
      {props.content}
    </div>
  );
};
