import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

export const Styled = styled.div`
  .loading-message {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    .ui.loader {
      margin-right: 5px;
    }
  }

  &.media-content.apps {
    .content-header {
      h3 {
        margin: 0;
        line-height: 36px;
      }

      .search-filter-container {
        display: flex;
        gap: 8px;
        align-items: center;

        .search {
          width: 100%;
        }

        .clear-all-tags-button {
          border: none;
          box-shadow: none;
          gap: var(--spacing-xxs);
          padding-left: 8px;
          padding-right: 12px;

          &:disabled {
            background: none;

            &:hover {
              background: none;
            }
          }

          @media screen and (max-width: ${Theme.break.mediumAlt}) {
            display: none;
          }
        }
      }
    }

    .container {
      .media-item-header {
        .media-core {
          .media-app-customs,
          .media-application {
            width: 30%;
          }

          @media screen and (max-width: ${Theme.break.mediumAlt}) {
            .media-app-customs,
            .media-application {
              width: 20%;
            }
          }

          @media screen and (max-width: ${Theme.break.small}) {
            .media-alpha {
              width: calc(100% - 160px);
            }

            .media-app-customs {
              display: none;
            }

            .media-application {
              width: 160px;
            }
          }

          @media screen and (max-width: ${Theme.break.tiny}) {
            .media-application {
              display: none;
            }
          }
        }
      }
    }
  }
`;

export interface AppPickerItemProps {
  selected?: boolean;
}

export const AppPickerItem = styled.div<AppPickerItemProps>`
  border: 1px solid transparent;
  border-top-color: ${Theme.color.silver};
  cursor: pointer;
  display: flex;
  padding: 10px 0;

  .media-core {
    width: calc(100% - 60px);
    display: flex;
    align-items: center;

    [class^="media-"] {
      text-align: center;

      span {
        color: ${Theme.color.grey};
        font-size: 0.875em;
        font-style: italic;

        span {
          font-size: initial;
        }
      }
    }

    .media-alpha {
      width: 40%;
      display: flex;
      flex: 0 0 auto;
      cursor: pointer;
      ${Theme.default.borderRadius};

      .thumbnail-preview {
        width: 60px;
        height: 60px;
        position: relative;
        margin: 0 10px;

        .wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          ${Theme.default.borderRadius};
          position: absolute;
          top: 0;
          width: 100%;
          height: 100%;

          img {
            width: auto;
            max-height: 50px;
            max-width: 50px;
            border-radius: 2px;
          }
        }

        :hover {
          background-color: transparent;
        }

        .thumbnail {
          width: 100%;
          height: 100%;
        }
      }

      .media-title {
        width: calc(100% - 80px);
        display: flex;
        flex-direction: column;
        justify-content: center;

        * {
          text-align: left;
        }

        h3 {
          line-height: 19px;
          padding: 0;
          margin-bottom: 5px;
          font-weight: normal;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          border-bottom: 1px solid transparent;

          :hover {
            background-color: transparent;
          }
        }

        div {
          display: flex;

          span {
            line-height: 14px;
            font-size: 14px;
            white-space: nowrap;
          }

          .media-item__url {
            display: none;
          }
        }

        .title-container {
          display: flex;
          align-items: center;
          gap: var(--spacing-lg, 16px);

          &--block {
            display: block;
          }

          &__name {
            white-space: nowrap;
          }

          .media-item__date {
            font-size: var(--font-size-xs, 12px);
            margin-top: 2px;
          }
        }
      }

      :hover {
        background: transparent;
      }
    }
    .media-app-customs,
    .media-application {
      width: 30%;
    }
  }

  .media-checked {
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      opacity: 0;
      background-color: ${Theme.color.darkGrey};
    }
  }

  :hover {
    background-color: rgba(0, 0, 0, 0.01);

    .media-checked {
      i {
        opacity: 0.2;
      }
    }
  }

  ${(props: AppPickerItemProps) =>
    props.selected &&
    `
    ,
    &:hover {
    background-color: ${Theme.color.silver};
    box-shadow: none;
    border-top-color: ${Theme.color.lightGrey};

    .media-checked {
      i {
        opacity: 1;
      }
    }
  `};

  @media screen and (max-width: ${Theme.break.mediumAlt}) {
    .media-core {
      .media-alpha {
        width: 60%;
      }
      .media-app-customs,
      .media-application {
        width: 20%;
      }
    }
  }

  @media screen and (max-width: ${Theme.break.small}) {
    .media-core {
      .media-alpha {
        width: calc(100% - 160px);
      }

      .media-app-customs {
        display: none;
      }

      .media-application {
        width: 160px;
      }
    }
  }

  @media screen and (max-width: ${Theme.break.tiny}) {
    .media-core {
      .media-application {
        display: none;
      }
    }
  }
`;
