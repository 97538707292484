import { ModalSize } from "@screencloud/screencloud-ui-components";
import { FunctionComponent, useState } from "react";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import {
  StyledButton,
  StyledContainer,
  StyledContentContainer,
  StyledIcon,
  StyledMessageContainer,
} from "./styles";
import withActivateBanner, { WithActivateBanner } from "./withActiveBanner";
import { useAppContext } from "src/hooks/useAppContext";
import { TrailExpiredModal } from "./TrialExpiredModal";

interface Props {
  isFullWidth?: boolean;
}

const ActivateBanner: FunctionComponent<Props & WithActivateBanner> = ({
  isShowActivateBanner,
  isFullWidth,
  remainingTrialDays,
  onClickActivateBanner,
  planId,
}) => {
  const context = useAppContext();
  const [verifyClosed, setVerifyClosed] = useState(false);

  // for testing purpose
  const isForceTrialEnd = localStorage.getItem("isTrialEnd") === "true";
  const isTrialEnd = isForceTrialEnd || (remainingTrialDays ?? 0) <= 0;

  const isCorePlan = planId?.includes("core");
  const activateMessageId = isCorePlan
    ? "ads.banner.activate_message_core_trial"
    : "ads.banner.activate_message_default";

  const defaultActivateMessage =
    "{dayLeft} days left! Activate your subscription now.";
  const defaultCoreActivateMessage =
    "{dayLeft} days left! Pay monthly or enjoy savings with an annual subscription.";

  const defaultMessage = isCorePlan
    ? defaultCoreActivateMessage
    : defaultActivateMessage;

  const renderActivateBanner = () => (
    <>
      <StyledIcon name="sparkle" />
      <StyledContentContainer>
        <StyledMessageContainer data-testid="activate-message">
          <FormattedHTMLMessage
            id={activateMessageId}
            defaultMessage={defaultMessage}
            values={{ dayLeft: remainingTrialDays }}
          />
        </StyledMessageContainer>
        <StyledButton
          mini
          onClick={onClickActivateBanner}
          data-testid="activate-button"
        >
          <FormattedMessage
            id="ads.banner.subscribe_now"
            defaultMessage="Subscribe Now"
          />
        </StyledButton>
      </StyledContentContainer>
    </>
  );

  const onGetStartedCallback = (periodUnit: string) => {
    setVerifyClosed(true);
    context.modal.closeModals();
    context.history.push(`/billing/latest/manage?periodUnit=${periodUnit}`);
  };

  const isPlanIdValid = (planId: string | null): boolean => {
    // ex: core-year-gbp
    if (!planId || !planId.includes("-")) {
      return false;
    }
    const planType = planId.split("-")[0];
    return !!(planType === "core" || planType === "pro");
  };

  const renderTrialEndBanner = () => {
    if (isPlanIdValid(planId) && !verifyClosed) {
      context.modal.openModal(
        <TrailExpiredModal
          planId={planId}
          onGetStartedCallback={(periodUnit: string) =>
            onGetStartedCallback(periodUnit)
          }
        />,
        null,
        {
          opts: {
            disableTitle: true,
            closeOnDimmerClick: false,
            size: ModalSize.SMALL,
            closeButtonId: `close-${
              isCorePlan ? "core" : "pro"
            }-trial-expired-modal`,
          },
        },
      );
    }
    return <div />;
  };

  if (isShowActivateBanner) {
    if (isTrialEnd) {
      return renderTrialEndBanner();
    }
    return (
      <StyledContainer
        isFullWidth={isFullWidth || false}
        data-testid="activate-banner"
        className="activate-banner"
        id="activate-banner"
      >
        {renderActivateBanner()}
      </StyledContainer>
    );
  }
  return <div />;
};

export { ActivateBanner };
export default withActivateBanner(ActivateBanner) as React.ComponentType<Props>;
