import { Button, Icon } from "@screencloud/screencloud-ui-components";
import * as React from "react";
import OutsideClickHandler from "react-outside-click-handler";
import {
  API_KEY_PROVIDER_URN,
  BASIC_AUTH_PROVIDER_URN,
  OAUTH2_PROVIDER_URN,
} from "../../../constants";

interface IState {
  isDropdownExpanded: boolean;
}

interface AddCredentialMenuProps {
  filterUrn: string;
  onNewCredentialTypeSelected: (type: string) => void;
}

const API_KEY_MENU_ITEM = { text: "API Key", type: API_KEY_PROVIDER_URN };
const BASIC_AUTH_MENU_ITEM = {
  text: "Single Login",
  type: BASIC_AUTH_PROVIDER_URN,
};
const OAUTH2_MENU_ITEM = { text: "Social Account", type: OAUTH2_PROVIDER_URN };

class AddCredentialMenu extends React.Component<
  AddCredentialMenuProps,
  IState
> {
  constructor(props: AddCredentialMenuProps) {
    super(props);

    this.state = {
      isDropdownExpanded: false,
    };
  }

  public render(): React.ReactNode {
    const { isDropdownExpanded } = this.state;

    const menuItems = this.getMenuItems().map((item, index) => (
      <li
        key={index}
        onClick={() => this.onNewCredentialTypeSelected(item.type)}
      >
        {item.text}
      </li>
    ));

    return (
      <div>
        <div className="credentials-add-wrapper">
          <OutsideClickHandler
            onOutsideClick={() => this.setState({ isDropdownExpanded: false })}
          >
            <Button
              transparent
              onClick={() =>
                this.setState({ isDropdownExpanded: !isDropdownExpanded })
              }
            >
              <Icon name="plus-circle" />
              Add Account
            </Button>
            {isDropdownExpanded && (
              <ol className="new-cred-dropdown-select">{menuItems}</ol>
            )}
          </OutsideClickHandler>
        </div>
      </div>
    );
  }

  private getMenuItems = () => {
    const { filterUrn } = this.props;

    if (filterUrn.startsWith(API_KEY_PROVIDER_URN)) {
      return [API_KEY_MENU_ITEM];
    }

    if (filterUrn.startsWith(BASIC_AUTH_PROVIDER_URN)) {
      return [BASIC_AUTH_MENU_ITEM];
    }

    if (filterUrn.startsWith(OAUTH2_PROVIDER_URN)) {
      return [OAUTH2_MENU_ITEM];
    }

    return [BASIC_AUTH_MENU_ITEM, OAUTH2_MENU_ITEM, API_KEY_MENU_ITEM];
  };

  private onNewCredentialTypeSelected = (type: string) => {
    this.setState({ isDropdownExpanded: false });
    this.props.onNewCredentialTypeSelected(type);
  };
}

export default AddCredentialMenu;
