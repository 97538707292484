import { Config, DriveStep, State, driver } from "driver.js";

import { OnBoardingRefType } from "src/constants/constants";
import apolloClient from "src/state/apolloClient";
import {
  Maybe,
  Org,
  Scalars,
  UpdateOrgOnboardingDataDocument,
  UpdateOrgSettingsDocument,
  UpdateOrgSettingsMutation,
  UpdateOrgSettingsMutationVariables,
  UpdateUserOnboardingDataDocument,
  User,
} from "src/types.g";
import "src/utils/driverjs.theme.css";

const getOrgOnboardingPreferences = (currentOrg: Maybe<Partial<Org>>) => {
  const isOnboardingExist = !!currentOrg?.preferences.onboarding;
  if (isOnboardingExist) {
    const isOnboardingV1Skip =
      currentOrg?.preferences.onboarding[
        OnBoardingRefType.ORG_ONBOARDING_V1_SKIP
      ];
    const isOnboardingV1Done =
      currentOrg?.preferences.onboarding[OnBoardingRefType.ORG_ONBOARDING_V1];
    const isOnboardingV2Skip =
      !!currentOrg?.preferences.onboarding[
        OnBoardingRefType.ORG_ONBOARDING_V2_SKIP
      ];
    const isOnbardingV2Done =
      !!currentOrg?.preferences.onboarding[OnBoardingRefType.ORG_ONBOARDING_V2];
    const isOnboardingV2Invite =
      !!currentOrg?.preferences.onboarding[
        OnBoardingRefType.ORG_ONBOARDING_V2_INVITE
      ];
    const isOnboardingV2ContentSetup =
      !!currentOrg?.preferences.onboarding[
        OnBoardingRefType.ORG_ONBOARDING_V2_CONTENT_SETUP
      ];

    const isWatchedTrialVideo =
      OnBoardingRefType.IS_WATCHED_TRIAL_VIDEO in
      currentOrg?.preferences.onboarding
        ? !!currentOrg?.preferences.onboarding[
            OnBoardingRefType.IS_WATCHED_TRIAL_VIDEO
          ]
        : true;

    return {
      isOnboardingExist,
      isOnboardingV1Skip,
      isOnboardingV1Done,
      isOnboardingV2Skip,
      isOnbardingV2Done,
      isOnboardingV2Invite,
      isOnboardingV2ContentSetup,
      isWatchedTrialVideo,
    };
  }
  return {
    isOnboardingExist,
    isOnboardingV1Skip: false,
    isOnboardingV1Done: false,
    isOnboardingV2Skip: false,
    isOnbardingV2Done: false,
    isOnboardingV2Invite: false,
    isOnboardingV2ContentSetup: false,
    isWatchedTrialVideo: true,
  };
};

const getUserOnboardingPreferences = (currentUser: Maybe<Partial<User>>) => {
  const isOnboardingExist = !!currentUser?.preferences.onboarding;

  if (isOnboardingExist) {
    const isOnboardingTourDone =
      !!currentUser.preferences.onboarding[
        OnBoardingRefType.ORG_ONBOARDING_TOUR_DONE
      ];
    const isOnboardingTourSkip =
      !!currentUser.preferences.onboarding[
        OnBoardingRefType.ORG_ONBOARDING_TOUR_SKIP
      ];
    return {
      isOnboardingExist,
      isOnboardingTourDone,
      isOnboardingTourSkip,
    };
  }
  return {
    isOnboardingExist: false,
    isOnboardingTourDone: false,
    isOnboardingTourSkip: false,
  };
};

const onBoardingSetup = ({
  steps,
  nextBtnText,
  doneBtnText,
  allowClose,
  showProgress,
  onHighlighted,
  onComplete,
  onClose,
}: {
  steps: DriveStep[];
  nextBtnText?: string;
  doneBtnText?: string;
  allowClose?: boolean;
  showProgress?: boolean;
  onHighlighted?: () => void;
  onComplete?: () => void;
  onClose?: () => void;
}) => {
  const driverObj = driver({
    showProgress: showProgress || true,
    allowClose: allowClose || false,
    doneBtnText: doneBtnText || "Done",
    nextBtnText: nextBtnText || "Continue",
    showButtons: ["next", "close"],
    stagePadding: 0,
    steps,
    onHighlighted,
    onPopoverRender: (popover) => {
      // https://github.com/kamranahmedse/driver.js/issues/458
      popover.closeButton.style.display = "";

      if (!showProgress) {
        popover.progress.style.display = "none";
        popover.title.style.margin = "0";
      }
    },
    onCloseClick: () => {
      if (onClose) {
        onClose();
      }
      driverObj.destroy();
    },
    onNextClick: (
      _element: Element,
      _step: DriveStep,
      options: { config: Config; state: State },
    ) => {
      if (options.state.activeIndex === steps.length - 1) {
        if (onComplete) {
          onComplete();
        }
      }
      driverObj.moveNext();
    },
  });
  driverObj.drive();
};

const updateUserOnboarding = (key: string, value: boolean) => {
  return apolloClient.mutate({
    mutation: UpdateUserOnboardingDataDocument,
    variables: {
      input: {
        key,
        value,
      },
    },
  });
};

const updateOrgOnboarding = (key: string, value: boolean | string) => {
  return apolloClient.mutate({
    mutation: UpdateOrgOnboardingDataDocument,
    variables: {
      input: {
        key,
        value,
      },
    },
  });
};

const updateOrgSettings = (
  settingsData: Scalars["JSON"],
  authorization?: string,
) => {
  if (authorization) {
    const orgSettingsVariables = {
      mutation: UpdateOrgSettingsDocument,
      variables: {
        input: {
          settingsData,
        },
      },
    };
    if (authorization) {
      orgSettingsVariables["context"] = {
        headers: {
          authorization,
        },
      };
    }
    return apolloClient.mutate<
      UpdateOrgSettingsMutation,
      UpdateOrgSettingsMutationVariables
    >(orgSettingsVariables);
  } else {
    return Promise.resolve({ data: null });
  }
};

const shouldInstallDemoContent = async ({
  currentOrg,
}: {
  currentOrg: Maybe<Partial<Org>>;
}) => {
  const { isOnboardingV2ContentSetup } =
    getOrgOnboardingPreferences(currentOrg);

  return !isOnboardingV2ContentSetup;
};

const getContactUrl = ({
  firstName,
  lastName,
  email,
  orgName,
  jobTitle,
  country,
}: {
  firstName: string;
  lastName: string;
  email: string;
  orgName: string;
  jobTitle: string;
  country: string;
}) => {
  let url = "https://screencloud.com/contact?";

  const params = {
    firstname: firstName,
    lastname: lastName,
    email,
    company: orgName,
    jobtitle: jobTitle,
    country,
  };

  Object.keys(params).forEach((key, index) => {
    if (params[key]) {
      url += `${index !== 0 ? "&" : ""}${key}=${encodeURIComponent(
        params[key],
      )}`;
    }
  });

  return url;
};

export {
  onBoardingSetup,
  updateOrgOnboarding,
  updateOrgSettings,
  updateUserOnboarding,
  getOrgOnboardingPreferences,
  shouldInstallDemoContent,
  getUserOnboardingPreferences,
  getContactUrl,
};
