import {
  extractAndGroupStudioRegions,
  IStudioRegionGroups,
} from "@screencloud/studio-regions";
import { BrowserOptions } from "@sentry/browser";
import { Locale, ReactAppEnvironment } from "./constants/constants";

/**
 * Env variables are exposed globally with current build config. This function returns
 * a collection of injected env variables.
 */
export function globalEnvSet(): { [key: string]: string } {
  return Object.keys(import.meta.env)
    .filter((key) => key.startsWith("VITE_REACT_APP_"))
    .reduce((result, key) => {
      const newKey = key;
      result[newKey.replace("VITE_", "")] = import.meta.env[key];
      return result;
    }, {});
}

// console.log(globalEnvSet());

const regions = extractAndGroupStudioRegions(globalEnvSet());

export interface AppConfig {
  auth: {
    service: string;
    frontend: string;
    autoRefreshSession: boolean;
    autoSyncSession: boolean;
    debug: boolean;
  };
  reactEnvironment: ReactAppEnvironment;
  environment: "development" | string;
  embedPlayerUrl: string;
  secureSiteChromeExtensionId: string;
  secureSiteChromeExtensionUrl: string;
  secureSiteTestChromeExtensionId: string;
  release: string;
  appServicesUrl: string;
  appConnectionsUrl: string;
  authSSOLoginUrl: string;
  authSignageAudience: string;
  appstoreGraphqlEndpoint: string;
  baseUrl: string;
  billingEnabled: boolean;
  billingBaseURL: string;
  billingArrThreshold: number;
  fileStackApiKey: string;
  fileStackSecurityPolicy: string;
  fileStackSecuritySignature: string;
  filestackMaxFileUpload: number;
  firebase: {
    apiKey: string;
    authDomain: string;
    projectId: string;
  };
  imgixBaseUrl: string;
  mediaOrigin: string;
  link: {
    cloudServiceUrl: string;
    credentialServerUrl: string;
    validationServiceUrl: string;
  };
  sentry?: BrowserOptions;
  s3Region: string;
  signagePlayerUrl: string;
  regions: IStudioRegionGroups;
  stripePublicKey: string;
  supportedLocales: Locale[];
  uploadsBaseUrl: string;
  uploadsBucket: string;
  uploadsLocation: string;
  altUploadBucket: string;
  userMedia: string;
  studioPlayerUrl: string;
  studioPlayerRegion: string;
  studioPlayerEnvironment: string;
  studioCurrentRegion: string;
  sitesViewerUrl: string;
  googleMapsKey: string;
  brightback_return_url: string;
  brightback_app_id: string;
  playerLogsServiceUrl: string;
  hubspot: {
    account_id: string;
    form_id: string;
    invited_form_id: string;
    subscription_id: string;
    onboarding_form_id: string;
  };
  qrServiceClientId: string;
  secureMediaUrl: string;
  rdmDashboardUrl: string;
  chargebeeSite: string;
  scim: {
    enabled: boolean;
    baseUrl: string;
  };
  previewUrl?: string;
  platformConnectionsUrl: string;
  platformFeedsUrl: string;
  intercomAppId: string;
  rdmClientUrl: string;
  pixiStoreUrl: string;
  scosStoreUrl: string;
  intercomTours: {
    setScreenContentTourId: string;
  };
  mixpanelToken: string;
  virtualScreenUrl: string;
}

const release =
  `${import.meta.env.VITE_REACT_APP_SENTRY_RELEASE}` || "development";
const environment =
  `${import.meta.env.VITE_REACT_APP_SENTRY_ENV}` || "development";
const reactEnvironment =
  import.meta.env.VITE_REACT_APP_ENV === "production"
    ? ReactAppEnvironment.Production
    : ReactAppEnvironment.Development;

export const appConfig: Readonly<AppConfig> = Object.freeze({
  auth: {
    service: import.meta.env.VITE_REACT_APP_AUTH_SERVICE_BASE_URL,
    frontend: import.meta.env.VITE_REACT_APP_AUTH_APP_BASE_URL,
    autoRefreshSession:
      import.meta.env.VITE_REACT_APP_AUTH_REFRESH_SESSION === "true",
    autoSyncSession:
      import.meta.env.VITE_REACT_APP_AUTH_SYNC_SESSION === "true",
    debug: import.meta.env.VITE_REACT_APP_AUTH_DEBUG === "true",
  },
  altUploadBucket: `${import.meta.env.VITE_REACT_APP_ALT_UPLOAD_BUCKET}`,
  appServicesUrl: `${import.meta.env.VITE_REACT_APP_SERVICES_URL}`,
  appConnectionsUrl: `${import.meta.env.VITE_REACT_APP_CONNECTIONS_URL}`,
  appstoreGraphqlEndpoint: `${
    import.meta.env.VITE_REACT_APP_APPSTORE_GRAPHQL_ENDPOINT
  }`,
  authSSOLoginUrl: `${import.meta.env.VITE_REACT_APP_AUTH_SSO_LOGIN_URL}`,
  authSignageAudience: `${import.meta.env.VITE_REACT_APP_SIGNAGE_AUDIENCE}`,
  baseUrl: regions.current.app.baseUrl,
  billingEnabled: !(
    reactEnvironment === ReactAppEnvironment.Development &&
    import.meta.env.VITE_REACT_APP_BILLING_ENABLED === "false"
  ),
  billingBaseURL: `${import.meta.env.VITE_REACT_APP_BILLING_BASE_URL}`,
  // $12k+ ARR is considered a mangaged customer
  billingArrThreshold: 1200000,
  environment,
  embedPlayerUrl: `${import.meta.env.VITE_REACT_APP_EMBED_PLAYER_URL}`,
  secureSiteChromeExtensionId: `${
    import.meta.env.VITE_REACT_APP_SECURE_SITES_EDITOR_EXTENSION_ID
  }`,
  secureSiteChromeExtensionUrl: `${
    import.meta.env.VITE_REACT_APP_SECURE_SITES_EDITOR_EXTENSION_URL
  }`,
  secureSiteTestChromeExtensionId: `${
    import.meta.env.VITE_REACT_APP_SECURE_SITES_EDITOR_TEST_EXTENSION_ID
  }`,
  fileStackApiKey: `${import.meta.env.VITE_REACT_APP_PUBLIC_FILESTACK_API_KEY}`,
  fileStackSecurityPolicy: `${
    import.meta.env.VITE_REACT_APP_FILESTACK_SECURITY_POLICY
  }`,
  fileStackSecuritySignature: `${
    import.meta.env.VITE_REACT_APP_FILESTACK_SECURITY_SIGNATURE
  }`,
  filestackMaxFileUpload: import.meta.env
    .VITE_REACT_APP_FILESTACK_MAX_FILE_UPLOAD,
  firebase: {
    apiKey: `${import.meta.env.VITE_REACT_APP_PUBLIC_FIREBASE_API_KEY}`,
    authDomain: `${import.meta.env.VITE_REACT_APP_FIREBASE_AUTH_DOMAIN}`,
    projectId: `${import.meta.env.VITE_REACT_APP_FIREBASE_PROJECT_ID}`,
  },
  imgixBaseUrl: `${import.meta.env.VITE_REACT_APP_IMGIX_BASE_URL}`,
  link: {
    cloudServiceUrl: `${import.meta.env.VITE_REACT_APP_LINK_CLOUD_SERVICE_URL}`,
    credentialServerUrl: `${
      import.meta.env.VITE_REACT_APP_LINK_CREDENTIAL_SERVER_URL
    }`,
    validationServiceUrl: `${
      import.meta.env.VITE_REACT_APP_LINK_VALIDATION_SERVICE_URL
    }`,
  },
  mediaOrigin: `${import.meta.env.VITE_REACT_APP_MEDIA_ORIGIN}`,
  reactEnvironment,
  regions,
  release,
  s3Region: import.meta.env.VITE_REACT_APP_S3_REGION,
  sentry: import.meta.env.VITE_REACT_APP_SENTRY_DSN
    ? {
        dsn: `${import.meta.env.VITE_REACT_APP_SENTRY_DSN}`,
        environment,
        release,
      }
    : undefined,
  signagePlayerUrl: `${import.meta.env.VITE_REACT_APP_SIGNAGE_PLAYER_URL}`,
  stripePublicKey: `${import.meta.env.VITE_REACT_APP_PUBLIC_STRIPE_KEY}`,
  supportedLocales: [Locale.en, Locale.th],
  uploadsBaseUrl: `${import.meta.env.VITE_REACT_APP_UPLOADS_BASE_URL}`,
  uploadsBucket: `${import.meta.env.VITE_REACT_APP_UPLOADS_BUCKET}`,
  uploadsLocation: `${import.meta.env.VITE_REACT_APP_UPLOADS_LOCATION}` || "s3",
  userMedia: `${import.meta.env.VITE_REACT_APP_USER_MEDIA_VIEW}`,
  studioPlayerUrl: `${import.meta.env.VITE_REACT_APP_STUDIO_PLAYER_URL}`,
  studioPlayerRegion: `${import.meta.env.VITE_REACT_APP_STUDIO_PLAYER_REGION}`,
  studioPlayerEnvironment: `${
    import.meta.env.VITE_REACT_APP_STUDIO_PLAYER_ENVIRONMENT
  }`,
  studioCurrentRegion: `${
    import.meta.env.VITE_REACT_APP_STUDIO_REGION_CURRENT_KEY
  }`,
  sitesViewerUrl: `${import.meta.env.VITE_REACT_APP_SECURE_SITES_VIEWER_URL}`,
  googleMapsKey: import.meta.env.VITE_REACT_APP_PUBLIC_GOOGLE_MAPS_API_KEY,
  brightback_return_url: `${
    import.meta.env.VITE_REACT_APP_BRIGHTBACK_RETURN_URL
  }`,
  brightback_app_id: `${import.meta.env.VITE_REACT_APP_BRIGHTBACK_APP_ID}`,
  playerLogsServiceUrl: `${
    import.meta.env.VITE_REACT_APP_PLAYER_LOGS_SERVICE_URL
  }`,
  hubspot: {
    account_id: `${import.meta.env.VITE_REACT_APP_HUBSPOT_ACCOUNT_ID}`,
    form_id: `${import.meta.env.VITE_REACT_APP_HUBSPOT_FORM_ID}`,
    invited_form_id: `${
      import.meta.env.VITE_REACT_APP_HUBSPOT_INVITED_FORM_ID
    }`,
    subscription_id: `${
      import.meta.env.VITE_REACT_APP_HUBSPOT_SUBSCRIPTION_ID
    }`,
    onboarding_form_id: `${
      import.meta.env.VITE_REACT_APP_HUBSPOT_ONBOARDING_FORM_ID
    }`,
  },
  qrServiceClientId: `${import.meta.env.VITE_REACT_APP_QR_SERVICE_CLIENT_ID}`,
  secureMediaUrl: `${import.meta.env.VITE_REACT_APP_SECURE_MEDIA_URL}`,
  rdmDashboardUrl: `${import.meta.env.VITE_REACT_APP_RDM_DASHBOARD_URL}`,
  chargebeeSite: `${import.meta.env.VITE_REACT_APP_CHARGEBEE_ACCOUNT}`,
  scim: {
    enabled: `${import.meta.env.VITE_REACT_APP_SCIM_ENABLED}` === "true",
    baseUrl: `${import.meta.env.VITE_REACT_APP_SCIM_BASE_URL}`,
  },
  previewUrl: import.meta.env.VITE_REACT_APP_PREVIEW_URL ?? null,
  platformConnectionsUrl: `${
    import.meta.env.VITE_REACT_APP_PLATFORM_CONNECTIONS_URL
  }`,
  platformFeedsUrl: `${import.meta.env.VITE_REACT_APP_API_FEEDS_URL}`,
  intercomAppId: `${import.meta.env.VITE_REACT_APP_INTERCOM_APP_ID}`,
  rdmClientUrl: `${import.meta.env.VITE_REACT_APP_RDM_CLIENT_URL}`,
  pixiStoreUrl: `${import.meta.env.VITE_REACT_APP_PIXI_STORE_URL}`,
  scosStoreUrl: `${import.meta.env.VITE_REACT_APP_SCOS_STORE_URL}`,
  intercomTours: {
    setScreenContentTourId: `${import.meta.env.VITE_REACT_APP_INTERCOM_SET_SCREEN_CONTENT_TOUR_ID}`,
  },
  mixpanelToken: `${import.meta.env.VITE_REACT_APP_MIXPANEL_TOKEN}`,
  virtualScreenUrl: `${import.meta.env.VITE_REACT_APP_VIRTUAL_SCREEN_URL}`,
} as AppConfig);
