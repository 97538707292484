import { useState } from "react";
import { AxiosError } from "axios";
import * as api from "@screencloud/billing-client-api";

import { getClient } from "src/billinglatest/clients/service.client";
import { useAppContext } from "src/hooks/useAppContext";

import { HookProps, Screen } from "src/billinglatest/types";

/**
 * This mapper function currently does nothing, as the api type is the same as the UI type.
 * However, it's here to allow for future changes to the API without affecting the UI, severing the
 * dependency between the two. If we decide in future to make a change to the API, we can do so without
 * affecting the UI, as long as we update this mapper function to map the new API type to the existing UI type.
 *
 * @param screen - Screen object from the API
 */
const mapFromApi = (screen: api.Screens.Me.Get.Response): Screen => {
  return screen;
};

export interface UseScreens {
  set: (input: Screen) => void;
  get: () => Screen | undefined;
  fetch: () => Promise<void>;
  refetch: () => Promise<void>;
}

export function useScreens(props?: HookProps): UseScreens {
  const context = useAppContext();

  const [_get, _set] = useState<Screen>();

  /**
   * Essential Methods.
   *
   * This section includes essential methods that form the core of the hook's functionality. These methods are crucial
   * and are unlikely to require any updates in the future.
   */

  /**
   * Set the screens.
   */
  const set = (input: Screen): void => {
    return _set(input);
  };

  /**
   * Return the screens.
   */
  const get = (): Screen | undefined => {
    return _get;
  };

  /**
   * Fetches the screens from the billing service.
   *
   * @remarks
   * This function retrieves the screens from the billing service and stores it within the hook.
   */
  const fetch = async (): Promise<void> => {
    const start = Date.now();

    try {
      const response = await getClient()?.screens.getScreens(context.currentSpace?.id);
      const screens = mapFromApi(response || ({} as api.Screens.Me.Get.Response));
      console.log(`useScreens: fetch() took ${Date.now() - start} ms`);
      set(screens);
    } catch (error) {
      if (error instanceof AxiosError) {
        console.error("Fetch failed for screens: ", error.response?.data.message);
      } else if (error instanceof Error) {
        console.error("Fetch failed for screens: ", error.message);
      } else {
        console.error("Fetch failed for screens");
      }
    }
  };

  /**
   * A shortcut for the `fetch` function.
   *
   * @remarks
   * This function acts as a convenient alternative to the fetch function. Calling refetch() produces the same outcome
   * as calling fetch()
   */
  const refetch = async (): Promise<void> => {
    return fetch();
  };

  /**
   * Return the hook
   */
  return {
    set,
    get,
    fetch,
    refetch,
  };
}
