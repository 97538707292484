import styled from "styled-components";
import videoThumbnail from "src/components/EmptyState/NewTrialEmptyScreenSection/images/video-thumbnail.png";

export const VideoCardStyled = styled.div`
  background: white;
  border-radius: 8px;
  padding: 24px;
  max-width: 600px;
  display: flex;
  padding: var(--spacing-lg, 16px);
  gap: var(--spacing-lg, 16px);
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  flex-direction: column;
  justify-content: space-around;
`;

export const Description = styled.div`
  color: #19191b;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export const CTALink = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 5px;
  color: #19191b;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: none;
  cursor: pointer;
`;

export const VideoThumbnail = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 16/9;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid #e5e7eb;
  &:hover {
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(
        255,
        200,
        0,
        0.2
      ); /* Yellow overlay with opacity */
      pointer-events: none;
    }
  }
`;

export const ThumbnailBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${videoThumbnail});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export const PlayButtonOverlay = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 64px;
  height: 64px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PlayButtonTriangle = styled.div`
  width: 0;
  height: 0;
  border-top: 12px solid transparent;
  border-left: 20px solid white;
  border-bottom: 12px solid transparent;
  margin-left: 4px;
`;
