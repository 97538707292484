import { AppContextState, AppContextType } from "src/AppContextProvider/type";
import { SUBSCRIPTION_STATUS } from "src/constants/constants";
import { FEATURE_FLAGS_ENUM } from "src/constants/featureFlag";
import { Countries, EuCountries } from "src/billing/constants";

export const isPixiTrialist = (context: AppContextType | AppContextState) => {
  const isTrialist =
    context.subscription?.status === SUBSCRIPTION_STATUS.IN_TRIAL;
  const isPixiEnabled = context.shouldShowFeature(
    FEATURE_FLAGS_ENUM.PIXI_TRIALIST,
  );
  return isTrialist && isPixiEnabled;
};

export const isTrialist = (context: AppContextType | AppContextState) => {
  return context.subscription?.status === SUBSCRIPTION_STATUS.IN_TRIAL;
};

type CountryConfig<T> = {
  eu: T;
  us: T;
  ca: T;
  gb: T;
  default: T;
};

// Check if the country is in the EU (excluding GB)
const isEUNonGBCountry = (countryCode: string) =>
  EuCountries.filter((c) => c !== "GB").includes(countryCode);

const getCountrySpecificConfg = <T extends unknown>(
  countryCode: string,
  config: CountryConfig<T>,
): T => {
  if (isEUNonGBCountry(countryCode)) {
    return config.eu;
  }

  switch (countryCode) {
    case Countries.US:
    case "US":
      return config.us;

    case Countries.CA:
    case "CA":
      return config.ca;

    case Countries.GB:
    case "GB":
      return config.gb;

    default:
      return config.default;
  }
};

type PriceInfo = {
  fullPrice: number;
  discountPrice: number;
  currency: string;
  currencySymbol: string;
};

/**
 * Gets the pricing information based on the country code
 *
 * @param countryCode - Two-letter country code (e.g., 'US', 'GB')
 * @returns PriceInfo object containing pricing details
 */
export const getSCOSPrice = (countryCode: string): PriceInfo => {
  return getCountrySpecificConfg(countryCode, {
    eu: {
      fullPrice: 179,
      discountPrice: 89.5,
      currency: "EUR",
      currencySymbol: "€",
    },
    us: {
      fullPrice: 199,
      discountPrice: 99.5,
      currency: "USD",
      currencySymbol: "$",
    },
    ca: {
      fullPrice: 279,
      discountPrice: 139.5,
      currency: "CAD",
      currencySymbol: "CA$",
    },
    gb: {
      fullPrice: 149,
      discountPrice: 74.5,
      currency: "GBP",
      currencySymbol: "£",
    },
    default: {
      fullPrice: 199,
      discountPrice: 99.5,
      currency: "USD",
      currencySymbol: "$",
    },
  });
};

export const getPixiPrice = (countryCode: string): PriceInfo => {
  return getCountrySpecificConfg(countryCode, {
    eu: {
      fullPrice: 75,
      discountPrice: 55,
      currency: "EUR",
      currencySymbol: "€",
    },
    us: {
      fullPrice: 65,
      discountPrice: 40,
      currency: "USD",
      currencySymbol: "$",
    },
    ca: {
      fullPrice: 95,
      discountPrice: 60,
      currency: "CAD",
      currencySymbol: "CA$",
    },
    gb: {
      fullPrice: 55,
      discountPrice: 35,
      currency: "GBP",
      currencySymbol: "£",
    },
    default: {
      fullPrice: 85,
      discountPrice: 60,
      currency: "USD",
      currencySymbol: "$",
    },
  });
};

export const getScosCouponCode = (countryCode: string): string => {
  return getCountrySpecificConfg(countryCode, {
    eu: "TRIAL50",
    us: "TRIAL50",
    ca: "TRIAL50",
    gb: "TRIAL50",
    default: "TRIAL50",
  });
};

interface WistiaVideoOptions {
  videoId: string;
  volume?: number;
  autoPlay?: boolean;
  captions?: boolean;
}

export const playWistiaVideo = (options: WistiaVideoOptions) => {
  const { videoId, volume = 1, autoPlay = true, captions = true } = options;
  // Load Wistia script
  const script = document.createElement("script");
  script.src = "https://fast.wistia.com/assets/external/E-v1.js";
  script.async = true;
  document.body.appendChild(script);

  // Initialize Wistia video with specified volume
  const handleWistiaInit = () => {
    if ((window as any)._wq) {
      (window as any)._wq = (window as any)._wq || [];
      (window as any)._wq.push({
        id: videoId,
        onReady: (video: any) => {
          video.volume(volume);

          // Handle autoplay if specified
          if (autoPlay) {
            video.play();
          } else {
            // Explicitly pause the video when autoPlay is false
            video.pause();
          }

          // Turn off captions if specified
          if (!captions) {
            if (video.plugin && video.plugin.captions) {
              video.plugin.captions.hide();
            }
          }
        },
      });
    }
  };

  // Check if Wistia is already loaded
  if ((window as any).Wistia) {
    handleWistiaInit();
  } else {
    // If not loaded yet, set up event listener
    script.onload = handleWistiaInit;
  }

  return () => {
    // Clean up script when component unmounts
    if (script.parentNode) {
      document.body.removeChild(script);
    }
  };
};
