import styled from "styled-components";

export const StepProgressContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const Step = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-sm, 8px);
`;

export const StepNumberStyled = styled.div<{
  active?: boolean;
  isComplete?: boolean;
  isBullet?: boolean;
}>`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 14px;
  border: 2px solid
    ${(props) =>
      props.isBullet
        ? "var(--color-gray-950, #19191B)"
        : props.active
          ? "var(--color-gray-950, #19191B)"
          : "var(--color-gray-400, #7D7E84)"};
  color: ${(props) =>
    props.isBullet
      ? "var(--color-gray-950, #19191B)"
      : props.isComplete
        ? "var(--color-white-100, #FFF)"
        : props.active
          ? "var(--color-gray-950, #19191B)"
          : "var(--color-gray-400, #7D7E84)"};

  background-color: ${(props) =>
    props.isComplete ? "var(--color-gray-950, #19191B)" : ""};
`;

export const StepTitle = styled.div<{ active: boolean }>`
  white-space: nowrap;
  font-size: 14px;
  font-weight: ${(props) => (props.active ? "bold" : "normal")};
  color: ${(props) =>
    props.active
      ? "var(--color-gray-950, #19191B)"
      : "var(--color-gray-400, #7D7E84)"};
`;

export const Connector = styled.div`
  flex-grow: 1;
  height: 1px;
  background-color: var(--color-gray-400, #7d7e84);
  margin: 0 10px;
`;
