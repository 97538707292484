import * as React from "react";
import { Styled } from "./styles";

export interface HeaderProps {
  children?: React.ReactNode;
  className?: string;
  id?: string;
  ["data-testid"]?: string;
  isShowBackButton?: boolean;
}

const Header = (props: HeaderProps) => {
  const { id, className, children, isShowBackButton = true } = props;
  return (
    <Styled
      data-testid={props["data-testid"]}
      className={className}
      id={id}
      isShowBackButton={isShowBackButton}
    >
      {children}
    </Styled>
  );
};

export default Header;
