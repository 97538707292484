import { MultiSelectDropdown } from "@screencloud/screencloud-ui-components";
import { Styled } from "src/components/TagFilterDropdown/styles";
import { useGetScreenSize } from "src/hooks/useGetScreenSize";
import {
  TagsByAppIdAndSpaceIdQueryVariables,
  useTagsByAppIdAndSpaceIdQuery,
} from "src/types.g";

interface TagFilterDropdownProps {
  className: string;
  selectedTags: string[];
  appId: string;
  spaceId: string;
  onSelect: (tagIds: string[]) => void;
  overlayWhenClick?: boolean;
}

export const TagFilterDropdown = ({
  selectedTags,
  appId,
  spaceId,
  onSelect,
  overlayWhenClick = false,
}: TagFilterDropdownProps) => {
  const { isTabletView } = useGetScreenSize();

  // Fetch all tags
  const variables: TagsByAppIdAndSpaceIdQueryVariables = {
    appId,
    spaceId,
    isTemplate: false,
    isShared: false,
  };
  const fetchConfigs = {
    fetchPolicy: "cache-and-network" as const,
    skip: !appId,
  };
  const { data: ownedTags } = useTagsByAppIdAndSpaceIdQuery({
    variables,
    ...fetchConfigs,
  });
  const { data: sharedTags } = useTagsByAppIdAndSpaceIdQuery({
    variables: { ...variables, isShared: true },
    ...fetchConfigs,
  });

  const availableTags = Array.from(
    new Set([
      ...(ownedTags?.tagsByAppIdAndSpaceId ?? []),
      ...(sharedTags?.tagsByAppIdAndSpaceId ?? []),
    ]),
  )
    .filter((tag) => tag !== null)
    .sort()
    .map((tag: string) => ({
      key: tag,
      text: tag,
      value: tag,
    }));

  return (
    <Styled overlayWhenClick={overlayWhenClick}>
      <MultiSelectDropdown
        data-testid="tag-filter-dropdown"
        name="Tag Filter"
        multiple
        selection
        showClearAll={false}
        placeholder="Filter by tags"
        searchPlaceholder="Search tags..."
        options={availableTags}
        value={selectedTags}
        onChange={(e, { value }) => {
          onSelect(value);
        }}
        formatSelectedValues={() => "Filter by tags"}
        icon={isTabletView ? "filter" : undefined}
      />
    </Styled>
  );
};
