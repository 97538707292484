import { ApolloQueryResult } from "@apollo/client";
import { ssm } from "../state/session/ssm";
import {
  ScreenListQuery,
  ScreenListQueryResult,
  ScreenListQueryVariables,
  useScreenListQuery,
} from "../types.g";

/**
 * searchString - use this to show in textbox
 * query - use this when query
 * updateSearchString - update string and update query with 500 millisecond debounce
 * clearSearce - use this instead of updateSearchString when want to clear query as this has no delay
 */

export type ScreenListQueryDataType = {
  defaultScreenGroupBySpaceId?: ScreenListQuery["defaultScreenGroupBySpaceId"];
  spaceById?: ScreenListQuery["spaceById"];
  loading: ScreenListQueryResult["loading"];
  refetch: ScreenListQueryResult["refetch"];
};

export type ScreenListQueryDataProps = {
  data: ScreenListQueryDataType;
  hasNextPage: boolean;
  loadMore: () => Promise<ApolloQueryResult<ScreenListQuery>>;
};

export function useScreenList() {
  const queryVar: ScreenListQueryVariables = {
    spaceId: ssm.current.settings.spaceId,
    filter: {},
    endCursor: null,
  };

  const screenList = useScreenListQuery({
    variables: queryVar,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  const data: ScreenListQueryDataType = {
    defaultScreenGroupBySpaceId: screenList.data?.defaultScreenGroupBySpaceId,
    spaceById: screenList.data?.spaceById,
    loading: screenList.loading,
    refetch: screenList.refetch,
  };

  const loadMore = () =>
    screenList.fetchMore({
      variables: {
        endCursor:
          screenList.data?.spaceById?.screenGroupsBySpaceId.pageInfo.endCursor,
      },
    });

  const filter = (tags: string[]) => {
    data.refetch({
      spaceId: ssm.current.settings.spaceId,
      endCursor: null,
      filter: {
        and: [{ tags: { contains: tags } }],
      },
    });
  };

  const hasNextPage =
    data?.spaceById?.screenGroupsBySpaceId?.pageInfo &&
    data?.spaceById?.screenGroupsBySpaceId.nodes.length !==
      data?.spaceById?.screenGroupsBySpaceId.totalCount
      ? data.spaceById.screenGroupsBySpaceId.pageInfo.hasNextPage
      : false;

  return { data, filter, hasNextPage, loadMore };
}
