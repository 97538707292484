import {
  Breadcrumb,
  BreadcrumbDivider,
  BreadcrumbSection,
  Button,
  Icon,
  LoaderBar,
  Search,
} from "@screencloud/screencloud-ui-components";
import { useEffect, useState } from "react";
import uniqBy from "lodash/uniqBy";
import { FormattedMessage } from "react-intl";
import classNames from "clsx";
import { UUID } from "src/constants/constants";
import { AppInstance } from "src/types.g";
import { AppInstances } from "src/components/AppPicker/components/AppInstances";
import SearchAppPicker from "src/components/AppPicker/components/SearchAppInstances";
import { AppPickerItem, Styled } from "src/components/AppPicker/styles";
import EmptyState from "src/components/EmptyState";
import { useAppContext } from "src/hooks/useAppContext";
import { useTagSelection } from "src/components/TagList/useTagSelection";
import { useAppPicker } from "src/components/AppPicker/hooks/useAppPicker";
import { TagFilterDropdown } from "src/components/TagFilterDropdown";
import { useGetScreenSize } from "src/hooks/useGetScreenSize";
import { useAppToggleSelection } from "src/hooks/useAppToggleSelection";
import { getSearchTerms } from "src/components/AppPicker/utils";
import { useSearchAppInstancePickerByAppId } from "src/components/AppPicker/hooks/useSearchAppInstancePickerByAppId";
import { useSearchAppInstancePicker } from "src/components/AppPicker/hooks/useSearchAppInstancePicker";
import { useMediaPickerAppInstances } from "src/components/AppPicker/hooks/useAppInstancePicker";
import { MediaPickerSelectedTagList } from "../MediaPickerSelectedTagList";

export interface Props {
  callback: (ids: string[], selectedApps: AppInstance[]) => void;
  isMultipleSelect?: boolean;
  spaceId?: UUID;
}

const AppPicker = (props: Props) => {
  const { isMultipleSelect, callback, spaceId } = props;

  const [customContainer, setCustomContainer] = useState<HTMLDivElement | null>(
    null,
  );
  const [appId, setAppId] = useState("");

  const context = useAppContext();

  const { isTabletView } = useGetScreenSize();
  const {
    onClickTag,
    onSetelectTags,
    selectedTagForQuery,
    onClearTags,
    hasSelectedTags,
    selectedTags,
  } = useTagSelection();

  const {
    searchString,
    query,
    clearSearch,
    updateSearchString,
    loading,
    apps,
  } = useAppPicker(spaceId);

  const searchTerms = getSearchTerms(query);
  const searchAppInstance = appId
    ? useSearchAppInstancePickerByAppId(searchTerms, appId, spaceId)
    : useSearchAppInstancePicker(searchTerms, spaceId as UUID);

  const appInstances = useMediaPickerAppInstances({
    appId,
    tags: selectedTagForQuery,
  });

  const allAppInstances = useMediaPickerAppInstances({
    appId,
  });

  const { selectedIds, onToggleSelection } = useAppToggleSelection({
    isMultipleSelect,
    appInstances: !appId
      ? searchAppInstance.appInstances
      : uniqBy(
          [
            ...allAppInstances.appInstances,
            ...searchAppInstance.appInstances,
            ...appInstances.appInstances,
          ],
          "id",
        ),
    callback,
  });

  useEffect(() => {
    if (!appId) {
      onClearTags();
    }
  }, [appId]);

  const goBackToAppInstall = () => setAppId("");
  const gotoAppInstanceList = (newAppId: UUID) => setAppId(newAppId);
  const getAppNameById = (appId: string) =>
    apps.find((item) => item.id === appId)?.name;
  const setContainerRef = (el: HTMLDivElement | null) => {
    if (el && !customContainer) setCustomContainer(el);
  };

  const renderHeader = () => (
    <div
      className={classNames("content-header", {
        "media-tags": hasSelectedTags,
      })}
    >
      <div className="search-filter-container">
        <Search
          className="search"
          onClear={clearSearch}
          onChange={(e, { value }) => updateSearchString(value)}
          placeholder="Search Apps"
          showNoResults={false}
          value={searchString}
        />
        {appId && (
          <>
            <TagFilterDropdown
              className="filter-dropdown"
              selectedTags={selectedTags}
              appId={appId}
              spaceId={context.user.settings.spaceId}
              onSelect={onSetelectTags}
              overlayWhenClick={isTabletView}
            />
            <Button
              onClick={onClearTags}
              className="clear-all-tags-button"
              data-testid="clear-all-tags-button"
              disabled={!hasSelectedTags}
            >
              <Icon name="close" />
              <FormattedMessage
                id="ui_component.common.label.clear_all"
                defaultMessage="Clear All"
              />
            </Button>
          </>
        )}
      </div>

      {hasSelectedTags && (
        <MediaPickerSelectedTagList
          selectedTags={selectedTags}
          onClearTags={onClearTags}
          onClickTag={onClickTag}
        />
      )}
    </div>
  );

  const renderBreadcrumb = () =>
    apps.length && !searchString ? (
      <Breadcrumb>
        <BreadcrumbSection>
          <Button transparent onClick={goBackToAppInstall}>
            <FormattedMessage id="apps.apps" defaultMessage="Apps" />
          </Button>
        </BreadcrumbSection>
        {appId && <BreadcrumbDivider />}
        <BreadcrumbSection active>{getAppNameById(appId)}</BreadcrumbSection>
      </Breadcrumb>
    ) : null;

  const renderAppInstallList = () =>
    apps.map((app) => (
      <AppPickerItem
        className="media-item"
        key={`apps-${app.id}`}
        onClick={() => gotoAppInstanceList(app?.id)}
      >
        <div className="media-core">
          <div className="media-alpha">
            <div className="thumbnail-preview">
              <div className="thumbnail">
                <div className="wrapper">
                  <img src={app.iconUrl!} alt={app.name} />
                </div>
              </div>
            </div>
            <div className="media-title">
              <h3>{app?.name}</h3>
              <div>
                <span className="media-item__date">
                  {app?.categories?.join(",")}
                </span>
              </div>
            </div>
          </div>
        </div>
      </AppPickerItem>
    ));

  const renderEmptyState = () => (
    <EmptyState section="apps" className="empty">
      <h3>
        <FormattedMessage
          id="apps.get_started"
          defaultMessage="This is where your apps will live"
        />
      </h3>
      <p>
        <FormattedMessage
          id="apps.no_apps_blurb"
          defaultMessage="Use the App Store to create screen-ready content from sources such as Quick Post, Slack and OneDrive."
        />
      </p>
    </EmptyState>
  );

  const renderContent = () => {
    if (searchString.length > 0) {
      return (
        <SearchAppPicker
          selectedIds={selectedIds}
          onToggleSelection={onToggleSelection}
          {...searchAppInstance}
        />
      );
    }

    if (!apps.length) {
      return renderEmptyState();
    }

    return appId === "" ? (
      renderAppInstallList()
    ) : (
      <AppInstances
        onClickTag={onClickTag}
        selectedIds={selectedIds}
        onToggleSelection={onToggleSelection}
        {...appInstances}
      />
    );
  };

  return (
    <Styled
      className={classNames("media-content apps", {
        "apps-search": searchString.length,
      })}
    >
      {loading && <LoaderBar />}
      {renderHeader()}
      <div className="container">
        {renderBreadcrumb()}
        <div
          className="layout-list"
          id="scrollableDivAppInstance"
          ref={setContainerRef}
        >
          {renderContent()}
        </div>
      </div>
    </Styled>
  );
};

export default AppPicker;
