import styled from "styled-components";
import { Theme } from "@screencloud/screencloud-ui-components";

export const Styled = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  @media screen and (max-width: ${Theme.break.medium}) {
    justify-content: flex-end;
  }

  .clear-all-tags-button-mobile {
    min-width: 64px;
    height: 40px;
    margin-left: var(--spacing-sm, 8px);
  }
`;
