import { useCallback, useState } from "react";
import {
  Left,
  Right,
  QuestionText,
  QuestionTextContainer,
  PreviousButton,
  ButtonContainer,
  AnimatedContent,
  AnimatedPairingImageContent,
  PairingMethodContainer,
  Content,
  AnimatedPairingContent,
  StepContainer,
} from "./styles";
import { PrimaryButton } from "src/helpers/whiteLabel";
import { Button } from "@screencloud/screencloud-ui-components";
import { HardwareDeviceStatus } from "src/components/FirstScreenPairingModal";

import { StepProgress } from "../../StepProgress";
import { HardwareQuestionId, Methods } from "../types";
import { questions, stepsByMethod } from "../constants";
import { determinePairingMethod } from "../utils";

interface StepsProps {
  onClose: () => void;
}

export const Steps = ({ onClose }: StepsProps) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [isInPairingMode, setIsInPairingMode] = useState(false);
  const [answers, setAnswers] = useState<HardwareDeviceStatus>({
    hasCorrectHardware: false,
    hasScreenNearby: false,
    isSmartTV: false,
  });

  const totalQuestions = questions.length;
  const currentQuestion = questions[currentStepIndex];
  const pairingSteps =
    stepsByMethod[determinePairingMethod(answers) as keyof Methods];
  const currentPairingStep = isInPairingMode
    ? pairingSteps[currentStepIndex]
    : null;

  const getShowingQuestionState = (
    index: number,
  ): "hidden" | "visible" | "blur" | "fly-away" => {
    const stateMap = {
      0: { 0: "visible", 1: "hidden", 2: "hidden" },
      1: { 0: "blur", 1: "visible", 2: "hidden" },
      2: { 0: "fly-away", 1: "blur", 2: "visible" },
    };
    return stateMap[currentStepIndex]?.[index] ?? "hidden";
  };

  const handleNext = useCallback(
    (answer?: boolean) => {
      if (isInPairingMode) {
        // Handle next pairing step
        if (currentStepIndex < pairingSteps.length - 1) {
          setCurrentStepIndex((prevIndex) => prevIndex + 1);
        } else {
          onClose();
        }
      } else {
        // Handle next question
        if (answer !== undefined) {
          const newAnswers = { ...answers };
          switch (currentQuestion.id) {
            case HardwareQuestionId.HARDWARE:
              newAnswers.hasCorrectHardware = answer;
              break;
            case HardwareQuestionId.SCREEN:
              newAnswers.hasScreenNearby = answer;
              break;
            case HardwareQuestionId.SMART_TV:
              newAnswers.isSmartTV = answer;
              break;
          }
          setAnswers(newAnswers);

          // Determine next step based on the flowchart
          if (currentQuestion.id === HardwareQuestionId.HARDWARE) {
            setCurrentStepIndex((prevIndex) => prevIndex + 1);
          } else if (currentQuestion.id === HardwareQuestionId.SCREEN) {
            if (answer) {
              if (newAnswers.hasCorrectHardware) {
                setIsInPairingMode(true);
                setCurrentStepIndex(0); // Start Hardware & Screen Pairing steps
              } else {
                setCurrentStepIndex((prevIndex) => prevIndex + 1); // Go to Smart TV question
              }
            } else {
              setIsInPairingMode(true);
              setCurrentStepIndex(0); // Start Mobile Pairing
            }
          } else if (currentQuestion.id === HardwareQuestionId.SMART_TV) {
            setIsInPairingMode(true);
            setCurrentStepIndex(0); // Start either Smart TV Pairing or Mobile Pairing
          }
        }
      }
    },
    [
      isInPairingMode,
      currentStepIndex,
      pairingSteps.length,
      onClose,
      currentQuestion.id,
      answers,
    ],
  );

  const handlePrevious = useCallback(() => {
    if (isInPairingMode) {
      // Handle previous pairing step
      if (currentStepIndex > 0) {
        setCurrentStepIndex((prevIndex) => prevIndex - 1);
      } else {
        setIsInPairingMode(false);
        // Determine which question to return to based on the answers
        if (!answers.hasCorrectHardware && answers.hasScreenNearby) {
          setCurrentStepIndex(2); // Go back to Smart TV question
        } else if (answers.hasCorrectHardware || !answers.hasScreenNearby) {
          setCurrentStepIndex(1); // Go back to Screen question
        } else {
          setCurrentStepIndex(0); // Go back to Hardware question
        }
      }
    } else {
      // Handle previous question
      if (currentStepIndex === 0) {
        onClose();
      } else {
        setCurrentStepIndex((prevIndex) => prevIndex - 1);
      }
    }
  }, [isInPairingMode, currentStepIndex, totalQuestions, onClose]);

  return (
    <StepContainer>
      <Left isInPairingMode={isInPairingMode}>
        <PairingMethodContainer isInPairingMode={isInPairingMode}>
          {pairingSteps.length > 1 && (
            <StepProgress
              currentStep={currentStepIndex + 1}
              totalSteps={pairingSteps.map(
                (step) => step.progressStepTitle || "",
              )}
            />
          )}
          {pairingSteps.map((step, index) => {
            return (
              <AnimatedPairingContent
                key={index}
                visible={index === currentPairingStep?.index}
              >
                <Content>
                  <h1>{step.title}</h1>
                  <p>{step.description}</p>
                  {step.extraElement ?? null}
                </Content>
                <PrimaryButton
                  data-testid={`button-${(step.progressStepTitle ?? "pairing")
                    .toLowerCase()
                    .split(" ")
                    .join("-")}-next`}
                  onClick={handleNext}
                >
                  {step.buttonText}
                </PrimaryButton>
                <PreviousButton
                  borderless
                  data-testid={`button-${(step.progressStepTitle ?? "pairing")
                    .toLowerCase()
                    .split(" ")
                    .join("-")}-back`}
                  onClick={handlePrevious}
                >
                  Go Back
                </PreviousButton>
              </AnimatedPairingContent>
            );
          })}
        </PairingMethodContainer>
        <QuestionTextContainer isInPairingMode={isInPairingMode}>
          {questions.map(({ id, question }, index) => (
            <QuestionText
              key={id}
              currentId={id}
              showingState={getShowingQuestionState(index)}
            >
              {question}
            </QuestionText>
          ))}
        </QuestionTextContainer>
        <ButtonContainer isInPairingMode={isInPairingMode}>
          <Button
            data-testid={`button-${currentQuestion.id}-no`}
            onClick={() => handleNext(false)}
          >
            {currentQuestion.noText}
          </Button>
          <PrimaryButton
            data-testid={`button-${currentQuestion.id}-yes`}
            onClick={() => handleNext(true)}
          >
            {currentQuestion.yesText}
          </PrimaryButton>
        </ButtonContainer>
        <PreviousButton
          borderless
          data-testid={`button-${currentQuestion.id}-back`}
          onClick={handlePrevious}
          style={{
            display: isInPairingMode ? "none" : "flex",
          }}
        >
          {currentQuestion.backText}
        </PreviousButton>
      </Left>
      <Right isInPairingMode={isInPairingMode}>
        <div className="bg-1" />
        <div className="bg-2" />
        {questions.map(({ id, rightComponent }) => (
          <AnimatedContent
            isInPairingMode={isInPairingMode}
            key={id}
            isShowing={currentQuestion.id === id}
            isTVDisplay={
              id === HardwareQuestionId.SCREEN ||
              id === HardwareQuestionId.SMART_TV
            }
          >
            {rightComponent}
          </AnimatedContent>
        ))}

        {pairingSteps.map((step, index) => {
          return (
            <AnimatedPairingImageContent
              key={index}
              visible={index === currentPairingStep?.index && isInPairingMode}
              visibleCssProperty={step.visibleCssProperty}
              beforeVisibleCssProperty={step.beforeVisibleCssProperty}
              afterVisibleCssProperty={step.afterVisibleCssProperty}
            >
              {step.leftElement}
            </AnimatedPairingImageContent>
          );
        })}
      </Right>
    </StepContainer>
  );
};
