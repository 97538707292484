import { Button, Loader } from "@screencloud/screencloud-ui-components";
import { FormattedMessage } from "react-intl";
import { PrimaryButton } from "src/helpers/whiteLabel";
import { ScreenPickerProps } from "../index";

export interface ReplaceDeviceModalFooterProps {
  selectedScreens: ScreenPickerProps["selectedScreens"];
  footerHelpText?: React.ReactNode;
  loading?: boolean;
  onConfirm?: () => void;
  onBack: () => void;
}

export function ReplaceDeviceModalFooter(props: ReplaceDeviceModalFooterProps) {
  const isSelectedItemsEmpty = () => {
    return props.selectedScreens.length < 1;
  };

  return (
    <div className="md-footer result-options h-100 center">
      <div className="help-text">
        {props.footerHelpText && <span>{props.footerHelpText}</span>}
      </div>

      <div className="actions">
        {props.onBack && (
          <Button
            basic
            loading={props.loading}
            size="big"
            onClick={props.onBack}
          >
            <FormattedMessage
              id="ui_component.common.label.back"
              defaultMessage="Back"
            />
          </Button>
        )}

        <PrimaryButton
          loading={props.loading}
          size="big"
          onClick={props.onConfirm}
          disabled={isSelectedItemsEmpty()}
        >
          {props.loading ? (
            <Loader inline active={props.loading} size="small" />
          ) : (
            <>
              <FormattedMessage
                id="ui_component.common.label.confirm"
                defaultMessage="Confirm"
              />
            </>
          )}
        </PrimaryButton>
      </div>
    </div>
  );
}
