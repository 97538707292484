import { useAppContext } from "src/hooks/useAppContext";
import ScreenPairing from "../ScreenPairing";
import { useSubscription } from "src/billinglatest/hooks/useSubscription";

interface ReplaceDeviceProps {
  onReplaceDeviceCallback: (pairingCode: string) => Promise<any>;
}

/**
 * Component for replacing a device.
 *
 * @param {ReplaceDeviceProps} props - Props containing the callback for device replacement.
 * @returns {JSX.Element} JSX representation of the ReplaceDevice component.
 */
export const ReplaceDevice = (props: ReplaceDeviceProps): JSX.Element => {
  const subscription = useSubscription({ cache: true });
  const context = useAppContext();
  const isReplaceDevice = true;

  const handlePairing: (
    code: string,
    callback: (success: boolean, errorMessage?: string) => void,
  ) => void = (code) => {
    props.onReplaceDeviceCallback(code).then(() => {
      // Close all modals
      context.modal.closeModals();
    });
  };

  return (
    <ScreenPairing
      onPairing={handlePairing}
      subscription={subscription}
      isReplaceDevice={isReplaceDevice}
    />
  );
};
