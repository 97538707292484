import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";
export const ModalStyled = styled.div`
  height: 100%;
  display: flex;
  flex-wrap: wrap;

  .md-footer.custom-align {
    width: 100%;
    gap: 10px;
    display: flex;
    position: relative;

    .button.primary {
      width: 200px;
    }

    .button-replace-device {
      position: absolute;
      right: 48px;
    }
  }

  @media screen and (max-width: ${Theme.break.small}) {
    .md-footer.custom-align {
      width: 100%;
      gap: 10px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .button.primary {
        width: 50px;
      }

      .button-replace-device {
        position: relative;
        margin-left: auto;
        max-width: 100px;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: auto;
        direction: rtl;
      }
    }
  }
`;
export const Styled = styled.div`
  text-align: center;
  background: ${Theme.color.nearWhite};
  display: inline-flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100% - 65px);
  padding: 0px 80px;
  border-radius: 0 0 ${Theme.default.borderRadiusValue}
    ${Theme.default.borderRadiusValue};
  overflow: auto;
  position: relative;

  .custom-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  h3 {
    color: ${Theme.color.white};
    font-size: 1.25em;
    font-weight: normal;

    i {
      margin-left: 5px;
      background-color: ${Theme.color.white};
    }
  }
  .screen {
    border: 8px solid ${Theme.color.black};
    border-radius: 2px;
    background: ${Theme.color.base};
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    position: relative;
    box-shadow: 0 90px 40px -80px rgba(0, 0, 0, 0.5);
    padding-bottom: 56.25%;
    width: 100%;
    margin: 0;

    .screen-wrapper {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      padding-top: 70px;

      .logo {
        position: absolute;
        top: 0;

        .logo-wrapper {
          justify-content: flex-start;
          padding: 30px;
          background: transparent;

          .wordmark {
            display: none;
          }
        }
      }
    }
    .input-wrapper {
      position: relative;
      .ui.input {
        display: block;
        input {
          background-color: transparent;
          text-align: center;
          color: var(--color-white-100);
          padding: 0;
          border: none;
          font-size: 6em;
          height: auto;
          line-height: 115px;
          border-radius: 0;
          border-bottom: 2px solid var(--color-primary-background);
          width: 605px;
          text-transform: lowercase;
          font-weight: 700;
          text-transform: uppercase;
          :focus,
          :hover,
          :active {
            border-bottom: 2px solid var(--color-primary-background);
            background-color: transparent;
          }
        }
      }
      .guide {
        font-size: 1em;
        color: ${Theme.color.grey};
        display: inline-flex;
        height: 45px;
        align-items: center;
        opacity: 0;
        .btn {
          border: 1px solid ${Theme.color.darkGrey};
          padding: 2px 4px;
          ${Theme.default.borderRadius};
          text-transform: uppercase;
          font-size: 0.8em;
          font-weight: bold;
          margin: 0 2px;
        }
      }
      .ui.input:focus-within + .guide {
        opacity: 1;
      }
      .warning {
        font-size: 1em;
        position: absolute;
        bottom: -40px;
        width: 100%;
        padding: 10px;
        left: 0;
        color: ${Theme.color.grey};
        > span:first-child {
          color: ${Theme.color.error};
        }
        a {
          color: ${Theme.color.lightGrey};
          :hover {
            color: ${Theme.color.white};
          }
        }
      }
    }
    .load {
      position: absolute;
      bottom: -40px;
      width: 100%;
      padding: 10px;
      color: ${Theme.color.white};
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
    }
  }
  > .button {
    gap: 5px;
    margin: 0 0 16px 0;
    color: var(--grey-100, #19191b);
    i {
      background-color: var(--grey-100, #19191b);
    }
  }
  .screen-license,
  .replace-device-info-text {
    color: ${Theme.color.silver};
    margin-top: 20px;
    font-size: 0.875em;
    line-height: 1.357;
    color: ${Theme.color.grey};

    > span {
      display: block;
      margin-bottom: 5px;
    }

    strong {
      color: ${Theme.color.lightGrey};
    }
  }

  .trial-mode {
    align-items: center;
    display: inline-flex;
    margin-top: 40px;
    position: relative;

    .ui.checkbox {
      margin: 0;
      height: 40px;
      padding: 0 15px;
      display: flex;
      align-items: center;
      ${Theme.default.borderRadius};

      input:focus ~ label {
        color: ${Theme.color.silver};
      }
      label {
        color: ${Theme.color.silver};
      }
      &.checked {
        background: ${Theme.color.darkGrey};

        input:checked ~ .box:before,
        input:checked ~ label:before {
          background: ${Theme.color.white};
        }
        input:checked ~ .box:after,
        input:checked ~ label:after {
          background: ${Theme.color.base};
          box-shadow:
            2px 0 0 ${Theme.color.base},
            4px 0 0 ${Theme.color.base},
            4px -2px 0 ${Theme.color.base},
            4px -4px 0 ${Theme.color.base},
            4px -6px 0 ${Theme.color.base},
            4px -8px 0 ${Theme.color.base};
          top: 10px;
        }
        label {
          color: ${Theme.color.white};
        }
      }

      :hover {
        background: ${Theme.color.black};

        &.checked {
          background: ${Theme.color.darkGrey};
        }
      }
    }
  }
  @media screen and (max-width: ${Theme.break.mediumAlt}) {
    padding: 0px 40px;
    .screen {
      .input-wrapper {
        .ui.input {
          input {
            width: 510px;
            font-size: 5em;
            line-height: 96px;
          }
        }
        .guide,
        .loader {
          display: none;
        }
      }
    }

    .screen-license {
      width: 510px;
    }

    > .button {
      display: inline-flex;
    }
  }
  @media screen and (max-width: ${Theme.break.small}) {
    padding: 0;
    border-radius: 0;

    .screen {
      border: none;
      height: 100%;
      padding: 0;
      border-radius: 0;

      h3 {
        padding-top: 15px;
      }

      .screen-wrapper {
        justify-content: center;

        .trial-mode {
          .ui.checkbox {
            background: ${Theme.color.black};
          }
        }
      }

      .input-wrapper {
        .ui.input {
          input {
            width: 430px;
            font-size: 4.2em;
            line-height: 81px;
          }
        }
      }
    }

    .screen-license {
      margin-top: 10px;
    }
  }
  @media screen and (max-width: ${Theme.break.tiny}) {
    .screen {
      .screen-wrapper {
        > div {
          width: calc(100% - 20px);
        }

        .trial-mode {
          justify-content: center;
          margin-top: 60px;
        }
      }

      .logo {
        display: none;
      }

      h3 {
        font-size: 1em;
        padding-top: 40px;
      }

      .input-wrapper {
        .ui.input {
          justify-content: center;
          input {
            width: 280px;
            font-size: 3.5em;
            line-height: 51px;
          }
        }
        .warning {
          bottom: -54px;
          font-size: 0.875em;
          line-height: 1.357;
        }
      }
    }

    .screen-license {
      font-size: 0.75em;
      line-height: 1.333;
    }
  }
`;
