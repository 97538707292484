import Andriod from "../images/hardware-logo-andriod.svg";
import FireOS from "../images/hardware-logo-fireos.svg";
import MacOs from "../images/hardware-logo-macos.svg";
import ScreenCloudOS from "../images/hardware-logo-scos.svg";
import Sony from "../images/hardware-logo-sony-bravia.svg";
import Windows from "../images/hardware-logo-windows.svg";
import WebOS from "../images/hardware-logo-webos.svg";
import Tizen from "../images/hardware-logo-tizen.svg";
import BrightSign from "../images/hardware-logo-brightsign.svg";
import ChromeOS from "../images/hardware-logo-chrome.svg";
import { CardContainer, IconGroup } from "./styles";

const icons = [
  {
    name: "Andriod",
    icon: Andriod,
    width: "88px",
    borderRadius: "16px 0 0 0",
  },
  {
    name: "FireOS",
    icon: FireOS,
    width: "88px",
    borderRadius: "0 16px 0 0",
  },
  {
    name: "ChromeOS",
    icon: ChromeOS,
    width: "120px",
  },
  {
    name: "Windows",
    icon: Windows,
    width: "60px",
  },
  {
    name: "Tizen",
    icon: Tizen,
    width: "120px",
  },
  {
    name: "BrightSign",
    icon: BrightSign,
    width: "120px",
  },
  {
    name: "WebOS",
    icon: WebOS,
    width: "120px",
  },
  {
    name: "MacOs",
    icon: MacOs,
    width: "72px",
  },
  {
    name: "Sony",
    icon: Sony,
    width: "120px",
    borderRadius: "0 0 0 16px",
  },
  {
    name: "ScreenCloudOS",
    icon: ScreenCloudOS,
    width: "120px",
    borderRadius: "0 0 16px 0",
  },
];

const Card = ({
  name,
  icon,
  width,
  borderRadius,
}: {
  name: string;
  icon: string;
  width: string;
  borderRadius: string;
}) => {
  return (
    <CardContainer borderRadius={borderRadius}>
      <img src={icon} alt={name} width={width} />
    </CardContainer>
  );
};

export const Hardwares = () => {
  return (
    <IconGroup>
      {icons.map((icon) => (
        <Card
          width={icon.width}
          key={icon.name}
          name={icon.name}
          icon={icon.icon}
          borderRadius={icon.borderRadius ?? ""}
        />
      ))}
    </IconGroup>
  );
};
