import { Icon } from "@screencloud/screencloud-ui-components";
import { memo } from "react";
import { appInstanceIconUrl } from "src/helpers/appHelper";
import { AppPickerItem } from "src/components/CanvasPicker/styles";
import { AvailableAppInstanceFragment } from "src/types.g";
import { MediaTitle } from "src/components/MediaTitle";

interface CanvasInstanceItemProps {
  app: AvailableAppInstanceFragment;
  selected: boolean;
  onToggleSelection: (app: AvailableAppInstanceFragment) => void;
  onClickTag: (tag: string) => void;
}

const ThumbnailPreview = memo<{ iconUrl: string; name: string }>(
  ({ iconUrl, name }) => (
    <div className="thumbnail-preview">
      <div className="thumbnail">
        <div className="wrapper">
          <img src={iconUrl} alt={name} />
        </div>
      </div>
    </div>
  ),
);

const CanvasInstanceItem = memo<CanvasInstanceItemProps>(
  ({ app, selected, onToggleSelection, onClickTag }) => {
    const iconUrl = appInstanceIconUrl(app);
    if (!iconUrl) return null;
    return (
      <AppPickerItem
        className="media-item"
        onClick={() => onToggleSelection(app)}
        selected={selected}
      >
        <div className="media-core">
          <div className="media-alpha">
            <ThumbnailPreview iconUrl={iconUrl} name={app.name!} />
            <MediaTitle app={app} onClickTag={onClickTag} />
          </div>
        </div>
        <div className="media-checked">
          <Icon name="checked-circle" />
        </div>
      </AppPickerItem>
    );
  },
);

interface CanvasesProps {
  appInstances: CanvasInstanceItemProps["app"][];
  selectedIds: string[];
  onToggleSelection: (app: CanvasInstanceItemProps["app"]) => void;
  renderFetchMoreButton: React.ReactNode;
  onClickTag: (tag: string) => void;
}

export const CanvaseInstances = ({
  appInstances,
  selectedIds,
  onToggleSelection,
  renderFetchMoreButton,
  onClickTag,
}: CanvasesProps) => {
  return (
    <>
      {appInstances?.map((app) => (
        <CanvasInstanceItem
          key={app.id}
          app={app}
          selected={selectedIds.includes(app.id)}
          onToggleSelection={onToggleSelection}
          onClickTag={onClickTag}
        />
      ))}
      {renderFetchMoreButton}
    </>
  );
};
