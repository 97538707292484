import styled, { css } from "styled-components";
import { Theme } from "@screencloud/screencloud-ui-components";

export const Styled = styled.div<{ isMediaPicker?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  gap: 4px var(--spacing-xxs, 4px);
  width: auto;

  .tag {
    font-weight: bold;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 4px 8px;
    border: 1px solid transparent;
    border-radius: 15px;
    ${({ isMediaPicker }) =>
      isMediaPicker &&
      css`
        cursor: pointer;
        background-color: #fff;
        color: #000 !important;
        border-radius: 15px;
        border: 1px solid var(--color-gray-100, "#E2E3E5");
        padding: 4px 8px;
        white-space: nowrap;
        font-size: var(--font-size-xs, 12px) !important;
        font-style: normal !important;
        :hover {
          background-color: #f0f0f0 !important;
        }
      `}

    i {
      width: 20px;
      height: 20px;
      background-color: ${Theme.color.grey100};
    }

    .tag-label {
      padding: 0px 5px;
      line-height: 1.429;
      font-weight: bold;
      font-size: 0.875em;
      ${({ isMediaPicker }) =>
        isMediaPicker &&
        css`
          font-weight: 500 !important;
        `}
    }

    ${({ isMediaPicker }) =>
      isMediaPicker &&
      css`
        border-color: ${Theme.color.grey40};
        background-color: ${Theme.color.white100};
      `}

    :hover {
      border-color: ${Theme.color.grey40};
      background-color: ${Theme.color.white100};
    }

    &.selected {
      background: ${Theme.color.grey100};
      border-color: transparent;

      i {
        background-color: ${Theme.color.white100};
      }

      .tag-label {
        color: ${Theme.color.white100};
      }

      :hover {
        border-color: transparent;
      }
    }
  }

  .collapse-tags {
    padding: 0px var(--spacing-sm, 8px);
  }

  .clear-selection {
    padding: 4px 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    ${Theme.default.borderRadius};

    i {
      width: 20px;
      height: 20px;
      background-color: ${Theme.color.red};
      margin-right: 4px;
    }

    span {
      font-size: 0.875em;
      color: ${Theme.color.red};
      font-weight: bold;
    }

    :hover {
      background: ${Theme.color.red};

      i {
        background-color: ${Theme.color.white100};
      }

      span {
        color: ${Theme.color.white100};
      }
    }
  }
`;
