import React from "react";
import {
  StepProgressContainer,
  Step,
  StepNumberStyled,
  StepTitle,
  Connector,
} from "./styles";

interface StepProgressProps {
  currentStep: number;
  totalSteps: string[];
}

interface StepNumberProps {
  index: number;
  currentStep?: number;
  isBullet?: boolean;
}

export const StepNumber: React.FC<StepNumberProps> = ({
  index,
  currentStep,
  isBullet,
}) => {
  const stepNumber = index + 1;
  const isActive = stepNumber <= (currentStep ?? 0);
  const isComplete = (currentStep ?? 0) > stepNumber;

  return (
    <StepNumberStyled
      active={isActive}
      isComplete={isComplete}
      isBullet={isBullet}
    >
      {stepNumber}
    </StepNumberStyled>
  );
};

export const StepProgress: React.FC<StepProgressProps> = ({
  currentStep,
  totalSteps,
}) => {
  return (
    <StepProgressContainer className="step-progress">
      {totalSteps.map((step, index) => (
        <React.Fragment key={index}>
          <Step>
            <StepNumber index={index} currentStep={currentStep} />
            <StepTitle active={index + 1 <= currentStep}>{step}</StepTitle>
          </Step>
          {index < totalSteps.length - 1 && <Connector />}
        </React.Fragment>
      ))}
    </StepProgressContainer>
  );
};
