import { FC } from "react";
import { FirstScreenPairingModalContainer } from "./styles";
import { Steps } from "./Steps";

export interface HardwareDeviceStatus {
  hasCorrectHardware: boolean;
  hasScreenNearby: boolean;
  isSmartTV: boolean;
}

export const FirstScreenPairingModal: FC<{
  onClose: () => void;
}> = ({ onClose }) => {
  return (
    <FirstScreenPairingModalContainer>
      <Steps onClose={onClose} />
    </FirstScreenPairingModalContainer>
  );
};
