import { isTrialist } from "src/pages/Welcome/utils";
import { useAppContext } from "./useAppContext";
import { IntercomEvent, trackIntercomEvent } from "src/utils/intercom";
import { PIXI_TRIALIST_PREFERENCES_KEYS } from "src/pages/Welcome/constants";

export const useIntercom = () => {
  const context = useAppContext();

  const trackIntercomWithFirstSetScreenContent = () => {
    const isSetFirstContentToScreen =
      context.currentOrg?.preferences.pixi_trialist[
        PIXI_TRIALIST_PREFERENCES_KEYS.COMPLETED_SET_CONTENT
      ] === false;

    if (isTrialist(context) && isSetFirstContentToScreen) {
      trackIntercomEvent(IntercomEvent.SET_FIRST_CONTENT_TO_SCREEN_COMPLETE);
    }
  };
  return { trackIntercomWithFirstSetScreenContent };
};
