import { Button } from "@screencloud/screencloud-ui-components";
import { useGetScreenSize } from "src/hooks/useGetScreenSize";
import { TagList } from "src/components/TagList";
import { Styled } from "./styles";

type MediaPickerSelectedTagListProps = {
  selectedTags: string[];
  onClearTags: () => void;
  onClickTag: (tag: string) => void;
};

export const MediaPickerSelectedTagList = ({
  selectedTags,
  onClearTags,
  onClickTag,
}: MediaPickerSelectedTagListProps): JSX.Element => {
  const { isTabletView } = useGetScreenSize();

  return (
    <Styled data-testid="media-tags" className="media-tags">
      {isTabletView ? (
        <div className="tag-list-mobile">
          <span>{selectedTags.length} filters applied</span>
          <Button
            onClick={onClearTags}
            className="clear-all-tags-button-mobile"
            data-testid="clear-all-tags-button-mobile"
          >
            Clear
          </Button>
        </div>
      ) : (
        <TagList
          tags={selectedTags}
          onClickTag={onClickTag}
          isTagIdSelected={() => false}
          isMediaPicker={true}
        />
      )}
    </Styled>
  );
};
