import {
  Screen,
  useScreenshotsByScreenIdQuery,
  useTakeScreenshotByScreenIdMutation,
} from "src/types.g";
import {
  CenterLoaderStyled,
  ScreenshotModalViewStyled,
  TakingScreenshotLoadingStyled,
} from "./styles";
import { PrimaryButton } from "src/helpers/whiteLabel";
import { FormattedMessage } from "react-intl";
import {
  Button,
  Icon,
  Loader,
  SCREEN_STATUS_ENUM,
} from "@screencloud/screencloud-ui-components";
import spacetime from "spacetime";
import { useCallback, useEffect, useMemo, useState } from "react";
import ProcessingFailureThumbnail from "../../../images/processing_failure_thumb.svg";

export const isAllowClearCacheByDevicePlatform = (
  screen: Pick<Screen, "devicePlatform" | "deviceInfo"> | null | undefined,
): boolean => {
  return (
    screen?.devicePlatform?.toLowerCase() === "android" ||
    screen?.devicePlatform?.toLowerCase() === "amazon" ||
    (screen?.deviceInfo?.native_player?.capabilities?.clear_cache &&
      screen?.deviceInfo?.native_player?.screen_api_command?.clear_cache)
  );
};

const handleDownload = (url, timestamp) => {
  const link = document.createElement("a");
  link.href = url;
  link.target = "_blank";
  link.download = `screenshot-${timestamp}.jpg`;
  link.click();
};

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

// This is workaround for this version. In the future we can improve by doing firebase realtime status update instead
const pollForImage = async (url: string): Promise<boolean> => {
  // In total of 15 * 2s = 30s
  const maxAttempts = 15;
  const intervalMs = 2000;

  const loadImage = (src: string): Promise<boolean> => {
    return new Promise((resolve) => {
      const image = new Image();
      image.onload = function () {
        resolve(true);
      };
      image.onerror = function () {
        resolve(false);
      };
      image.src = src;
    });
  };

  for (let attempt = 1; attempt <= maxAttempts; attempt++) {
    try {
      const isAvailable = await loadImage(url);
      if (isAvailable) {
        console.log("Image is available! Render it.");
        return true;
      }
      if (attempt === maxAttempts) {
        console.log(
          "Image not available after maximum attempts. Stop polling.",
        );
        return false;
      }
      await sleep(intervalMs);
      console.log(`Attempt ${attempt}: Image not available. Polling again...`);
    } catch (error) {
      console.log("Error while polling:", error);
    }
  }

  return false;
};

const TakingScreenshotLoading = () => {
  const [showAttention, setShowAttention] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setShowAttention(true);
    }, 10 * 1000);
  }, []);

  return (
    <TakingScreenshotLoadingStyled>
      <CenterLoaderStyled>
        <div className="center">
          <Loader active />
        </div>
      </CenterLoaderStyled>
      <p className="header">Taking screenshot...</p>
      <p className={`description ${showAttention && "show"}`}>
        This shouldn’t take longer than a minute. Please take a new screenshot
        or reach out to our support team for further assistance.
      </p>
    </TakingScreenshotLoadingStyled>
  );
};

interface ScreenshotModalViewProps {
  screenId: string;
  screenStatus: SCREEN_STATUS_ENUM;
}
const ScreenshotModal = (props: ScreenshotModalViewProps) => {
  const { screenStatus } = props;
  const {
    data,
    loading,
    error: screenshotsByScreenIdError,
    refetch,
  } = useScreenshotsByScreenIdQuery({
    variables: {
      input: {
        screenId: props.screenId,
      },
    },
  });

  const isScreenOnline = screenStatus === SCREEN_STATUS_ENUM.LIVE;

  const [takeScreenshotByScreenId, { loading: isTakingScreenshot }] =
    useTakeScreenshotByScreenIdMutation();
  const [isPollingScreenshot, setIsPollingScreenshot] =
    useState<boolean>(false);

  const [isSelectedScreenshotImageError, setIsSelectedScreenshotImageError] =
    useState<boolean>(false);
  const [
    isSelectedScreenshotImageLoading,
    setIsSelectedScreenshotImageLoading,
  ] = useState<boolean>(true);
  const [selectedScreenshotId, setSelectedScreenshotId] = useState<string>("");

  const isNoScreenshotYet = useMemo(
    () => data?.screenshotsByScreenId?.screenshots?.length === 0,
    [data?.screenshotsByScreenId?.screenshots?.length],
  );

  const selectedScreenshot = useMemo(
    () =>
      data?.screenshotsByScreenId?.screenshots?.find(
        (screenshot) => screenshot?.id === selectedScreenshotId,
      ),
    [data?.screenshotsByScreenId?.screenshots, selectedScreenshotId],
  );

  const selectScreenshotId = useCallback((screenshotId: string) => {
    setSelectedScreenshotId(screenshotId);
    setIsSelectedScreenshotImageLoading(true);
    setIsSelectedScreenshotImageError(false);
  }, []);

  const takeScreenshot = useCallback(async () => {
    const { data, errors } = await takeScreenshotByScreenId({
      variables: {
        input: {
          screenId: props.screenId,
        },
      },
    });

    if (errors) {
      console.log("Error from taking screenshot:", errors);
      return;
    }

    setIsPollingScreenshot(true);
    await pollForImage(data?.takeScreenshotByScreenId?.screenshot?.url ?? "");
    setIsPollingScreenshot(false);

    selectScreenshotId("");
    refetch();
  }, []);

  if (screenshotsByScreenIdError) {
    console.warn("Screenshots error:", screenshotsByScreenIdError);
  }

  useEffect(() => {
    if (!isScreenOnline) {
      return;
    }

    if (isNoScreenshotYet) {
      takeScreenshot();
      return;
    }

    const latestScreenshot = data?.screenshotsByScreenId?.screenshots?.[0];
    if (!latestScreenshot) {
      return;
    }

    selectScreenshotId(latestScreenshot.id ?? "");
  }, [data?.screenshotsByScreenId, isNoScreenshotYet]);

  if (isScreenOnline && (loading || !data)) {
    return (
      <CenterLoaderStyled>
        <Loader active />
      </CenterLoaderStyled>
    );
  }

  return (
    <div className="screenshot-viewer-container">
      <div className={`screenshot-viewer ${isNoScreenshotYet && "empty"}`}>
        {isScreenOnline &&
        (isTakingScreenshot || isPollingScreenshot || !selectedScreenshot) ? (
          <TakingScreenshotLoading />
        ) : (
          <div className="screenshot-preview-container">
            {selectedScreenshot ? (
              <>
                <img
                  loading="lazy"
                  src={selectedScreenshot?.url ?? ""}
                  onLoad={() => setIsSelectedScreenshotImageLoading(false)}
                  onError={(e) => {
                    e.currentTarget.src = ProcessingFailureThumbnail;
                    setIsSelectedScreenshotImageError(true);
                  }}
                />
                <div className="info-container">
                  <div className="info">
                    <div>
                      {spacetime(
                        parseInt(selectedScreenshot?.created_at ?? ""),
                      ).unixFmt("MMM dd, yyyy, h:mm a")}
                    </div>
                    <div className="taken-by">
                      Taken by {selectedScreenshot?.given_name}{" "}
                      {selectedScreenshot?.family_name}
                    </div>
                  </div>
                  <Button
                    data-testid="screenshot-download-btn"
                    disabled={
                      isSelectedScreenshotImageError ||
                      isSelectedScreenshotImageLoading
                    }
                    onClick={() =>
                      handleDownload(
                        selectedScreenshot?.url,
                        selectedScreenshot?.created_at,
                      )
                    }
                  >
                    <Icon
                      data-testid="screenshot-preview-download-icon"
                      name="download"
                    />
                    <FormattedMessage
                      id="ui_component.common.label.download"
                      defaultMessage="Download"
                    />
                  </Button>
                </div>
              </>
            ) : (
              <div className="info-container">
                <FormattedMessage
                  id="ui_component.common.label.no_screenshot_yet"
                  defaultMessage="No screenshot yet"
                />
              </div>
            )}
          </div>
        )}
      </div>
      <div className="screenshot-list">
        <div className="header item-padding">
          <FormattedMessage
            id="ui_component.common.label.all_screenshots"
            defaultMessage="ALL SCREENSHOTS"
          />
        </div>
        <div className="y-scrollable">
          {data?.screenshotsByScreenId?.screenshots?.map((screenshot) => (
            <div
              className={`screenshot-item item-padding ${
                screenshot?.id === selectedScreenshotId && "active"
              }`}
              key={screenshot?.id}
              onClick={() => selectScreenshotId(screenshot?.id ?? "")}
            >
              <img
                loading="lazy"
                src={screenshot?.url ?? ""}
                onError={(e) => {
                  const target = e.currentTarget;
                  target.src = ProcessingFailureThumbnail;
                  const screenshotItemDownloadButton =
                    target.parentElement?.querySelector<HTMLButtonElement>(
                      "[data-testid=screenshot-list-item-download]",
                    );
                  if (!screenshotItemDownloadButton) {
                    return;
                  }
                  screenshotItemDownloadButton.style.display = "none";
                }}
              />
              <div className="screenshot-info">
                <div>
                  {spacetime(parseInt(screenshot?.created_at ?? "")).unixFmt(
                    "MMM dd, yyyy, h:mm a",
                  )}
                </div>
                <div>
                  Taken by {screenshot?.given_name} {screenshot?.family_name}
                </div>
              </div>
              <div className="center">
                <Icon
                  data-testid="screenshot-list-item-download"
                  name="download"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDownload(screenshot?.url, screenshot?.created_at);
                  }}
                />
              </div>
            </div>
          ))}
        </div>

        <div className="screenshot-action">
          <PrimaryButton
            disabled={
              isTakingScreenshot || isPollingScreenshot || !isScreenOnline
            }
            className="screenshot-btn"
            data-testid="screenshot-btn"
            onClick={takeScreenshot}
          >
            <Icon data-testid="screenshot-modal-screenshot" name="camera" />
            <FormattedMessage
              id="ui_component.common.label.new_screenshot"
              defaultMessage="New Screenshot"
            />
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export const ScreenshotModalView = (props: ScreenshotModalViewProps) => {
  return (
    <ScreenshotModalViewStyled>
      <ScreenshotModal {...props} />
    </ScreenshotModalViewStyled>
  );
};
