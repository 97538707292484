import { UUID } from "@screencloud/uuid";
import {
  getMediaPicker,
  getMediaPickerSkipProps,
} from "src/domains/mediaPicker/mediaPicker";
import { useAllAppInstances } from "src/hooks/useAllAppinstances";
import { AvailableAppInstanceFragment } from "src/types.g";
import { isOrgWidePath } from "src/utils/orgWideFeature";
import { useAllAppInstancesInCurrentOrg } from "./useAllAppInstancesInCurrentOrg";

export interface UseAppInstancePicker {
  appInstances: AvailableAppInstanceFragment[];
  loading: boolean;
  renderFetchMoreButton: React.ReactNode;
  totalCount: number;
  isFirstTimeAlreadyLoaded: boolean;
}

export function useMediaPickerAppInstances({
  appId,
  spaceId,
  tags,
}: {
  appId: string;
  spaceId?: UUID;
  tags?: string[];
}): UseAppInstancePicker {
  const isOrgWide = isOrgWidePath();
  const { skipOrgWideQuery, skipSpaceWideQuery } = getMediaPickerSkipProps({
    isOrgWide,
  });
  return getMediaPicker({
    isOrgWide,
    orgWideMediaPicker: useAllAppInstancesInCurrentOrg({
      skip: skipOrgWideQuery || !appId,
      appId,
      tags,
    }),
    spaceWideMediaPicker: useAllAppInstances({
      appId,
      spaceId,
      skip: skipSpaceWideQuery || !appId,
      tags,
    }),
  }) as unknown as UseAppInstancePicker; // TODO vite migration fix this later
}
