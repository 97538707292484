import { useCallback } from "react";
import { FormattedHTMLMessage } from "react-intl";
import {
  CardsContainer,
  NewTrialEmptyScreenSectionStyled,
  Title,
} from "src/components/EmptyState/NewTrialEmptyScreenSection/styles";
import {
  PairScreenCard,
  VirtualScreenCard,
} from "src/components/EmptyState/NewTrialEmptyScreenSection/components/ContentCard";
import { VideoCard } from "src/components/EmptyState/NewTrialEmptyScreenSection/components/VideoCard";
import { useVirtualScreen } from "src/hooks/useVirtualScreen";
import { useScreenPairing } from "src/domains/screens/hooks/useScreenPairing";

export const NewTrialEmptyScreenSection = () => {
  const { openScreenPairing } = useScreenPairing();
  const { launchScreen } = useVirtualScreen();

  const onClickVirtualScreenCard = useCallback(() => {
    openScreenPairing();
    launchScreen();
  }, [openScreenPairing, launchScreen]);

  return (
    <NewTrialEmptyScreenSectionStyled>
      <Title>
        <FormattedHTMLMessage
          id="new-trial-screen-empty-state.title"
          defaultMessage="<b>Okay</b>, let's get a screen up and running"
        />
      </Title>
      <CardsContainer>
        <PairScreenCard
          className="dark-card"
          onClick={() => openScreenPairing()}
        />
        <VirtualScreenCard
          className="purple-card"
          onClick={onClickVirtualScreenCard}
        />
      </CardsContainer>
      <VideoCard />
    </NewTrialEmptyScreenSectionStyled>
  );
};
