import {
  Button,
  Icon,
  Input,
  Modal,
  ModalSize,
} from "@screencloud/screencloud-ui-components";
import { Component } from "react";
import { ssm } from "../../../../../state/session/ssm";
import { ApolloProps, withData } from "./apollo";

import { PrimaryButton } from "../../../../../helpers/whiteLabel";
import {
  API_KEY_ICON,
  API_KEY_PROVIDER_URN,
  BASIC_AUTH_ICON,
  BASIC_AUTH_PROVIDER_URN,
  OAUTH2_ICON,
} from "../../../constants";
import { Credential } from "../../../models/Credential";
import { IdentityProvider } from "../../../models/IdentityProvider";

interface IState {
  newCredential?: Credential;
  newCredentialName: string;
  nameError?: string;
}

export interface CredentialModalProps extends ApolloProps {
  identityProvider: IdentityProvider;
  onCredentialAdded: (credential?: Credential) => void;

  addAndSelectCredential: Function;
  setCredentialModalClosed: Function;
}

class AddNewCredentialModal extends Component<CredentialModalProps, IState> {
  constructor(props: CredentialModalProps) {
    super(props);

    this.state = {
      newCredentialName: "",
    };
  }

  public componentDidMount() {
    window.addEventListener("message", this.onWindowMessage);
  }

  public componentWillUnmount() {
    window.removeEventListener("message", this.onWindowMessage);
  }

  public render() {
    const { newCredentialName, newCredential, nameError } = this.state;

    return (
      <Modal
        open={true}
        size={ModalSize.SMALL}
        title={"Create New Account"}
        onClose={() => {
          this.props.onCredentialAdded();
        }}
        className="add-credential-modal"
      >
        <div className="new-cred-modal-body-wrapper">
          <div className="new-cred-modal-body">
            <div className="new-cred-label">Name</div>
            <Input
              placeholder="New Account Name"
              onChange={this.handleCredNameInputChange}
              value={newCredentialName}
              error={!!nameError}
            />
            {nameError && <p className="error-message">{nameError}</p>}

            <div className="new-cred-label">Credentials</div>

            {!newCredential ? (
              <Button transparent onClick={this.handleAddCredentialClick}>
                <Icon name="plus-circle" />
                <h3>Add Credentials</h3>
              </Button>
            ) : (
              <div className="new-credential-container">
                <img
                  className="new-credential-icon"
                  src={newCredential.iconUrl || ""}
                />
                <div className="new-credential-label">
                  {newCredential.username}
                </div>
              </div>
            )}
          </div>
          <div className="model-footer">
            <PrimaryButton
              disabled={!newCredential || newCredentialName.length === 0}
              onClick={() => {
                this.props.onCredentialAdded(newCredential);
              }}
            >
              Add account
            </PrimaryButton>
          </div>
        </div>
      </Modal>
    );
  }

  private handleCredNameInputChange = (e) => {
    this.setState({
      newCredentialName: e.target.value,
    });
  };

  private handleAddCredentialClick = () => {
    const { newCredentialName } = this.state;

    if (newCredentialName.trim().length === 0) {
      this.setState({ nameError: "Please enter an account name" });
      return;
    }

    this.setState({ nameError: undefined });

    const authUrl = `${this.props.identityProviderLaunchUrl
      .identityProviderLaunchUrl!}&name=${this.state.newCredentialName}`;
    window.open(
      authUrl,
      undefined,
      this.getPopupWindowParams(screen.width, screen.height),
    );
  };

  private getPopupWindowParams = (
    screenWidth: number = 1280,
    screenHeight: number = 720,
  ) => {
    const width = 500;
    const height = 600;
    const left = (screenWidth - width) * 0.5;
    const top = (screenHeight - height) * 0.5;

    return [
      "modal=yes",
      "alwaysRaised=yes",
      "alwaysOnTop=yes",
      "scrollbars=yes",
      "resizable=yes",
      "status=yes",
      "location=yes",
      "menubar=no",
      `width=${width}`,
      `height=${height}`,
      `left=${left}`,
      `top=${top}`,
    ].join(",");
  };

  private onWindowMessage = async (event: MessageEvent) => {
    if (!event.data || !event.data.credential) {
      return;
    }

    const { credential } = event.data;

    const newCredential: Credential = {
      id: atob(credential.id).split("|")[1],
      description: credential.name,
      provider: credential.type,
      username: credential.metadata ? credential.metadata.username : "API Key",
      credentialKey: credential.id,
      iconUrl: this.getProviderIconUrl(credential.type),
      spaceId: ssm.current.settings.spaceId,
    };

    await this.props.createCredential({
      variables: {
        input: {
          provider: this.props.identityProvider.type,
          credentialKey: newCredential.credentialKey,
          description: newCredential.description,
          username: newCredential.username,
          spaceId: ssm.current.settings.spaceId,
        },
      },
    });

    this.setState({ newCredential });
  };

  private getProviderIconUrl = (urn: string) => {
    if (urn.startsWith(API_KEY_PROVIDER_URN)) {
      return `${window.location.origin}${API_KEY_ICON}`;
    }

    if (urn.startsWith(BASIC_AUTH_PROVIDER_URN)) {
      return `${window.location.origin}${BASIC_AUTH_ICON}`;
    }

    return (
      this.props.identityProvider.iconUrl ||
      `${window.location.origin}${OAUTH2_ICON}`
    );
  };
}

export default withData(AddNewCredentialModal);
