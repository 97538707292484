import { Icon, LoaderBar } from "@screencloud/screencloud-ui-components";
import { appInstanceIconUrl } from "src/helpers/appHelper";
import { AvailableAppInstanceFragment } from "src/types.g";
import NoCanvasSearchResult from "src/pages/Canvas/CanvasList/NoCanvasSearchResult";
import { AppPickerItem } from "src/components/CanvasPicker/styles";
import { UseSearchAppInstancePickerByAppId } from "src/components/AppPicker/hooks/useSearchAppInstancePickerByAppId";
import { MediaTitle } from "src/components/MediaTitle";

interface SearchAppContentPickerProps
  extends UseSearchAppInstancePickerByAppId {
  selectedIds: string[];
  onToggleSelection: (app: AvailableAppInstanceFragment) => void;
}

const SearchAppPicker = (props: SearchAppContentPickerProps) => {
  const {
    appInstances,
    loading,
    renderFetchMoreButton,
    selectedIds,
    onToggleSelection,
  } = props;
  const renderAppInstanceItem = (
    app: AvailableAppInstanceFragment,
  ): JSX.Element => {
    const iconUrl = appInstanceIconUrl(app);

    return (
      <AppPickerItem
        className="media-item"
        key={`apps-${app.id}`}
        onClick={() => onToggleSelection(app)}
        selected={selectedIds.includes(app.id)}
      >
        <div className="media-core">
          <div className="media-alpha">
            <div className="thumbnail-preview">
              <div className="thumbnail">
                <div className="wrapper">
                  <img src={iconUrl} />
                </div>
              </div>
            </div>

            <MediaTitle app={app} />
          </div>
        </div>

        <div className="media-checked">
          <Icon name="checked-circle" />
        </div>
      </AppPickerItem>
    );
  };

  return (
    <>
      {appInstances?.map(renderAppInstanceItem)}
      {renderFetchMoreButton}
      {appInstances?.length === 0 && !loading && (
        <NoCanvasSearchResult isTemplate={false} />
      )}
      {loading && <LoaderBar />}
    </>
  );
};

export default SearchAppPicker;
