import * as React from "react";

import * as _ from "lodash";
import { FormattedMessage } from "react-intl";

import {
  Button,
  Checkbox,
  Icon,
  Input,
  Loader,
  Logo,
  Popover,
} from "@screencloud/screencloud-ui-components";
import { ModalStyled, Styled } from "./styles";

import { PrimaryButton } from "../../helpers/whiteLabel";
import codeSample from "./img/code-sample.png";
import { AppContext } from "src/AppContextProvider/AppContext";
import { AppContextType } from "src/AppContextProvider/type";
import withScreenPairing, { WithScreenPairing } from "./hocs/withScreenParing";
import { DEBOUNCE_TIMEOUT_MS } from "src/constants/constants";
import { ScreenCloudContactUrl } from "../../constants/url";
import { Screen } from "src/billinglatest/types";
import { FEATURE_FLAGS_ENUM } from "src/constants/featureFlag";
import { UseSubscription } from "src/billinglatest/hooks/useSubscription";
import { isDummmyPairingCode } from "./utils";
import {
  autoPairingCodeKey,
  getAutoPairingCode,
  removePairingCodeFromUrl,
} from "src/helpers/autoPairingCodeHelper";
import { datadogLogs } from "@datadog/browser-logs";
import { provider } from "src/billinglatest/util/provider";
import { IntercomEvent, trackIntercomEvent } from "src/utils/intercom";
import { ScreenGroup } from "src/types.g";

const BillingMaintenanceMessage = () => {
  return (
    <ModalStyled>
      <Styled>
        <div className="screen">
          <div className="screen-wrapper">
            <Logo dark className="logo" />
            <h3>
              <FormattedMessage
                id="ui_component.screen_pairing_info.billing_maintenance.title"
                defaultMessage="Hang tight!"
              />
            </h3>
            <h3>
              <FormattedMessage
                id="ui_component.screen_pairing_info.billing_maintenance.line1"
                defaultMessage="Our pairing magic is recharging. Try pairing again in an hour — we’ll be back and ready to go!"
              />
            </h3>
          </div>
        </div>
      </Styled>
    </ModalStyled>
  );
};

export interface ScreenParingProps {
  onPairing: (
    code: string,
    callback: (success: boolean, errorMessage?: string) => void,
    screenGroups?: ScreenGroup[],
  ) => void;
  screenLicences?: Screen;
  subscription: UseSubscription;
  defaultPairingCode?: string;
  isTrialMode?: boolean;
  onTrialModeChange?: (isTrialModeChecked: boolean) => void;
  openOnboardingScreenPairingModal?: () => void;
  isReplaceDevice?: boolean;
  isDuringOnboarding?: boolean;
  customCloseButton?: JSX.Element;
  isAbleToReplaceDevice?: boolean;
}

export interface ScreenHardwareDeviceStatus {
  error: boolean;
  isLoading: boolean;
  isTrialMode: boolean;
  pairing_code: string;
  errorMessage: string;
}

export class ScreenPairing extends React.Component<
  ScreenParingProps & WithScreenPairing,
  ScreenHardwareDeviceStatus
> {
  public debounceParing = _.debounce(() => {
    this.props.onPairing(
      this.state.pairing_code,
      this.onComplete,
      this.props.screenGroups,
    );
  }, DEBOUNCE_TIMEOUT_MS);

  private inputRef;
  public static contextType = AppContext;
  public context: AppContextType;

  constructor(props: ScreenParingProps & WithScreenPairing) {
    super(props);
    this.state = {
      error: false,
      errorMessage: "ui_component.screen_pairing.pair_error",
      isLoading: false,
      isTrialMode: this.props.isTrialMode ? this.props.isTrialMode : false,
      pairing_code: this.props.defaultPairingCode || "",
    };
  }

  public async componentDidMount() {
    this.autoTypingPairingCode(getAutoPairingCode());
  }

  private autoTypingPairingCode = async (pairingCode: string) => {
    if (!pairingCode) {
      return;
    }

    datadogLogs.logger.info("[Auto Pairing Code] Triggered", {
      [autoPairingCodeKey]: pairingCode,
    });
    removePairingCodeFromUrl();

    for (let i = 0; i < pairingCode.length; i++) {
      this.setState({ pairing_code: pairingCode.slice(0, i + 1) });
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  };

  public onPairing = async () => {
    if (!this.state.isLoading) {
      this.setState({ isLoading: true, error: false });
      this.debounceParing();
    }
  };

  public onTrialModeChange = (e, data) => {
    this.setState({ isTrialMode: data.checked });
    if (this.props.onTrialModeChange && data) {
      this.props.onTrialModeChange(data.checked);
    }
  };

  public onChange = (
    event: React.SyntheticEvent<any>,
    data: { [key: string]: string },
  ) => {
    this.setState({ pairing_code: data.value });
  };

  public handleKeyDown = (event: React.KeyboardEvent<any>) => {
    const key = event.key;
    if (key === "Enter" && this.state.pairing_code.length === 6) {
      this.onPairing();
    }
  };

  public onComplete = (error: boolean, errorMessage: string) => {
    if (this.props.isDuringOnboarding) {
      trackIntercomEvent(IntercomEvent.SCREEN_PAIRING_ONBOARDING_COMPLETE);
    }
    this.setState({
      isLoading: false,
      error,
      errorMessage,
    });
  };

  public onAddNewScreen = () => {
    this.setState({ error: false });
  };

  public componentDidUpdate(
    prevProps: ScreenParingProps,
    prevState: ScreenHardwareDeviceStatus,
  ) {
    setTimeout(() => {
      if (this.inputRef && this.inputRef.focus) {
        this.inputRef.focus();
      }
    }, 300);
  }

  handleReplaceDevice = () => {
    this.props.selectReplaceDevice(this.state.pairing_code);
  };

  canReplaceDevice = () => {
    const { isAbleToReplaceDevice = true } = this.props;
    return (
      !isDummmyPairingCode(this.state.pairing_code) &&
      !this.props.isReplaceDevice &&
      isAbleToReplaceDevice
    );
  };

  public render() {
    const {
      availableLicensesCount,
      remainingTrialDays,
      isInTrial,
      usedLicencesCount,
      licensesLeft,
      customCloseButton,
      openOnboardingScreenPairingModal,
    } = this.props;

    const shouldDisplayOnboardingScreenPairingButton =
      !this.props.isDuringOnboarding &&
      !this.context.shouldShowFeature(FEATURE_FLAGS_ENUM.RESELLER) &&
      !this.props.isReplaceDevice;

    const hasReachedLimit = licensesLeft !== undefined && licensesLeft <= 0;

    if (
      this.context.shouldShowFeature(FEATURE_FLAGS_ENUM.BILLING_MAINTENANCE) &&
      provider.get() === "chargebee"
    ) {
      return <BillingMaintenanceMessage />;
    }

    return (
      <ModalStyled>
        <Styled>
          {customCloseButton}
          {shouldDisplayOnboardingScreenPairingButton && (
            <Button
              basic
              mini
              data-testid={"button-device-information"}
              onClick={() => {
                openOnboardingScreenPairingModal?.();
              }}
            >
              <span>Find your perfect digital signage device</span>
              <Icon name="arrow-right-circle" />
            </Button>
          )}
          <div className="screen">
            <div className="screen-wrapper">
              <Logo dark className="logo" />
              <h3>
                <FormattedMessage
                  id="ui_component.screen_pairing.enter_pairing_code"
                  defaultMessage="Enter the 6-character pairing code"
                />
                <Popover
                  content={
                    <img
                      src={codeSample}
                      title="Sample pairing code"
                      width="184px"
                    />
                  }
                  position="bottom center"
                  trigger={<Icon name="help" />}
                />
              </h3>
              <div className="input-wrapper">
                <Input
                  data-testid="input-pairing-code"
                  ref={(x) => {
                    this.inputRef = x;
                  }}
                  autoFocus
                  spellCheck={false}
                  maxLength={6}
                  onChange={this.onChange}
                  onKeyPress={this.handleKeyDown}
                  value={this.state.pairing_code}
                />
                {this.state.error && (
                  <span className="warning">
                    <FormattedMessage
                      id={this.state.errorMessage}
                      defaultMessage={
                        this.state.errorMessage ===
                        "ui_component.screen_pairing.pair_error"
                          ? "This code is incorrect."
                          : "There is no valid card."
                      }
                    />
                    &nbsp;
                    <span>
                      Need help? Contact{" "}
                      <a href={ScreenCloudContactUrl}>support.</a>
                    </span>
                  </span>
                )}
              </div>
              <div className="trial-mode">
                {!this.props.isReplaceDevice && (
                  <Popover
                    content={
                      <FormattedMessage
                        id="addscreen.preview_mode"
                        defaultMessage="Preview mode allows you to experiment without using up licenses. You can then publish your screen to make it live at any time."
                      />
                    }
                    position="top center"
                    trigger={
                      <Checkbox
                        data-testid="preview-mode-checkbox"
                        checked={this.state.isTrialMode}
                        label="Start screen in Preview Mode"
                        onChange={this.onTrialModeChange}
                      />
                    }
                  />
                )}
              </div>
              <div className="screen-license">
                {this.props.screenLicences && (
                  <span>
                    <strong>{usedLicencesCount}</strong> out of{" "}
                    <strong>{availableLicensesCount}</strong>
                    &nbsp;
                    {isInTrial ? (
                      <span>
                        free licenses are being used, active until your trial
                        ends in{" "}
                        <strong data-testid="trial-remaining-days">
                          {remainingTrialDays}
                        </strong>{" "}
                        days.
                      </span>
                    ) : (
                      "licenses are being used."
                    )}
                  </span>
                )}
                {isInTrial && (
                  <span>
                    To keep your paired screens after this date, please add your
                    payment details.
                  </span>
                )}
                {!isInTrial && hasReachedLimit && (
                  <span>
                    {this.state.isTrialMode
                      ? "This additional trial screen won't be added to your organization pro rata for the current billing period until it's activated."
                      : "This additional screen will be added to your organization pro rata for the current billing period."}
                  </span>
                )}
              </div>
              {this.props.isReplaceDevice && (
                <div className="replace-device-info-text">
                  <span>
                    <FormattedMessage
                      id="ui_component.replace_device.replace_device_info"
                      defaultMessage="The selected screen settings will be seamlessly transferred to your new device."
                    />
                  </span>
                </div>
              )}
            </div>
          </div>
        </Styled>
        <div className="md-footer custom-align center">
          <PrimaryButton
            data-testid="pairing-button"
            disabled={this.state.pairing_code.length < 6}
            onClick={this.onPairing}
          >
            {!this.state.isLoading ? (
              <FormattedMessage
                id="ui_component.common.label.continue"
                defaultMessage="Continue"
              />
            ) : (
              <Loader inline size="small" active>
                <FormattedMessage
                  id="common.text.loading"
                  defaultMessage="Loading"
                />
              </Loader>
            )}
          </PrimaryButton>
          {this.canReplaceDevice() && (
            <Button
              disabled={this.state.pairing_code.length < 6}
              inline
              className="button-replace-device"
              data-testid="button-replace-device"
              onClick={this.handleReplaceDevice}
            >
              <span>Replace device instead</span>
            </Button>
          )}
        </div>
      </ModalStyled>
    );
  }
}

export default withScreenPairing(
  ScreenPairing,
) as React.ComponentType<ScreenParingProps>;
