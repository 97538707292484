import { HardwareDeviceStatus } from ".";
import { PairingMethodEnum } from "./types";

export const determinePairingMethod = ({
  hasCorrectHardware,
  hasScreenNearby,
  isSmartTV,
}: HardwareDeviceStatus): PairingMethodEnum => {
  if (hasCorrectHardware) {
    return hasScreenNearby
      ? PairingMethodEnum.HARDWARE
      : PairingMethodEnum.MOBILE;
  }

  if (hasScreenNearby) {
    return isSmartTV ? PairingMethodEnum.SMART_TV : PairingMethodEnum.MOBILE;
  }

  return PairingMethodEnum.MOBILE;
};
