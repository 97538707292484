import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";
import image from "../../constants/images/empty-file-upload-landing.png";
export interface StyledMediaPickerContainerProps {
  isMultiSelect?: boolean;
  isOrgWidePath?: boolean;
}

export const StyledMediaPickerContainer = styled.div<StyledMediaPickerContainerProps>`
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  .loadmore-items {
    width: 100%;
    padding: 0 20px;
    display: inline-block;
  }
  .loading-message {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    .ui.loader {
      margin-right: 5px;
    }
  }
  .caret-order {
    width: 10px;
    margin-left: 10px;
  }

  .container {
    .media-item-header {
      span,
      .button {
        text-transform: uppercase;
        color: ${Theme.color.grey60};
        z-index: 1;
        position: relative;
        height: 40px;
        font-size: 12px;
        display: inline-flex;
        align-items: center;
        font-weight: bold;
        letter-spacing: 0.1em;
      }

      i {
        width: 8px;
        margin-left: 5px;
        height: 4px;
        background-color: ${Theme.color.grey60};
      }

      .media-core {
        width: calc(100% - 60px);
        display: flex;

        [class^="media-"] {
          text-align: center;
        }

        .media-alpha {
          width: 50%;
          text-align: left;
          padding: 0 0 0 20px;

          :hover {
            background: transparent;
          }
        }

        .media-scheduled,
        .media-casting,
        .media-starred,
        .media-duration,
        .media-kind {
          display: block;
          width: 10%;
        }

        .media-size {
          display: none;
        }

        @media screen and (max-width: ${Theme.break.mediumAlt}) {
          .media-alpha {
            width: 70%;
          }

          .media-scheduled,
          .media-casting,
          .media-starred {
            width: 10%;
          }

          .media-duration,
          .media-kind,
          .media-tags {
            display: none;
          }
        }

        @media screen and (max-width: ${Theme.break.small}) {
          width: calc(100% - 40px);

          .media-alpha {
            width: calc(100% - 210px);
          }

          .media-scheduled,
          .media-casting,
          .media-starred {
            width: 70px;
          }
        }

        @media screen and (max-width: ${Theme.break.tiny}) {
          .media-alpha {
            width: 100%;
          }

          .media-scheduled,
          .media-casting,
          .media-starred {
            display: none;
          }
        }
      }
    }
  }

  #media-picker {
    &__sidebar {
      background: ${Theme.color.white};
      border-right: 1px solid ${Theme.color.grey40};
      height: 100%;
      left: 0;
      padding: 10px 0;
      width: 160px;
      z-index: 2;
      border-radius: 0 0 0 ${Theme.default.borderRadiusValue};

      @media screen and (max-width: ${Theme.break.small}) {
        overflow-x: auto;
      }

      .button {
        width: 100%;
        text-align: left;
        margin: 0;
        padding: 0 20px;
        border-radius: 0px;
        font-weight: normal;
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
        height: 45px;

        span:first-letter {
          text-transform: uppercase;
        }

        i {
          background-color: ${Theme.color.grey80};
        }

        &.active,
        :focus,
        :hover {
          background-color: ${Theme.color.grey80};
          color: ${Theme.color.white};

          i {
            background-color: ${Theme.color.white};
          }
        }

        :focus,
        :hover {
          background-color: ${Theme.color.grey100};
        }

        i {
          margin-right: 10px;
        }
      }
    }

    &__content {
      padding: 0;
      width: calc(100% - 160px);
      position: relative;
      background: ${Theme.color.grey20};
      height: calc(100% - 65px);

      .empty-state,
      .media-content,
      .layout-list {
        + .empty {
          display: none;
        }
      }

      .empty {
        width: 100%;
        height: calc(100% - 40px);
        display: flex;
        align-items: center;

        img {
          max-width: 280px;
        }

        .ui.loader {
          display: block;
          position: relative;
          transform: none;
          margin: 0 auto;
          bottom: auto;
          right: auto;
        }
      }

      .media-content {
        height: 100%;

        &.apps {
          .content-header.media-tags + .container {
            height: calc(100% - 100px);
          }
        }
        .content-header {
          background-color: ${Theme.color.white};
          border-bottom: 1px solid ${Theme.color.grey40};
          height: 66px;
          padding: 10px 20px;
          width: 100%;
          &.media-tags {
            height: unset;
            gap: 10px;
            display: flex;
            flex-direction: column;
          }

          > .media-search-box {
            margin-bottom: 10px;
            ${(props) =>
              !props.isOrgWidePath &&
              `
              width: calc(100% - 110px);
              float: left;
            `};

            + .button {
              margin-left: 10px;
              width: 100px;
              margin-bottom: 10px;
            }
          }
        }

        .container {
          height: calc(100% - 65px);
          overflow: auto;
          width: 100%;

          > .breadcrumb {
            width: 100%;
            padding: 0 20px;
            height: 40px;
            display: flex;
            align-items: center;
          }
        }

        .layout-list {
          border-top: 1px solid ${Theme.color.grey40};
          height: calc(100% - 40px);
          margin-left: 0px;
          margin-right: 0px;
          overflow: auto;
          padding-bottom: 20px;
        }

        .ui.loader {
          bottom: calc(50% - 60px);
          right: calc(50% - 90px);
        }

        &.media {
          .layout-list {
            height: auto;
          }
        }

        &.apps-search {
          .layout-list {
            height: 100%;
          }
        }
      }

      .media-folder,
      .media-file {
        overflow: hidden;
      }

      .buttons {
        text-align: center;
        margin-top: 1em;

        .button {
          height: 40px;
        }
      }
    }
  }

  .md-footer {
    .info-group {
      display: flex;
      gap: 8px;
    }
    .picker-content-info {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      height: 45px;

      span {
        font-size: 14px;
        line-height: 1.357;
        margin-right: 3px;
        color: ${Theme.color.grey80};
      }

      .ui.inline.dropdown {
        font-size: 14px;

        .input {
          font-size: 16px;
        }

        .menu > .item {
          font-size: 14px;
        }
      }
    }

    div.ui.dropdown:not(.inline) {
      width: 180px;
      flex-shrink: 0;

      > .item {
        display: flex;
        align-items: center;
        gap: 8px;

        i {
          background-color: var(--color-grey100);
        }
      }

      div.menu {
        align-items: flex-start;
        display: none;

        > .item {
          width: 100%;
        }
      }
    }

    .selected-item {
      align-items: center;
      font-weight: 600;
      display: inline-flex;
      width: calc(50% - 5px);

      .count {
        align-items: center;
        background: ${Theme.color.grey100};
        border-radius: 100%;
        color: ${Theme.color.white};
        display: inline-flex;
        font-size: 0.75em;
        height: 20px;
        justify-content: center;
        margin-left: 5px;
        min-width: 20px;
        width: auto;
      }
    }
  }

  #droppane {
    height: 100%;
    max-height: 100%;
    width: 100%;

    .fsp-modal {
      border-bottom: 0px solid transparent;
      .fsp-modal__sidebar {
        .fsp-source-list {
          .fsp-source-list__item {
            &.fsp-source-list__item--active {
              background-color: ${Theme.color.grey20};
            }

            .fsp-icon--unsplash {
              background: ${Theme.color.white}
                url(https://static.filestackapi.com/picker/1.20.1/assets/images/unsplashlogo.svg);
              background-size: 80%;
              background-position: center;
              border-radius: 100%;
            }

            :focus {
              box-shadow: none;
            }
          }
        }
      }
      .fsp-modal__body {
        background-color: ${Theme.color.grey20};
        padding: 0;

        .fsp-header {
          display: none;
        }
        .fsp-content {
          height: 100%;

          .fsp-unsplash-source {
            height: 100%;
          }

          .fsp-source-auth__wrapper {
            padding: 20px;
          }

          .fsp-url-source__form,
          .fsp-unsplash-source__form {
            width: 100%;
            max-width: 400px;
          }
        }
      }
    }
    .fsp-drop-area-container {
      background-color: ${Theme.color.grey20};
      padding: 20px;
    }
    .fsp-header-icon {
      width: 0px;
      height: 0px;
    }
    .fsp-drop-area {
      background-color: ${Theme.color.grey20};
      border: 3px dashed ${Theme.color.grey30};
      ${Theme.default.borderRadius};
      cursor: pointer;
      height: 100%;

      :focus,
      :hover {
        background-color: ${Theme.color.grey30};
        box-shadow: none;
        border-color: ${Theme.color.grey40};
      }
    }
    .fsp-select-labels {
      width: 200px;
      padding-top: 90px;
      background-size: contain;
      background-image: url(${image});

      &:after {
        display: inline-block;
        content: "Browse";
        width: auto;
        background: ${Theme.color.blue};
        color: ${Theme.color.white};
        font-size: 1em;
        font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
        height: 45px;
        line-height: 45px;
        padding: 0 20px;
        ${Theme.default.borderRadius};
        font-weight: bold;
        margin-top: 20px;
        box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, 0.1);

        :hover {
          background: ${Theme.color.blueHover};
        }
      }
    }

    @media screen and (max-height: 600px),
      screen and (max-width: ${Theme.break.small}) {
      max-height: 100%;
    }

    @media screen and (max-width: ${Theme.break
        .small}) and (max-height: 720px) {
      height: 100%;
    }

    @media screen and (max-width: ${Theme.break.small}) {
      height: 100%;
    }
  }

  @media screen and (max-width: ${Theme.break.mediumAlt}) {
    #media-picker {
      &__content {
        width: calc(100% - 60px);
      }

      &__sidebar {
        width: 60px;

        .button {
          i {
            margin: 0;
          }

          span {
            display: none;
          }
        }
      }
    }
    .md-footer {
      .picker-content-info {
        padding-right: 20px;
      }
    }
  }

  @media screen and (max-width: ${Theme.break.small}) {
    #media-picker__content {
      height: 100%;

      .media-content {
        height: calc(100% - 125px);
      }
      .md-footer {
        flex-wrap: wrap;
        height: 125px;
        padding-top: 10px;
        padding-bottom: 10px;
        justify-content: flex-start;

        .info-group {
          width: 100%;
          margin-bottom: 10px;
          flex-wrap: wrap;

          .picker-content-info {
            padding: 0;

            .ui.inline.dropdown {
              height: unset;
            }
          }
        }

        .button.primary {
          width: 100%;
          margin: 0;
          margin-right: 0;
          margin-left: 0;
        }
      }
    }
  }

  @media screen and (max-width: ${Theme.break.tiny}) {
    #media-picker {
      &__sidebar {
        width: 100%;
        height: 45px;
        padding: 0;
        z-index: 3;
        display: flex;
        border-right: none;
        border-bottom: 1px solid ${Theme.color.grey30};

        .button {
          align-items: center;
          display: inline-flex;
          height: 100%;
          justify-content: center;
          padding: 0;
          width: calc(100% / 6);

          i {
            width: 18px;
            height: 18px;
          }
        }
      }

      &__content {
        height: calc(100% - 45px);
        width: 100%;

        .media-content {
          height: calc(100% - 180px);
          .content-header {
            left: 0;
            padding: 10px 15px;
            top: 100px;
            width: 100%;
          }
        }

        .md-footer {
          padding: 10px 15px;
          height: 180px;

          .info-group {
            div.ui.dropdown:not(.inline) {
              width: 100%;
              margin-right: 0;
            }

            .picker-content-info {
              margin-top: 10px;
              width: 100%;

              .ui.inline.dropdown {
                margin-left: 2px;
              }
            }
          }
        }
      }
    }
  }
`;
