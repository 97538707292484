import { Icon, LoaderBar } from "@screencloud/screencloud-ui-components";
import { FormattedMessage } from "react-intl";
import { AvailableAppInstanceFragment } from "src/types.g";
import { appInstanceIconUrl } from "src/helpers/appHelper";
import EmptyState from "src/components/EmptyState";
import { useAppContext } from "src/hooks/useAppContext";
import { UseAppInstancePicker } from "src/components/AppPicker/hooks/useAppInstancePicker";
import { AppPickerItem } from "src/components/AppPicker/styles";
import { MediaTitle } from "src/components/MediaTitle";

interface Props extends Omit<UseAppInstancePicker, "totalCount"> {
  selectedIds: string[];
  onToggleSelection: (app: AvailableAppInstanceFragment) => void;
  onClickTag: (tag: string) => void;
}

export const AppInstances = (props: Props) => {
  const {
    selectedIds,
    onToggleSelection,
    onClickTag,
    appInstances,
    loading,
    renderFetchMoreButton,
  } = props;
  const context = useAppContext();

  const canCreateAppInstance = context.currentPermissions.validateCurrentSpace(
    "app_instance",
    "create",
  );

  if (loading) {
    return <LoaderBar />;
  }

  if (appInstances.length > 0) {
    return (
      <>
        {appInstances.map((app) => {
          const iconUrl = appInstanceIconUrl(app);
          return (
            <AppPickerItem
              className="media-item"
              key={`apps-${app.id}`}
              onClick={() => onToggleSelection(app)}
              selected={selectedIds.includes(app.id)}
            >
              <div className="media-core">
                <div className="media-alpha">
                  <div className="thumbnail-preview">
                    <div className="thumbnail">
                      <div className="wrapper">
                        <img src={iconUrl} />
                      </div>
                    </div>
                  </div>
                  <MediaTitle app={app} onClickTag={onClickTag} />
                </div>
              </div>
              <div className="media-checked">
                <Icon name="checked-circle" />
              </div>
            </AppPickerItem>
          );
        })}
        <div>{renderFetchMoreButton}</div>
      </>
    );
  } else {
    return (
      <EmptyState section="apps">
        <h3>
          <FormattedMessage
            id={
              canCreateAppInstance
                ? `apps.empty_message`
                : `apps.empty_message_no_edit`
            }
            defaultMessage="You haven't used this app yet"
          />
        </h3>
        <p>
          <FormattedMessage
            id={
              canCreateAppInstance
                ? `apps.help_message`
                : `apps.help_message_no_edit`
            }
            defaultMessage="Create your first app instance"
          />
        </p>
      </EmptyState>
    );
  }
};
