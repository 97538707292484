import useReplaceDevice from "src/domains/screens/hooks/useReplaceDevice";
import { ScreenParingProps } from "..";
import { UUID } from "@screencloud/uuid";
import { ScreenGroup, useGetPrimaryScreenGroupQuery } from "src/types.g";
import { ssm } from "src/state/session/ssm";

export interface WithScreenPairing {
  remainingTrialDays: number | null;
  availableLicensesCount?: number;
  isInTrial: boolean;
  usedLicencesCount?: number;
  licensesLeft?: number;
  screenGroups: ScreenGroup[];
  replaceDevice: (pairingCode: string, screenId: UUID) => void;
  selectReplaceDevice: (pairingCode: string) => void;
}

const withScreenPairing =
  <T extends {}>(Component: React.ComponentType<T & WithScreenPairing>) =>
  (props: T & ScreenParingProps) => {
    const { screenLicences, subscription } = props;
    const { replaceDevice, openSelectScreenForReplaceDeviceModal } =
      useReplaceDevice();

    const variables = { spaceId: ssm.current.settings.spaceId };

    const getPrimaryScreenGroup = useGetPrimaryScreenGroupQuery({
      variables,
      fetchPolicy: "cache-and-network",
    });

    const screenGroups =
      getPrimaryScreenGroup?.data?.allScreenGroups?.nodes ?? [];

    return (
      <Component
        {...props}
        isInTrial={subscription.isInTrial()}
        remainingTrialDays={subscription.remainingTrialDays()}
        availableLicensesCount={screenLicences?.total}
        usedLicencesCount={screenLicences?.active}
        licensesLeft={screenLicences?.remaining}
        replaceDevice={replaceDevice}
        selectReplaceDevice={openSelectScreenForReplaceDeviceModal}
        screenGroups={screenGroups as ScreenGroup[]}
      />
    );
  };

export default withScreenPairing;
