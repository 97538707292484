import { appConfig } from "src/appConfig";
import pixiDevice from "src/pages/Welcome/images/pixi-device.png";
import pixiLogo from "src/pages/Welcome/images/light-pixi-icon.svg";
import { FormattedMessage } from "react-intl";

export const PixiCard = (): JSX.Element => {
  const storeUrl = appConfig.pixiStoreUrl;
  const handleOnCardClick = (url: string) => {
    window.open(url, "_blank");
  };
  return (
    <div
      data-testid="trialist-empty-screen-pixi-card"
      className="trialist-empty-screen-card pixi-card"
      onClick={() => handleOnCardClick(storeUrl)}
    >
      <div className="card-image-container">
        <img className="pixi-device-img" src={pixiDevice} />
      </div>
      <div className="card-heading">
        <h4 className="title">
          <FormattedMessage
            id="ui_component.screen_list_empty.pixi_trialist.pixi_card_title"
            defaultMessage="Try our {device} Hardware"
            values={{
              device: (
                <img
                  src={pixiLogo}
                  alt="Pixi"
                  className="inline-icon pixi-icon"
                />
              ),
            }}
          />
        </h4>
        <p className="card-description">
          <FormattedMessage
            id="ui_component.screen_list_empty.pixi_trialist.pixi_card_description"
            defaultMessage="The lightweight digital signage solution that won't weigh down your budget."
          />
        </p>
      </div>
    </div>
  );
};
