import { Icon, LoaderBar } from "@screencloud/screencloud-ui-components";
import { FormattedMessage } from "react-intl";
import { AvailableAppInstanceFragment } from "src/types.g";
import { appInstanceIconUrl } from "src/helpers/appHelper";
import EmptyState from "src/components/EmptyState";
import { AppPickerItem } from "src/components/AppPicker/styles";
import { UseSearchAppInstancePicker } from "src/components/AppPicker/hooks/useSearchAppInstancePicker";
import { MediaTitle } from "src/components/MediaTitle";

export interface Props extends UseSearchAppInstancePicker {
  selectedIds: string[];
  onToggleSelection: (app: AvailableAppInstanceFragment) => void;
}

const SearchAppPicker = ({
  selectedIds,
  onToggleSelection,
  appInstances,
  loading,
  renderFetchMoreButton,
}: Props) => {
  const renderAppInstance = (app: AvailableAppInstanceFragment) => {
    const iconUrl = appInstanceIconUrl(app);

    return (
      <AppPickerItem
        className="media-item"
        key={`apps-${app.id}`}
        onClick={() => onToggleSelection(app)}
        selected={selectedIds.includes(app.id)}
      >
        <div className="media-core">
          <div className="media-alpha">
            <div className="thumbnail-preview">
              <div className="thumbnail">
                <div className="wrapper">
                  <img src={iconUrl} />
                </div>
              </div>
            </div>
            <MediaTitle app={app} />
          </div>
        </div>
        <div className="media-checked">
          <Icon name="checked-circle" />
        </div>
      </AppPickerItem>
    );
  };

  if (loading) return <LoaderBar />;

  if (appInstances.length === 0) {
    return (
      <EmptyState section="search-apps" className="empty">
        <h3>
          <FormattedMessage
            id="common.search.no_results"
            defaultMessage="We couldn't find any matches"
          />
        </h3>
        <p>
          <FormattedMessage
            id="common.search.no_results_description"
            defaultMessage="Try adjusting or using different search terms."
          />
        </p>
      </EmptyState>
    );
  }

  return (
    <>
      {appInstances.map(renderAppInstance)}
      {renderFetchMoreButton}
    </>
  );
};

export default SearchAppPicker;
