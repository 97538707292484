import { Button } from "@screencloud/screencloud-ui-components";
import { Component } from "react";
import { FormattedMessage } from "react-intl";
import { StyledMessage } from "./styles";

export enum BillingMessageType {
  EXPIRED = "EXPIRED",
  PAYMENT_MISSING = "PAYMENT_MISSING",
  IN_TRIAL = "IN_TRIAL",
  PARTNER_LIMIT_REACHED = "PARTNER_LIMIT_REACHED",
}
export enum BillingMessageActionsEnum {
  PAYMENT = "PAYMENT",
  CANCEL = "CANCEL",
  RESUME = "RESUME",
}
import { PrimaryButton } from "../../../helpers/whiteLabel";
import expiredScreenPlaceholder from "../../../images/screen-issue.svg";

import { provider } from "src/billinglatest/util/provider";
import { CanPairScreenResponseCode, Screen } from "src/billinglatest/types";

export type BillingMessageActions =
  | BillingMessageActionsEnum.PAYMENT
  | BillingMessageActionsEnum.CANCEL
  | BillingMessageActionsEnum.RESUME;

interface BillingMessageProps {
  type?: CanPairScreenResponseCode;
  screenLicenses?: Screen;
  underMaintenance?: boolean;
  callback: (action: BillingMessageActions) => void;
}

class BillingMessage extends Component<BillingMessageProps> {
  public renderMessage() {
    const { type, underMaintenance } = this.props;

    if (underMaintenance && provider.get() !== "azure") {
      return (
        <>
          <h1>
            <FormattedMessage
              defaultMessage="Hang Tight!"
              id="addscreen.maintenance.line1"
            />
          </h1>
          <div>
            <FormattedMessage
              defaultMessage="ScreenCloud’s billing system is getting a little TLC right now. Please Try again later."
              id="addscreen.maintenance.line2"
            />
          </div>
        </>
      );
    }
    if (type === "BILLING_SUBSCRIPTION_EXPIRED") {
      return (
        <>
          <h1>
            <FormattedMessage
              defaultMessage="Uh Oh! Your account subscription has expired"
              id="addscreen.expired.line1"
            />
          </h1>
          {provider.isChargebee() && (
            <div>
              <FormattedMessage
                defaultMessage="Please provide your payment details to reactivate your account"
                id="addscreen.expired.line2"
              />
            </div>
          )}
        </>
      );
    }

    if (type === "BILLING_IN_TRIAL_LIMIT_REACHED") {
      return (
        <>
          <h1>
            <FormattedMessage
              defaultMessage="You have reached your limit for screens during your trial"
              id="addscreen.trial_expired.line1"
            />
          </h1>
          <div>
            <FormattedMessage
              defaultMessage="Please provide your payment details to add more screens to your account"
              id="addscreen.trial_expired.line2"
            />
          </div>
        </>
      );
    }

    if (type === "BILLING_PARTNER_LIMIT_REACHED") {
      return (
        <>
          <h1>
            <FormattedMessage
              defaultMessage="You have reached your partner screen limit"
              id="addscreen.partner_limit_reached.line1"
            />
          </h1>
          <div>
            <FormattedMessage
              defaultMessage="Your subscription allows a maximum of {total, number} {total, plural, one {license} other {licenses}} and you have used {used, number} {used, plural, one {license} other {licenses}}"
              id="addscreen.partner_limit_reached.line2"
              values={{
                total: this.props.screenLicenses?.total,
                used: this.props.screenLicenses?.active,
              }}
            />
          </div>
        </>
      );
    }

    if (type === "STUDIO_CANNOT_PAIR_SCREEN") {
      return (
        <h1>
          <FormattedMessage
            defaultMessage="You don't have permission to pair screen."
            id="addscreen.permission_denied.line1"
          />
        </h1>
      );
    }

    if (type === "BILLING_SUBSCRIPTION_PAUSED") {
      return (
        <h1>
          <FormattedMessage
            defaultMessage="Uh Oh! Your account subscription is paused"
            id="addscreen.paused.line1"
          />
        </h1>
      );
    }

    return (
      <h1>
        <FormattedMessage
          defaultMessage="Hold up, you need to add a payment method in order to add a screen."
          id="addscreen.payment.line1"
        />
      </h1>
    );
  }

  public renderButton() {
    const { callback, type } = this.props;
    if (provider.get() === "azure") {
      return (
        <PrimaryButton
          data-testid="button-payment"
          onClick={() => (window.location.href = "https://portal.azure.com/")}
        >
          <FormattedMessage
            defaultMessage="Manage Subscription"
            id="billing.manage_subscription"
          />
        </PrimaryButton>
      );
    } else if (
      type !== "BILLING_PARTNER_LIMIT_REACHED" &&
      type !== "BILLING_SUBSCRIPTION_PAUSED"
    ) {
      return (
        <PrimaryButton
          data-testid="button-payment"
          onClick={() => callback(BillingMessageActionsEnum.PAYMENT)}
        >
          <FormattedMessage
            defaultMessage="Add payment details"
            id="billing.add_payment_details"
          />
        </PrimaryButton>
      );
    } else if (type === "BILLING_SUBSCRIPTION_PAUSED") {
      return (
        <PrimaryButton
          data-testid="button-payment"
          onClick={() => callback(BillingMessageActionsEnum.RESUME)}
        >
          <FormattedMessage
            defaultMessage="Go to Billing"
            id="billing.go_to_billing"
          />
        </PrimaryButton>
      );
    } else {
      return null;
    }
  }

  public render() {
    const { callback } = this.props;
    return (
      <StyledMessage>
        <img src={expiredScreenPlaceholder} />
        {this.renderMessage()}
        <div className="button-options">
          <Button
            data-testid="button-cancel"
            onClick={() => callback(BillingMessageActionsEnum.CANCEL)}
          >
            <FormattedMessage
              defaultMessage="Cancel"
              id="ui_component.common.label.cancel"
            />
          </Button>
          {(!this.props.underMaintenance || provider.get() === "azure") &&
            this.renderButton()}
        </div>
      </StyledMessage>
    );
  }
}

export default BillingMessage;
