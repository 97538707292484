import { UseStateSearch, useStateSearch } from "src/hooks/useStateSearch";
import {
  UseAppInstallPicker,
  useAppInstallsPicker,
} from "src/components/AppPicker/hooks/useAppInstallsPicker";
import { App } from "src/types.g";
import { getDistinctAppsByAppInstalls } from "src/domains/appInstall";

export interface UseAppPickerReturn
  extends UseStateSearch,
    Omit<UseAppInstallPicker, "appInstalls"> {
  apps: App[];
}

export const useAppPicker = (spaceId: string): UseAppPickerReturn => {
  const appInstallsProps = useAppInstallsPicker(spaceId);
  const stateSearchProps = useStateSearch();
  const apps = getDistinctAppsByAppInstalls(appInstallsProps.appInstalls);

  return {
    ...stateSearchProps,
    ...appInstallsProps,
    apps,
  };
};
