import { Button, Icon } from "@screencloud/screencloud-ui-components";
import classNames from "clsx";
import { FormattedMessage } from "react-intl";
import { Styled } from "./styles";
import { useEffect, useState } from "react";
export interface TagListPros extends React.HTMLAttributes<HTMLDivElement> {
  tags: string[];
  onClickTag: (tag: string) => void;
  isTagIdSelected: (tag: string) => boolean;
  maximumDisplayTags?: number;
  hasSelectedTags?: boolean;
  isMediaPicker?: boolean;
}

export function TagClearSelection(props: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <Styled {...props}>
      <span className="clear-selection">
        <Icon name="close" />
        <FormattedMessage
          id="canvas.tagging.clear_selection"
          defaultMessage="Clear Selection"
        />
      </span>
    </Styled>
  );
}

export function getMaxiumDisplayTags(
  tags: string[],
  maximumDisplayTags?: number,
) {
  if (!maximumDisplayTags) {
    return tags;
  }
  return tags.slice(0, maximumDisplayTags);
}

export function TagList(props: TagListPros) {
  const {
    hasSelectedTags,
    tags,
    onClickTag,
    isTagIdSelected,
    maximumDisplayTags,
    isMediaPicker,
    ...htmlDivProps
  } = props;
  const shouldDisplayMoreTags =
    maximumDisplayTags && tags.length > maximumDisplayTags;
  const [tagsToDisplay, setTagsToDisplay] = useState<string[]>(
    getMaxiumDisplayTags(tags, maximumDisplayTags),
  );
  useEffect(() => {
    if (hasSelectedTags) {
      setTagsToDisplay(tags);
    } else {
      setTagsToDisplay(getMaxiumDisplayTags(tags, maximumDisplayTags));
    }
    setIsShowAllags(!maximumDisplayTags || !!hasSelectedTags);
  }, [tags, maximumDisplayTags]);

  const [isShowAllTags, setIsShowAllags] = useState<boolean>(
    !maximumDisplayTags || !!hasSelectedTags,
  );

  const onShowAllTagsClicked = () => {
    setTagsToDisplay(getMaxiumDisplayTags(tags));
    setIsShowAllags(true);
  };

  const onShowLessTagsClicked = () => {
    setTagsToDisplay(getMaxiumDisplayTags(tags, maximumDisplayTags));
    setIsShowAllags(false);
  };

  return (
    <Styled {...htmlDivProps} isMediaPicker={isMediaPicker}>
      {tagsToDisplay.map((tag) => (
        <span
          key={tag}
          onClick={() => onClickTag(tag)}
          className={classNames("tag", { selected: isTagIdSelected(tag) })}
          data-testid="tag-filter"
          data-cy="tag-filter"
        >
          {!isMediaPicker && <Icon name="tag" />}
          <span className="tag-label">{tag}</span>
        </span>
      ))}
      {shouldDisplayMoreTags && (
        <Button
          onClick={isShowAllTags ? onShowLessTagsClicked : onShowAllTagsClicked}
          className="collapse-tags"
          mini
        >
          {isShowAllTags ? (
            <FormattedMessage
              id="screens.tagging.minimize"
              defaultMessage="Minimize"
            />
          ) : (
            <FormattedMessage
              id="screens.tagging.more_tags"
              defaultMessage="Show all ({count} more)"
              values={{ count: tags.length - maximumDisplayTags! }}
            />
          )}
        </Button>
      )}
    </Styled>
  );
}
