import {
  Button,
  Icon,
  LoaderBar,
  Search,
} from "@screencloud/screencloud-ui-components";
import { UUID } from "@screencloud/uuid";
import { useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import classNames from "clsx";
import { AppInstance } from "src/types.g";
import EmptyState from "src/components/EmptyState";
import SearchAppPicker from "src/components/CanvasPicker/components/Search";
import { Styled } from "src/components/CanvasPicker/styles";
import { useAppContext } from "src/hooks/useAppContext";
import { useStateSearch } from "src/hooks/useStateSearch";
import { useMediaPickerAppInstances } from "src/components/AppPicker/hooks/useAppInstancePicker";
import { CanvaseInstances } from "src/components/CanvasPicker/components/CanvaseInstances";
import { useTagSelection } from "src/components/TagList/useTagSelection";
import { useAppToggleSelection } from "src/hooks/useAppToggleSelection";
import { useSearchAppInstancePickerByAppId } from "src/components/AppPicker/hooks/useSearchAppInstancePickerByAppId";
import { TagFilterDropdown } from "src/components/TagFilterDropdown";
import { useGetScreenSize } from "src/hooks/useGetScreenSize";
import { MediaPickerSelectedTagList } from "../MediaPickerSelectedTagList";
import uniqBy from "lodash/uniqBy";

interface Props {
  isMultipleSelect: boolean | undefined;
  callback: (ids: string[], selectedApps: AppInstance[]) => void;
  spaceId?: UUID;
}

const CanvasPicker = ({ isMultipleSelect, spaceId, callback }: Props) => {
  const context = useAppContext();
  const { isTabletView } = useGetScreenSize();
  const { query, searchString, updateSearchString, clearSearch } =
    useStateSearch();

  const {
    onClickTag,
    selectedTagForQuery,
    hasSelectedTags,
    selectedTags,
    onSetelectTags,
    onClearTags,
  } = useTagSelection();

  const searchCanvases = useSearchAppInstancePickerByAppId(
    query,
    context.canvasAppId,
    spaceId,
  );

  const canvases = useMediaPickerAppInstances({
    appId: context.canvasAppId,
    spaceId: spaceId as UUID,
    tags: selectedTagForQuery,
  });

  const allCanvasInstances = useMediaPickerAppInstances({
    appId: context.canvasAppId,
    spaceId: spaceId as UUID,
  });

  const { onToggleSelection, selectedIds } = useAppToggleSelection({
    appInstances: uniqBy(
      [
        ...allCanvasInstances.appInstances,
        ...searchCanvases.appInstances,
        ...canvases.appInstances,
      ],
      "id",
    ),
    isMultipleSelect,
    callback,
  });

  const handleSearch = useCallback(
    (_: React.SyntheticEvent, data: { value: string }) =>
      updateSearchString(data.value),
    [updateSearchString],
  );

  const renderHeader = () => (
    <div
      className={classNames("content-header", {
        "media-tags": hasSelectedTags,
      })}
    >
      <div className="search-filter-container">
        <Search
          className="search"
          onClear={clearSearch}
          onChange={handleSearch}
          placeholder="Search Canvas"
          showNoResults={false}
          value={searchString}
        />
        <TagFilterDropdown
          className="filter-dropdown"
          selectedTags={selectedTags}
          appId={context.canvasAppId}
          spaceId={context.user.settings.spaceId}
          onSelect={onSetelectTags}
          overlayWhenClick={isTabletView}
        />
        <Button
          onClick={onClearTags}
          className="clear-all-tags-button"
          data-testid="clear-all-tags-button"
          disabled={!hasSelectedTags}
        >
          <Icon name="close" />
          <FormattedMessage
            id="ui_component.common.label.clear_all"
            defaultMessage="Clear All"
          />
        </Button>
      </div>
      {hasSelectedTags && (
        <MediaPickerSelectedTagList
          selectedTags={selectedTags}
          onClearTags={onClearTags}
          onClickTag={onClickTag}
        />
      )}
    </div>
  );

  const content = useMemo(() => {
    if (searchString.length > 0) {
      return (
        <SearchAppPicker
          selectedIds={selectedIds}
          onToggleSelection={onToggleSelection}
          {...searchCanvases}
        />
      );
    }

    return (
      <CanvaseInstances
        selectedIds={selectedIds}
        onToggleSelection={onToggleSelection}
        onClickTag={onClickTag}
        {...canvases}
      />
    );
  }, [
    searchString,
    selectedIds,
    onToggleSelection,
    query,
    context.canvasAppId,
    spaceId,
    isMultipleSelect,
    callback,
    canvases,
    searchCanvases,
  ]);

  return (
    <Styled className="media-content apps">
      {renderHeader()}
      <div className="container">
        <div className="layout-list" id="scrollableDiv">
          {content}
        </div>
        {canvases.appInstances?.length === 0 && !canvases.loading && (
          <EmptyState section="app-picker" cover={false} className="empty">
            <h3>
              <FormattedMessage
                id="canvas.empty.no_content"
                defaultMessage="No Canvas added yet"
              />
            </h3>
            <p>
              <FormattedMessage
                id="canvas.empty.add_content"
                defaultMessage="Make a Canvas to be able to add this content"
              />
            </p>
          </EmptyState>
        )}
      </div>
      {canvases.loading && <LoaderBar />}
    </Styled>
  );
};

export default CanvasPicker;
