import styled from "styled-components";
export const StyledMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  h1 {
    font-size: 1.25em;
    margin: 40px 0 10px;
  }

  .button-options {
    display: inline-flex;
    gap: 10px;
    margin-top: 20px;
  }
`;
