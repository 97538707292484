import { Styled } from "./styles";
import React from "react";
import { ReplaceDeviceModalFooter } from "./components/ReplaceDeviceModalFooter";
import { withData } from "./apollo";
import { ScreenPickerProps } from ".";
import { UUID } from "@screencloud/uuid";
import { ScreenPickerScreenList } from "./components/ScreenPickerScreenList";
import { ScreenPickerSearch } from "./components/ScreenPickerSearch";

export interface SingleScreenPickerProps extends ScreenPickerProps {
  onBack: () => void;
  footerHelpText?: React.ReactNode;
  onConfirm: (screenId: UUID) => void;
  isReplaceDevice?: boolean;
}

const SingleScreenPicker: React.FC<SingleScreenPickerProps> = (
  props: SingleScreenPickerProps,
) => {
  const customContainerRef = React.useRef(null);

  const onSearchInputChange = (
    event: React.SyntheticEvent<any>,
    data: { [key: string]: any },
  ) => {
    props.updateSearchString(data.value);
  };

  const toggleSelect = (id: UUID) => {
    const { updateSelectedScreen, screens: screenList } = props;
    if (id) {
      const screen = screenList.find((item) => item.id === id);
      screen && updateSelectedScreen([screen]);
    }
  };

  const onButtonClick = () => {
    const { onConfirm, selectedScreens } = props;
    onConfirm && onConfirm(selectedScreens[0].id);
  };

  const screenPickerCallback = (checked: boolean, id: string) => {
    toggleSelect(id);
  };

  const realScreens = props.screens.filter(
    (screen) => screen?.deviceProvider !== "fake",
  );

  return (
    <Styled className="sp single-sp">
      <ScreenPickerSearch
        selectedScreens={props.selectedScreens}
        isSingleSelect
        searchString={props.searchString}
        clearSearch={props.clearSearch}
        onSearchInputChange={onSearchInputChange}
      />
      <div
        className="container"
        ref={customContainerRef}
        id="lazy-ref-container"
      >
        <div className="list">
          <ScreenPickerScreenList
            handleAddScreen={props.onBack}
            customContainer={customContainerRef.current}
            screenPickerCallback={screenPickerCallback}
            screens={props.isReplaceDevice ? realScreens : props.screens}
            selectedScreens={props.selectedScreens}
            loadMore={props.loadMore}
            hasNextPage={props.hasNextPage}
            isSearch={props.isSearch}
            loading={props.loading}
            clearSearch={props.clearSearch}
          />
        </div>
      </div>
      <ReplaceDeviceModalFooter {...props} onConfirm={onButtonClick} />
    </Styled>
  );
};

export default withData(SingleScreenPicker);
