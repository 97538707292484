import { Hardwares } from "./Hardwares";
import { StepNumber } from "../StepProgress";
import { TVDisplay } from "./TVDisplay";
import {
  HardwareQuestionId,
  Methods,
  PairingMethodEnum,
  PairingProgressStepTitle,
  Question,
} from "./types";
import devices from "./images/devices.svg";
import laptop from "./images/labtop.svg";
import qrcode from "./images/qrcode.png";
import playerAppStoreContent from "./images/player-app-store-content.svg";
import hardwarePairing from "./images/hardware-pairing-code.svg";

export const questions: Question[] = [
  {
    id: HardwareQuestionId.HARDWARE,
    question:
      "Do you have one of the following hardware options to power your signage?",
    yesText: "Yes, I do!",
    noText: "No",
    backText: "I know how to pair my screen",
    rightComponent: <Hardwares />,
  },
  {
    id: HardwareQuestionId.SCREEN,
    question: "Do you have access to a screen nearby?",
    previousQuestion:
      "Do you have one of the following hardware options to power your signage?",
    yesText: "Yes, I do!",
    noText: "No",
    backText: "Go Back",
    rightComponent: <TVDisplay isSmartTv={false} />,
  },
  {
    id: HardwareQuestionId.SMART_TV,
    question: "Is it a smart TV?",
    previousQuestion: "Do you have access to a screen nearby?",
    yesText: "Yes!",
    noText: "No",
    backText: "Go Back",
    rightComponent: <TVDisplay isSmartTv={true} />,
  },
];

export const stepsByMethod: Methods = {
  [PairingMethodEnum.HARDWARE]: [
    {
      index: 0,
      progressStepTitle: PairingProgressStepTitle.HARDWARE,
      title: "Lets get you set up with hardware!",
      description: "Connect your device to your screen and power it on.",
      buttonText: "My Device is Installed",
      visibleCssProperty: {
        transform: "translateX(0) scale(1)",
      },
      beforeVisibleCssProperty: {
        transform: "translateX(500px) scale(1.2)",
      },
      leftElement: (
        <div className="hardware-pairing-image">
          <TVDisplay
            isSmartTv={true}
            styles={{
              position: "absolute",
              top: "-210px",
              right: "-180px",
            }}
          />
          <img
            src={devices}
            style={{
              zIndex: 1,
              position: "absolute",
              top: "40px",
              left: "-195px",
            }}
          />
        </div>
      ),
    },
    {
      index: 1,
      progressStepTitle: PairingProgressStepTitle.DOWNLOAD_PLAYER,
      title: "Get your ScreenCloud player app ready",
      description:
        "On your device, search and install the ScreenCloud Digital Signage Player.",
      extraElement: (
        <div
          style={{
            color: "var(--color-yellow-800, #927400)",
            fontSize: "var(--font-size-sm, 14px)",
            fontWeight: 400,
          }}
        >
          It's available for Amazon, Google, and Chrome platforms.
        </div>
      ),
      buttonText: "App Installed",
      visibleCssProperty: {
        transform: "translateX(0) scale(1)",
      },
      beforeVisibleCssProperty: {
        transform: "translateX(-500px) scale(1.2)",
      },
      leftElement: (
        <div className="download-player-image">
          <TVDisplay
            width={970}
            height={612}
            isSmartTv={false}
            styles={{
              position: "absolute",
              top: "-300px",
              left: "-200px",
            }}
            content={
              <div
                style={{
                  position: "absolute",
                  top: "-12px",
                  right: "10px",
                }}
              >
                <img
                  src={playerAppStoreContent}
                  style={{
                    width: "950px",
                    height: "592px",
                  }}
                />
              </div>
            }
          />
        </div>
      ),
    },
    {
      index: 2,
      progressStepTitle: PairingProgressStepTitle.PAIR_DEVICE,
      title: "Your Screen Pairing Code",
      description:
        "Once you’ve got your Player app installed and running, you’ll need be given a 6-character pairing code. Enter this to pair your screen with ScreenCloud.",
      buttonText: "Continue to Pairing",
      visibleCssProperty: {
        transform: "translateX(-50px) scale(1)",
      },
      beforeVisibleCssProperty: {
        transform: "translateX(0) scale(1)",
      },
      leftElement: (
        <div className="pairing-code-image">
          <TVDisplay
            width={970}
            height={612}
            isSmartTv={false}
            styles={{
              position: "absolute",
              top: "-300px",
              left: "-155px",
            }}
            className="pairing-tv-display-container"
            content={
              <div
                style={{
                  position: "absolute",
                  right: "279px",
                  top: "58px",
                }}
              >
                <img
                  src={hardwarePairing}
                  style={{
                    width: "874px",
                    height: "459px",
                  }}
                />
              </div>
            }
          />
          <img
            className="laptop-image"
            src={laptop}
            style={{
              width: "386px",
              height: "258px",
              position: "absolute",
              left: "-25px",
              top: "45px",
              zIndex: 1,
            }}
          />
          <img
            className="laptop-image"
            src={hardwarePairing}
            style={{
              width: "346px",
              height: "217px",
              position: "absolute",
              left: "37px",
              top: "56px",
              zIndex: 1,
            }}
          />
        </div>
      ),
    },
  ],
  [PairingMethodEnum.SMART_TV]: [
    {
      index: 0,
      progressStepTitle: PairingProgressStepTitle.DOWNLOAD_PLAYER,
      title: "Get set up on your Smart TV!",
      description:
        "On your Smart TV's app store, search and install the ScreenCloud Digital Signage Player.",
      buttonText: "App Installed",
      leftElement: (
        <div className="download-player-image">
          <TVDisplay
            width={970}
            height={612}
            isSmartTv={false}
            styles={{
              position: "absolute",
              top: "-300px",
              left: "-200px",
            }}
            content={
              <div
                style={{
                  position: "absolute",
                  top: "-12px",
                  right: "10px",
                }}
              >
                <img
                  src={playerAppStoreContent}
                  style={{
                    width: "950px",
                    height: "592px",
                  }}
                />
              </div>
            }
          />
        </div>
      ),
    },
    {
      index: 1,
      progressStepTitle: PairingProgressStepTitle.PAIR_DEVICE,
      title: "Your Screen Pairing Code",
      description:
        "Once you’ve got your Player app installed and running, you’ll need be given a 6-character pairing code. Enter this to pair your screen with ScreenCloud.",
      buttonText: "Continue to Pairing",
      leftElement: (
        <div className="pairing-code-image">
          <TVDisplay
            width={768}
            height={484}
            isSmartTv={false}
            content={
              <div
                style={{
                  position: "absolute",
                  right: "185px",
                  top: "12px",
                }}
              >
                <img
                  src={hardwarePairing}
                  style={{
                    width: "699px",
                    height: "425px",
                  }}
                />
              </div>
            }
            styles={{
              position: "absolute",
              top: "-300px",
              left: "-200px",
            }}
          />
          <img
            src={laptop}
            style={{
              width: "386px",
              height: "258px",
              position: "absolute",
              left: "-75px",
              top: "-10px",
              zIndex: 1,
            }}
          />
        </div>
      ),
    },
  ],
  [PairingMethodEnum.MOBILE]: [
    {
      index: 0,
      title:
        "You can use your mobile to practice powering your digital signage.",
      buttonText: "Continue to Pairing",
      extraElement: (
        <div
          style={{
            display: "flex",
            width: "400px",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            gap: "24px",
          }}
        >
          <div style={{ display: "flex", gap: "8px" }}>
            <StepNumber index={0} isBullet />
            <div style={{ maxWidth: "350px" }}>
              Scan the QR code or go to your App Store, search for ‘ScreenCloud
              Player’, tap Install.
            </div>
          </div>
          <div style={{ display: "flex", gap: "8px" }}>
            <StepNumber index={1} isBullet />
            <div style={{ maxWidth: "350px" }}>
              Use the 6-character pairing to add screen to your account.
            </div>
          </div>
        </div>
      ),
      leftElement: (
        <div className="qrcode-image">
          <img
            style={{
              borderRadius: "24px",
              boxShadow: "0px 0px 40px 0px rgba(0, 0, 0, 0.08)",
            }}
            width={300}
            src={qrcode}
          />
        </div>
      ),
    },
  ],
};
