import { UUID } from "@screencloud/uuid";
import { renderFetchMorePickerButton } from "src/helpers/generalHelper";
import { useLoadMore } from "src/hooks/useLoadMore";
import {
  AvailableAppInstanceFragment,
  SearchAppInstancePickerByAppIdQueryVariables,
  useSearchAppInstancePickerByAppIdQuery,
} from "src/types.g";
import { shouldUseSpecificSpaceId } from "src/utils/shouldUseSpecificSpaceId";
import { getLoadMoreItems } from "src/utils/getLoadmoreItems";
export interface UseSearchAppInstancePickerByAppId {
  appInstances: AvailableAppInstanceFragment[];
  loading: boolean;
  renderFetchMoreButton: React.ReactNode;
}

export function useSearchAppInstancePickerByAppId(
  query: string,
  appId: string,
  spaceId?: UUID,
): UseSearchAppInstancePickerByAppId {
  const variables: SearchAppInstancePickerByAppIdQueryVariables = {
    query,
    first: getLoadMoreItems(),
    endCursor: null,
    appId,
    spaceId: shouldUseSpecificSpaceId(spaceId),
  };

  const { data, fetchMore, loading } = useSearchAppInstancePickerByAppIdQuery({
    fetchPolicy: "cache-and-network",
    variables,
  });

  const appInstanceNodes = data?.searchAppInstanceSpecificAppId?.nodes ?? [];
  const appInstanceTotalCount =
    data?.searchAppInstanceSpecificAppId?.totalCount;
  const isQueryHasNextPage =
    data?.searchAppInstanceSpecificAppId?.pageInfo.hasNextPage;
  const endCursor = data?.searchAppInstanceSpecificAppId?.pageInfo?.endCursor;

  const { isLoading, loadMore } = useLoadMore(fetchMore, endCursor);

  const fetchMoreButton = renderFetchMorePickerButton(
    Number(appInstanceNodes?.length),
    Number(appInstanceTotalCount),
    isLoading,
    Boolean(isQueryHasNextPage) &&
      appInstanceNodes.length! < appInstanceTotalCount!,
    loadMore,
  );

  return {
    appInstances: appInstanceNodes,
    loading,
    renderFetchMoreButton: fetchMoreButton,
  };
}
