import { Button, Theme } from "@screencloud/screencloud-ui-components";
import { isEmpty, isEqual, pick } from "lodash";

import { AppContextState } from "../AppContextProvider";
import { FEATURE_FLAGS_ENUM } from "../constants/featureFlag";
import { useAppContext } from "../hooks/useAppContext";
import { WhiteLabel } from "../types.g";

export type WhiteLabelStyles = Pick<
  Partial<WhiteLabel>,
  "bgColor" | "textColor" | "activeItemColor" | "activeTextColor"
>;

/** calculate contrast color using YIQ, ref: https://24ways.org/2010/calculating-color-contrast */
export const getContrastYIQ = (hexcolor: string): string => {
  hexcolor = hexcolor.replace("#", "");

  const r = parseInt(hexcolor.substr(0, 2), 16);
  const g = parseInt(hexcolor.substr(2, 2), 16);
  const b = parseInt(hexcolor.substr(4, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;

  return yiq >= 128 ? "#000000" : "#ffffff";
};

export const DefaultWhiteLabel: Partial<WhiteLabel> = {
  bgColor: Theme.color.base,
  textColor: Theme.color.white,
  activeTextColor: Theme.color.base,
  activeItemColor: Theme.color.yellow,
};

/** fallback support for changed white_label structure */
export const isUsingDeprecatedWhiteLabel = (
  context: AppContextState,
): boolean => {
  return isEmpty(context.whiteLabel);
};

export const getWhiteLabelGlyph = (context: AppContextState): string => {
  const glyph = isUsingDeprecatedWhiteLabel(context)
    ? context.currentOrg?.preferences?.white_label?.glyph
    : context.whiteLabel?.fileByGlyphFileId?.source;

  return glyph ?? "";
};

export const getWhiteLabelStyles = (
  context: AppContextState,
): WhiteLabelStyles | null => {
  if (!context.shouldShowFeature(FEATURE_FLAGS_ENUM.WHITE_LABEL)) {
    return null;
  }
  const whiteLabel = isUsingDeprecatedWhiteLabel(context)
    ? context.currentOrg?.preferences?.white_label
    : context.whiteLabel;

  const whiteLabelStyles = isEmpty(whiteLabel) ? DefaultWhiteLabel : whiteLabel;

  return pick(whiteLabelStyles, [
    "bgColor",
    "textColor",
    "activeItemColor",
    "activeTextColor",
  ]);
};

export const getWhiteLabelOrganizationName = (
  context: AppContextState,
): string => {
  const organizationName = isUsingDeprecatedWhiteLabel(context)
    ? context.currentOrg?.preferences?.white_label?.wordmark
    : context.currentOrg?.name;

  return organizationName ?? context.currentOrg?.name ?? "";
};

/**
 * This function returns reversed of white label between active and inactive
 *
 * returns null if it is the default white label (nothing to reverse)
 *
 * this is temporary fix for some specific white label buttons
 */
export const getReversedWhiteLabelStyles = (
  context: AppContextState,
): WhiteLabelStyles | null => {
  const whiteLabel = getWhiteLabelStyles(context);

  if (isEqual(whiteLabel, DefaultWhiteLabel)) {
    // * NOTE: we will not apply this special white label to the default
    return null;
  }

  const reversedWhiteLabelStyles: WhiteLabelStyles = {
    bgColor: whiteLabel?.activeItemColor,
    textColor: whiteLabel?.activeTextColor,
    activeItemColor: whiteLabel?.bgColor,
    activeTextColor: whiteLabel?.textColor,
  };
  return reversedWhiteLabelStyles;
};

// TODO: React.FC<should be ExtendButtonProps (from exported ui-components)> instead of any
export const PrimaryButton: React.FC<any> = (props) => {
  const context = useAppContext();
  const reversedWhiteLabelStyles =
    context?.shouldShowFeature?.(FEATURE_FLAGS_ENUM.WHITE_LABEL) &&
    getReversedWhiteLabelStyles(context);
  return (
    <Button
      className="primary"
      {...reversedWhiteLabelStyles}
      {...props}
      primary
    />
  );
};
