import { useCallback } from "react";
import { appConfig } from "src/appConfig";
import AddScreen from "src/components/AddScreen";
import { useAppContext } from "src/hooks/useAppContext";
import { useScreenList } from "src/hooks/useScreenList";
import {
  PairScreenMutationVariables,
  Screen,
  ScreenListDocument,
  useGetPrimaryScreenGroupQuery,
  usePairScreenMutation,
} from "src/types.g";
import { useUpdateScreen } from "./useUpdateScreen";

export function useScreenPairing() {
  const context = useAppContext();
  const variables = { spaceId: context.currentSpace?.id };
  const { data } = useGetPrimaryScreenGroupQuery({
    variables,
    fetchPolicy: "cache-first",
    skip: !context.currentSpace?.id || !appConfig.billingEnabled,
  });
  const { data: screenListData } = useScreenList();

  const { updateScreen } = useUpdateScreen({
    hasSelectedTags: false,
    selectedTagForQuery: [],
  });
  const [pairScreen] = usePairScreenMutation();

  const screenPairing = (pairingCode: string, isTrialMode: boolean) => {
    if (
      data?.allScreenGroups?.nodes &&
      data?.allScreenGroups?.nodes.length > 0
    ) {
      const defaultGroupId = data.allScreenGroups.nodes[0].id;
      const screenData: PairScreenMutationVariables = {
        input: {
          pairingCode,
          preview: isTrialMode,
          screenGroupId: defaultGroupId,
          spaceId: context.currentSpace?.id,
        },
      };

      return pairScreen({
        refetchQueries: [
          {
            query: ScreenListDocument,
            variables,
          },
        ],
        variables: screenData,
      });
    }
    return;
  };

  const onTrialModeChange = useCallback(
    async (screenId: string, isTrialModeChange: boolean) => {
      const getScreenById = (screenId: string): Screen | null | undefined => {
        const screen = screenListData?.spaceById?.screensBySpaceId?.nodes?.find(
          (screen) => screen?.id === screenId,
        );
        return screen as Screen;
      };
      const screen = getScreenById(screenId);
      if (!screen) {
        throw new Error("screen is falsy");
      }

      const screenData = { ...screen };
      screenData.preview = isTrialModeChange;

      const screenInput = {
        preview: isTrialModeChange,
      };

      // Update screen with new trial mode
      await updateScreen?.(screenId, screenData, screenInput);
    },
    [context, updateScreen],
  );

  const openScreenPairing = useCallback(
    (defaultPairingCode: string = "") => {
      context.modal.openModal(
        <AddScreen
          onTrialModeChange={onTrialModeChange}
          defaultPairingCode={defaultPairingCode}
        />,
        "New Screen",
      );
    },
    [onTrialModeChange, context.modal],
  );

  return { screenPairing, openScreenPairing };
}
