import { useEffect, useState } from "react";
import { updateAndToggleSelectedItems } from "src/helpers/updateAndToggleSelectedItemsHelper";
import { AppInstance, AvailableAppInstanceFragment } from "src/types.g";
import uniqBy from "lodash/uniqBy";
interface UseAppToggleSelectionProps {
  isMultipleSelect?: boolean;
  appInstances: AvailableAppInstanceFragment[] | undefined;
  callback: (ids: string[], selectedApps: AppInstance[]) => void;
}

export const getSelectedApps = ({
  appInstances,
  selectedArray,
}: {
  appInstances: AvailableAppInstanceFragment[];
  selectedArray: string[];
}): AvailableAppInstanceFragment[] => {
  return selectedArray.reduce(
    (allSelectedApps: AvailableAppInstanceFragment[], currentId) => {
      const appInstance = appInstances.find(
        (instance) => instance.id === currentId,
      );
      return appInstance ? [...allSelectedApps, appInstance] : allSelectedApps;
    },
    [],
  );
};

export const useAppToggleSelection = ({
  isMultipleSelect,
  appInstances,
  callback,
}: UseAppToggleSelectionProps) => {
  const [selectedApps, setSelectedApps] = useState<
    AvailableAppInstanceFragment[]
  >([]);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const [allAppInstances, setAllAppInstances] = useState<
    AvailableAppInstanceFragment[]
  >([]);

  useEffect(() => {
    if (appInstances) {
      setAllAppInstances((prevInstances) => {
        const combined = [...prevInstances, ...appInstances];
        const unique = uniqBy(combined, "id");
        // Only update if we actually have new items
        return unique.length === prevInstances.length ? prevInstances : unique;
      });
    }
  }, [appInstances]);

  const onToggleSelection = (app: AvailableAppInstanceFragment) => {
    if (!appInstances) return;

    const { selectedArray } = updateAndToggleSelectedItems(
      app.id,
      app,
      selectedIds,
      selectedApps,
      isMultipleSelect,
    );

    setSelectedIds(selectedArray);

    const newSelectedApps = getSelectedApps({
      selectedArray,
      appInstances: allAppInstances,
    });

    setSelectedApps(newSelectedApps);
    callback(selectedArray, newSelectedApps as AppInstance[]);
  };

  return {
    selectedApps,
    selectedIds,
    onToggleSelection,
  };
};
