import { Icon } from "@screencloud/screencloud-ui-components";
import { Styled } from "./styles";
import scosDevice from "../img/scos-device.png";
import { TrialistEmptyScreenSection } from "../TrialistEmptyScreenSection";
import { FormattedMessage } from "react-intl";
import { openScosStore } from "src/components/ScosStore";
import { useAppContext } from "../../../../src/hooks/useAppContext";
import { isTrialist } from "../../../pages/Welcome/utils";

const EmptyScreenSection = (): JSX.Element => {
  const context = useAppContext();
  const handleOnCardClick = (url: string) => {
    window.open(url, "_blank");
  };

  return (
    <Styled className="empty-screen-section">
      {isTrialist(context) ? (
        <TrialistEmptyScreenSection />
      ) : (
        <>
          <div className="empty-screen-card watch-video-card">
            <span className="wistia_embed wistia_async_p40hmtg6wx popover=true popoverAnimateThumbnail=true" />
            <div className="card-heading">
              <h4>
                <FormattedMessage
                  id="common.watch_video"
                  defaultMessage="Watch Video"
                />
              </h4>
              <p>
                <FormattedMessage
                  id="ui_component.screen_list_empty.watch_video_card_description"
                  defaultMessage="Learn more about your screen and devices"
                />
              </p>
            </div>
          </div>
          <div
            className="empty-screen-card help-center-card"
            onClick={() => handleOnCardClick("https://help.screencloud.com/s/")}
          >
            <Icon name="help" />
            <div className="card-heading">
              <h4>
                <FormattedMessage
                  id="ui_component.screen_list_empty.help_center_card_title"
                  defaultMessage="Get Started Guide"
                />
              </h4>
              <p>
                <FormattedMessage
                  id="ui_component.screen_list_empty.help_center_card_description"
                  defaultMessage="Find out how to get the most out of your screens"
                />
              </p>
            </div>
            <span className="card-link">
              <FormattedMessage
                id="ui_component.screen_list_empty.help_center_card_menu"
                defaultMessage="Help Center"
              />
              <Icon name="arrow-right-circle" />
            </span>
          </div>
          <div
            className="empty-screen-card scos-card"
            onClick={() =>
              openScosStore({ context, isFromDeviceInformationModal: false })
            }
          >
            <img className="scos-device-img" src={scosDevice} />
            <div className="card-heading">
              <h4 className="card-heading title">
                <FormattedMessage
                  id="ui_component.screen_list_empty.scos_card_title"
                  defaultMessage="Still looking for a device?"
                />
              </h4>
              <p className="card-description">
                <FormattedMessage
                  id="ui_component.screen_list_empty.scos_card_description"
                  defaultMessage="Check out our ScreenCloud OS devices"
                />
              </p>
            </div>
            <span className="card-link">
              <FormattedMessage
                id="ui_component.screen_list_empty.scos_card_menu"
                defaultMessage="ScreenCloud Store"
              />
              <Icon name="arrow-right-circle" />
            </span>
          </div>
        </>
      )}
    </Styled>
  );
};

export default EmptyScreenSection;
