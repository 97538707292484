import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

export const NewTrialEmptyScreenSectionStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${Theme.break.small}) {
    .purple-card {
      display: none;
    }
    gap: 30px;
    height: 100%;
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.div`
  color: #000;
  text-align: center;
  font-size: var(--font-size-lg, 20px);
  font-style: normal;
  font-weight: 400;
  line-height: var(--font-line-height-lg, 28px);
`;
