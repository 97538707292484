import { FC, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import {
  VideoModalContainer,
  TitleContainer,
  Title,
  Subtitle,
  VideoContainer,
  CloseButton,
  WistiaWrapper,
  WistiaEmbed,
  WistiaSwatch,
  SwatchImage,
} from "src/components/EmptyState/NewTrialEmptyScreenSection/components/VideoModal/styles";

interface VideoModalProps {
  onClose: () => void;
}

export const VideoModal: FC<VideoModalProps> = ({ onClose }) => {
  useEffect(() => {
    // Load Wistia script if it's not already loaded
    if (!document.querySelector('script[src*="wistia.com"]')) {
      const script = document.createElement("script");
      script.src = "//fast.wistia.com/assets/external/E-v1.js";
      script.async = true;
      document.body.appendChild(script);
    }
  }, []);

  const handleClose = () => {
    if ((window as any)._wq) {
      (window as any)._wq.push({
        id: "p40hmtg6wx",
        onReady: (video: any) => {
          video.pause();
        },
      });
    }
    onClose();
  };

  return (
    <VideoModalContainer>
      <TitleContainer>
        <Title>
          <FormattedMessage
            id="new-trial-screen-empty-state.video-modal.title"
            defaultMessage="Choosing the right hardware & screen set up"
          />
        </Title>
        <Subtitle>
          <FormattedMessage
            id="new-trial-screen-empty-state.video-modal.subtitle"
            defaultMessage="Tom will guide you through the various hardware options on the market, as well as the right screens to use. "
          />
        </Subtitle>
      </TitleContainer>
      <VideoContainer>
        <div className="wistia_responsive_padding">
          <WistiaWrapper className="wistia_responsive_wrapper">
            <WistiaEmbed className="wistia_embed wistia_async_p40hmtg6wx videoFoam=true autoPlay=true">
              <WistiaSwatch className="wistia_swatch">
                <SwatchImage
                  src="https://fast.wistia.com/embed/medias/p40hmtg6wx/swatch"
                  alt=""
                  aria-hidden="true"
                />
              </WistiaSwatch>
            </WistiaEmbed>
          </WistiaWrapper>
        </div>
      </VideoContainer>

      <CloseButton onClick={handleClose}>
        <FormattedMessage
          id="new-trial-screen-empty-state.video-modal.close"
          defaultMessage="Close"
        />
      </CloseButton>
    </VideoModalContainer>
  );
};
