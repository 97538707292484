import { isShowInSearchResult } from "src/helpers/folderHelper";
import { renderFetchMorePickerButton } from "src/helpers/generalHelper";
import { useLoadMore } from "src/hooks/useLoadMore";
import {
  File,
  Folder,
  useSearchFilePickerForOrgWideQuery,
  useSearchFolderPickerForOrgWideQuery,
} from "src/types.g";
import { getLoadMoreItems } from "src/utils/getLoadmoreItems";

function useSearchFoldersPicker(query: string) {
  const { data, fetchMore, loading } = useSearchFolderPickerForOrgWideQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      query,
      first: getLoadMoreItems(),
      endCursor: null,
    },
    skip: !query,
  });

  return {
    folders: (data?.searchFolder?.nodes ?? []).filter(
      isShowInSearchResult,
    ) as Folder[],
    searchFoldersTotalCount: data?.searchFolder?.totalCount,
    searchFoldersEndCursor: data?.searchFolder?.pageInfo.endCursor,
    searchFoldersFetchMore: fetchMore,
    isSearchFoldersHasNextPage: data?.searchFolder?.pageInfo.hasNextPage,
    isSearchFoldersLoading: loading,
  };
}

function useSearchFilesPicker(
  query: string,
  searchFoldersHasNextPage: boolean,
) {
  const { data, fetchMore, loading } = useSearchFilePickerForOrgWideQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      query: query ?? "",
      first: getLoadMoreItems(),
      endCursor: null,
    },
    skip: !query,
  });

  return {
    files: searchFoldersHasNextPage
      ? ([] as File[])
      : ((data?.searchFile?.nodes ?? []) as File[]),
    searchFilesTotalCount: data?.searchFile?.totalCount,
    searchFilesEndCursor: data?.searchFile?.pageInfo.endCursor,
    searchFilesFetchMore: fetchMore,
    isSearchFilesHasNextPage: data?.searchFile?.pageInfo.hasNextPage,
    isSearchFilesLoading: loading,
  };
}

export function useSearchMediaPickerForOrgWide(query?: string) {
  const queryString = query ?? "";

  const {
    folders,
    searchFoldersTotalCount,
    isSearchFoldersHasNextPage,
    searchFoldersEndCursor,
    searchFoldersFetchMore,
    isSearchFoldersLoading,
  } = useSearchFoldersPicker(queryString);
  const {
    files,
    searchFilesTotalCount,
    isSearchFilesHasNextPage,
    searchFilesEndCursor,
    searchFilesFetchMore,
    isSearchFilesLoading,
  } = useSearchFilesPicker(queryString, Boolean(isSearchFoldersHasNextPage));

  let loadMore: () => void;
  let isLoading: boolean;
  if (isSearchFoldersHasNextPage) {
    ({ isLoading, loadMore } = useLoadMore(
      searchFoldersFetchMore,
      searchFoldersEndCursor,
    ));
  } else {
    ({ isLoading, loadMore } = useLoadMore(
      searchFilesFetchMore,
      searchFilesEndCursor,
    ));
  }

  const renderFetchMoreButton = renderFetchMorePickerButton(
    Number(folders.length + files.length),
    Number(searchFoldersTotalCount! + searchFilesTotalCount!),
    isLoading,
    Boolean(isSearchFoldersHasNextPage || isSearchFilesHasNextPage),
    loadMore,
  );

  return {
    mediaFoldersSearchResults: folders,
    mediaFilesSearchResults: files,
    isSearchMediaLoading: isSearchFoldersLoading || isSearchFilesLoading,
    renderFetchMoreButton,
  };
}
