import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

export const CenterLoaderStyled = styled.div`
  .ui.loader {
    position: static;
    width: 0px;
  }

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  background: var(--color-grey20);
`;

export const ScreenshotModalViewStyled = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 600px;
  border-bottom-left-radius: var(--radius-md, 8px);
  border-bottom-right-radius: var(--radius-md, 8px);
  overflow: auto;

  .center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .screenshot-viewer-container {
    flex-grow: 1;
    display: grid;
    grid-template-columns: 2fr 1fr;
    overflow: hidden;

    @media only screen and (max-width: ${Theme.break.small}) {
      grid-template-columns: 1fr;
    }
  }
  .screenshot-viewer-container > .screenshot-viewer {
    background: var(--color-grey20);

    display: flex;
    justify-content: center;
    align-items: center;

    border-right: 1px solid var(--color-grey40);
    overflow: hidden;
  }
  .screenshot-viewer-container > .screenshot-viewer.empty {
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .item-padding {
    @media only screen and (max-width: ${Theme.break.small}) {
      padding: 10px 5px 10px 20px;
    }
    padding: 20px 0 20px 20px;
  }
  .screenshot-viewer-container > .screenshot-list {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .screenshot-viewer-container > .screenshot-list > .header {
    font-size: 12px;
    font-weight: 900;
    border-bottom: 1px solid var(--color-grey40);

    span {
      letter-spacing: 1px;
    }
  }

  .y-scrollable {
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scroll-snap-type: y mandatory;
    // Padding to make sure the last item is visible over the action button
    margin-bottom: 64px;
  }
  .y-scrollable::-webkit-scrollbar {
    display: none; /* Chrome, Safari, and Opera */
  }

  .screenshot-item.active:before {
    position: absolute;
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: black;
    left: 5px;
  }
  .screenshot-item.active {
    background: var(--color-grey40);
  }
  .screenshot-item {
    position: relative;
    scroll-snap-align: start;

    @media only screen and (max-width: ${Theme.break.small}) {
      font-size: var(--font-size-sm, 14px);
      grid-template-columns: 1fr 3fr 0.5fr;
    }

    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    gap: 10px;
    align-items: center;

    border-bottom: 1px solid var(--color-grey40);

    cursor: pointer;

    > img {
      object-fit: contain;
      width: 100%;
    }

    :hover {
      background: var(--color-grey30);
    }
  }
  .screenshot-item > .screenshot-info {
    overflow: hidden;

    > div:nth-child(1) {
      font-weight: 700;
    }
    > div:nth-child(2) {
      color: var(--color-grey70);

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .screenshot-footer {
    padding: 10px;
    border-top: 1px solid var(--color-grey40);
  }

  .screenshot-action {
    border-top: 1px solid var(--color-grey40);
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: var(--color-white-100);
    border-bottom-right-radius: var(--radius-lg, 16px);

    > button {
      width: 100%;
      height: 44px;
    }
  }

  .screenshot-preview-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 70px 60px;

    @media only screen and (max-width: ${Theme.break.small}) {
      padding: 20px 10px;
    }

    img {
      flex: 2;

      object-fit: contain;
      overflow: hidden;
      height: 400px;
    }

    .info-container {
      flex: 1;

      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      text-align: center;

      .info > .taken-by {
        font-size: 14px;
        color: var(--color-grey70);

        @media only screen and (max-width: ${Theme.break.small}) {
          margin-bottom: 14px;
        }
      }
    }
  }
`;

export const TakingScreenshotLoadingStyled = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 60px 60px 0 60px;

  justify-content: center;
  align-items: center;
  text-align: center;

  .header {
    font-size: 20px;
    font-weigth: 800;
  }
  .description {
    visibility: hidden;
    color: var(--color-grey70);
    font-size: 16px;
  }
  .description.show {
    visibility: visible;
    opacity: 0;
    animation: fade-in 2s forwards;
  }

  @keyframes fade-in {
    0% {
      display: none;
      opacity: 0;
    }
    100% {
      display: block;
      opacity: 1;
    }
  }

  .ui.loader {
    position: static;
    width: 0px;
  }

  .screenshot-btn {
    display: block;
  }
`;
