import { Button, Theme } from "@screencloud/screencloud-ui-components";
import styled, { css } from "styled-components";
import { HardwareQuestionId } from "../types";

const transition = "all 0.3s";

export const StepContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
`;

export const Left = styled.div<{ isInPairingMode: boolean }>`
  display: flex;
  padding: var(--spacing-xxxl, 56px);
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--spacing-xxl, 40px);
  flex: 1 0 0;
  align-self: stretch;

  @media not all and (max-width: ${Theme.break.small}) {
    ${({ isInPairingMode }) =>
      isInPairingMode
        ? css`
            border-radius: 8px 0 0 8px;
            transform: translateX(100%);
            justify-content: flex-start;
          `
        : css`
            transform: translateX(0);
          `}
  }

  @media (max-width: ${Theme.break.small}) {
    width: 100%;
    ${({ isInPairingMode }) =>
      isInPairingMode
        ? css`
            margin-left: -20px;
            justify-content: flex-start;
          `
        : css`
            justify-content: center;
          `}
  }
`;

export const QuestionTextContainer = styled.div<{ isInPairingMode: boolean }>`
  display: flex;
  position: relative;
  width: 100%;
  ${({ isInPairingMode }) =>
    isInPairingMode
      ? css`
          display: none;
        `
      : css`
          display: flex;
        `}
`;

export const QuestionText = styled.div<{
  currentId: string;
  showingState: "hidden" | "visible" | "blur" | "fly-away";
}>`
  color: #19191b;
  font-size: var(--font-size-xl, 24px);
  font-weight: 900;
  line-height: var(--font-line-height-xl, 36px);
  transition: ${transition};
  transform: translateY(70px);
  opacity: 0;

  position: absolute;
  ${({ showingState, currentId }) => {
    const baseStyles = {
      hidden: css`
        opacity: 0;
        transform: translateY(-70px);
      `,
      visible: css`
        opacity: 1;
        transform: translateY(-70px);
      `,
      blur: css`
        opacity: 0.2;
        transform: translateY(-200px);
      `,
      "fly-away": css`
        opacity: 0;
        transform: translateY(-400px);
      `,
    };

    const specialCases = {
      visible: {
        [HardwareQuestionId.HARDWARE]: css`
          transform: translateY(-115px);
        `,
        [HardwareQuestionId.SMART_TV]: css`
          transform: translateY(-45px);
        `,
      },
      blur: {
        [HardwareQuestionId.SCREEN]: css`
          transform: translateY(-155px);
        `,
      },
    };

    const baseStyle = baseStyles[showingState] || "";
    const specialStyle = specialCases[showingState]?.[currentId] || "";

    return css`
      ${baseStyle}
      ${specialStyle}
    `;
  }};
`;

export const PreviousButton = styled(Button)`
  color: var(--color-yellow-800, #927400) !important;
  padding: 0 !important;
  min-width: 0 !important;
  :hover {
    background-color: transparent !important;
    text-decoration: underline;
  }
  :focus {
    background-color: transparent !important;
  }
`;

export const ButtonContainer = styled.div<{ isInPairingMode: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing-m, 16px);
  ${({ isInPairingMode }) =>
    isInPairingMode
      ? css`
          display: none;
        `
      : css`
          display: flex;
        `}
`;

export const Right = styled.div<{ isInPairingMode: boolean }>`
  display: flex;
  width: 50%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  background: var(--color-gray-50, #f6f6f7);
  border-radius: 0 8px 8px 0;

  transition: transform 1s cubic-bezier(0.2, 0.69, 0.63, 1.23);
  ${({ isInPairingMode }) =>
    isInPairingMode
      ? css`
          border-radius: 8px 0 0 8px;
          transform: translateX(-100%);
        `
      : css`
          transform: translateX(0);
        `}
  ${({ isInPairingMode }) =>
    isInPairingMode &&
    `
    .bg-1 {
      position: absolute;
      top: -206px;
      left: -130px;
      width: 413px;
      height: 413px;
      border-radius: 413px;
      background: #b1a3fe;
      mix-blend-mode: multiply;
      filter: blur(100px);
    }
    .bg-2 {
      position: absolute;
      top: -177px;
      right: -221px;
      width: 484px;
      height: 484px;
      border-radius: 484px;
      background: var(--color-yellow-300, #ffe26f);
      mix-blend-mode: multiply;
      filter: blur(100px);
    }
  `}
  
  @media (max-width: ${Theme.break.small}) {
    display: none;
  }
`;

export const AnimatedContent = styled.div<{
  isShowing: boolean;
  isTVDisplay: boolean;
  isInPairingMode: boolean;
}>`
  position: absolute;
  transition: ${transition};

  ${({ isShowing, isTVDisplay, isInPairingMode }) => {
    if (isInPairingMode) {
      return css`
        display: none;
      `;
    }

    const hiddenPosition = "-700px";
    const visibleTVPosition = "-190px";
    const visibleDefaultPosition = "0";

    let translateX;

    if (!isShowing) {
      translateX = hiddenPosition;
    } else if (isTVDisplay) {
      translateX = visibleTVPosition;
    } else {
      translateX = visibleDefaultPosition;
    }

    return css`
      transform: translateX(${translateX});
    `;
  }}
`;

export const AnimatedPairingImageContent = styled.div<{
  visible: boolean;
  visibleCssProperty?: React.CSSProperties;
  beforeVisibleCssProperty?: React.CSSProperties;
  afterVisibleCssProperty?: React.CSSProperties;
}>`
  opacity: ${(props) => (props.visible ? 1 : 0)};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: ${transition};

  ${({
    visible,
    visibleCssProperty,
    beforeVisibleCssProperty,
    afterVisibleCssProperty,
  }) => {
    let result;
    if (visible) {
      result = visibleCssProperty;
    } else {
      result = beforeVisibleCssProperty;
    }
    return css`
      ${result
        ? Object.entries(result)
            .map(([key, value]) => `${key}: ${value};`)
            .join(" ")
        : ""}
    `;
  }};

  .laptop-image {
    transition: ${transition};
    transform: ${(props) =>
      props.visible ? "translateX(0)" : "translateX(200px)"};
  }
  .pairing-tv-display-container {
    transition: ${transition};
    transform: ${(props) => (props.visible ? "scaleY(0.85)" : "scaleY(1)")};
  }
`;

export const PairingMethodContainer = styled.div<{ isInPairingMode: boolean }>`
  ${({ isInPairingMode }) =>
    isInPairingMode
      ? css`
          display: flex;
        `
      : css`
          display: none;
        `}
`;

export const Content = styled.div``;

export const AnimatedPairingContent = styled.div<{ visible: boolean }>`
  opacity: ${(props) => (props.visible ? 1 : 0)};
  z-index: ${(props) => (props.visible ? 1 : -1)};
  transition: opacity 0.2s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: var(--spacing-xxl, 40px);
  padding: var(--spacing-xxxl, 56px);
`;
