import { appConfig } from "src/appConfig";
import scosDevice from "../../img/scos-device-trialist.png";
import { FormattedMessage } from "react-intl";
import { useAppContext } from "src/hooks/useAppContext";
import { getSCOSPrice } from "src/pages/Welcome/utils";

const ScosLogo = (): JSX.Element => (
  <span className="scos-logo">
    {" "}
    ScreenCloud<span className="scos-logo os-text">OS</span>
  </span>
);

export const ScosCard = (): JSX.Element => {
  const storeUrl = appConfig.scosStoreUrl;
  const context = useAppContext();
  const { fullPrice, discountPrice, currencySymbol } = getSCOSPrice(
    context.currentOrg?.preferences.settings.country_code,
  );
  const handleOnCardClick = (url: string) => {
    window.open(url, "_blank");
  };
  return (
    <div
      data-testid="trialist-empty-screen-scos-card"
      className="trialist-empty-screen-card scos-card"
      onClick={() => handleOnCardClick(storeUrl)}
    >
      <div className="card-image-container">
        <img className="scos-device-img" src={scosDevice} />
      </div>
      <div className="card-heading">
        <h4 className="title">
          <FormattedMessage
            id="ui_component.screen_list_empty.trialist.scos_card_title"
            defaultMessage="Try {device}"
            values={{ device: <ScosLogo /> }}
          />
        </h4>

        <p className="scos-price-offer">
          <FormattedMessage
            id="ui_component.screen_list_empty.trialist.scos_card_price_offer"
            defaultMessage="{fullPrice} - Exclusive offer: {discount}"
            values={{
              fullPrice: (
                <span className="full-price">
                  {currencySymbol}
                  {fullPrice}
                  <span className="full-price crossed" />
                </span>
              ),
              discount: `${currencySymbol}${discountPrice}`,
            }}
          />
        </p>
        <p className="card-description">
          <FormattedMessage
            id="ui_component.screen_list_empty.trialist.scos_card_description"
            defaultMessage="Say goodbye to hardware headaches forever – the perfect pairing for your digital signage."
          />
        </p>
      </div>
    </div>
  );
};
