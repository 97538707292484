import styled from "styled-components";
import { Button } from "@screencloud/screencloud-ui-components";

export const VideoModalContainer = styled.div`
  display: flex;
  padding: var(--spacing-xl, 24px);
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  gap: var(--spacing-lg, 16px);
  height: 100%;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  flex-direction: column;
`;

export const Title = styled.div`
  color: #000;
  font-family: var(--font-family-studio, Lato);
  font-size: var(--font-size-lg, 20px);
  font-style: normal;
  font-weight: 900;
  line-height: var(--font-line-height-lg, 28px);
`;

export const Subtitle = styled.div`
  color: #000;
  font-family: var(--font-family-studio, Lato);
  font-size: var(--font-size-md, 16px);
  font-style: normal;
  font-weight: 400;
  line-height: var(--font-line-height-md, 24px);
`;

export const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  padding: 4px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: var(--color-black-100, #000);
`;

export const CloseButton = styled(Button)`
  width: 200px;
`;

export const WistiaWrapper = styled.div`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

export const WistiaEmbed = styled.div`
  height: 100%;
  position: relative;
  width: 100%;
`;

export const WistiaSwatch = styled.div`
  height: 100%;
  left: 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  transition: opacity 200ms;
  width: 100%;
`;

export const SwatchImage = styled.img`
  filter: blur(5px);
  height: 100%;
  object-fit: contain;
  width: 100%;
`;
