import { Styled } from "./styles";
import playIcon from "../img/play-tutorial.svg";
import { FormattedMessage } from "react-intl";
import { useCallback, useEffect, MouseEvent } from "react";
import { PixiCard } from "./PixiCard";
import { ScosCard } from "./ScosCard";
import { isPixiTrialist } from "src/pages/Welcome/utils";
import { useAppContext } from "src/hooks/useAppContext";

export const TrialistEmptyScreenSection = (): JSX.Element => {
  const context = useAppContext();
  const handleVideoClick = useCallback((e: MouseEvent) => {
    e.preventDefault();
    const wistiaEmbed = document.querySelector("#screen-video-tutorial");

    if (wistiaEmbed) {
      // Force reset the Wistia player state
      if ((window as any)._wq && (window as any)._wq.push) {
        (window as any)._wq.push({
          id: "_all",
          onReady(video: any) {
            // Reset the player first
            video.pause();
            video.time(0);

            // Remove any existing popover
            const existingPopover = document.querySelector(".wistia_popover");
            if (existingPopover) {
              existingPopover.remove();
            }

            // Show embed and play
            (wistiaEmbed as HTMLElement).style.display = "block";
            video.play();
          },
        });
      }
    }
  }, []);

  // Add cleanup function for when video is closed
  useEffect(() => {
    const handleWistiaClose = () => {
      const wistiaEmbed = document.querySelector("#screen-video-tutorial");
      if (wistiaEmbed) {
        (wistiaEmbed as HTMLElement).style.display = "none";
      }
    };
    // Listen for Wistia popover close event
    if ((window as any)._wq) {
      (window as any)._wq.push({
        id: "_all",
        onPopoverHide: handleWistiaClose,
      });
    }

    return () => {
      // Cleanup if needed
      const wistiaEmbed = document.querySelector(".wistia_embed");
      if (wistiaEmbed) {
        (wistiaEmbed as HTMLElement).style.display = "none";
      }
    };
  }, []);

  return (
    <Styled
      className="trialist-empty-screen-card"
      data-testid="trialist-empty-screen-card"
    >
      {isPixiTrialist(context) ? <PixiCard /> : <ScosCard />}
      <div className="trialist-empty-screen-card watch-video-tutorial-card">
        <div className="card-image-container">
          <span
            id="screen-video-tutorial"
            className="wistia_embed wistia_async_p40hmtg6wx popover=true popoverAnimateThumbnail=true"
          />
        </div>
        <div className="card-heading" onClick={handleVideoClick}>
          <h4 className="title">
            <FormattedMessage
              id="ui_component.screen_list_empty.trialist.watch_tutorial_title"
              defaultMessage="{icon} Watch video tutorial"
              values={{
                icon: (
                  <img
                    src={playIcon}
                    alt="play"
                    className="inline-icon play-icon"
                  />
                ),
              }}
            />
          </h4>
          <p>
            <FormattedMessage
              id="ui_component.screen_list_empty.trialist.watch_tutorial_description"
              defaultMessage="Learn more about your screens capabilities with the right hardware"
            />
          </p>
        </div>
      </div>
    </Styled>
  );
};
