import { useState } from "react";
import { AxiosError } from "axios";

import { getClient } from "src/billinglatest/clients/service.client";
import { useAppContext } from "src/hooks/useAppContext";

import { CanPairScreenResponseCode, HookProps } from "src/billinglatest/types";

export interface UseCanPairScreen {
  set: (input: CanPairScreenResponseCode) => void;
  get: () => CanPairScreenResponseCode | undefined;
  fetch: () => Promise<void>;
  refetch: () => Promise<void>;
  getLatest: () => Promise<CanPairScreenResponseCode | undefined>;
}

export function useCanPairScreen(props?: HookProps): UseCanPairScreen {
  const context = useAppContext();

  const [_get, _set] = useState<CanPairScreenResponseCode>();

  /**
   * Essential Methods.
   *
   * This section includes essential methods that form the core of the hook's functionality. These methods are crucial
   * and are unlikely to require any updates in the future.
   */

  /**
   * Set the response code that allows a user to pair a screen.
   * Any response code other than SUCCESS means the user should be blocked from pairing a screen.
   */
  const set = (input: CanPairScreenResponseCode): void => {
    return _set(input);
  };

  /**
   * Return the code to indicate if a user can pair a screen or not and why.
   */
  const get = (): CanPairScreenResponseCode | undefined => {
    return _get;
  };

  /**
   * Fetches a response code to know if a user can pair a screen from the billing service.
   *
   * @remarks
   * Any response code other than SUCCESS means something is wrong and the user should be
   * blocked from pairing a screen. Some scenarios will return why the user is blocked as the response code.
   */
  const fetch = async (): Promise<void> => {
    const start = Date.now();
    try {
      await getClient()?.screens.canPairScreen(context.currentSpace?.id);
      console.log(`useCanPairScreen: fetch() took ${Date.now() - start} ms`);
      set("SUCCESS");
    } catch (error) {
      if (error instanceof AxiosError) {
        console.error("Axios fetch failed for canPairScreen: ", error.response?.data.message);
      } else if (error instanceof Error) {
        console.error("Fetch failed for canPairScreen error: ", error.message);
        set(error.message as CanPairScreenResponseCode);
      } else {
        console.error("Fetch failed for canPairScreen", error);
      }
    }
  };

  /**
   * A shortcut for the `fetch` function.
   *
   * @remarks
   * This function acts as a convenient alternative to the fetch function. Calling refetch() produces the same outcome
   * as calling fetch()
   */
  const refetch = async (): Promise<void> => {
    return fetch();
  };

  /**
   * Synchronously gets the canPairscreen response code.
   *
   * @returns A promise resolving to the canPairScreen response code.
   */
  const getLatest = async () => {
    await fetch();
    return Promise.resolve(_get);
  };

  /**
   * Return the hook
   */
  return {
    set,
    get,
    fetch,
    refetch,
    getLatest,
  };
}
