import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

interface StyledProps {
  overlayWhenClick?: boolean;
}

export const Styled = styled.div<StyledProps>`
  display: flex;
  position: relative;
  min-width: 150px;

  @media screen and (max-width: ${Theme.break.mediumAlt}) {
    min-width: unset;
  }

  ${({ overlayWhenClick }) =>
    overlayWhenClick &&
    `
      &::after {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.5);
        z-index: 10;
        opacity: 0;
        visibility: hidden;
        transition:
          opacity 0.2s ease,
          visibility 0.2s ease;
      }

      &:has(.dropdown-options-container)::after {
        opacity: 1;
        visibility: visible;
      }
    `}

  .tailwind-css-ui-components {
    width: 100%;
  }

  .dropdown-options-container {
    width: 120%;
    z-index: 11; /* Ensure dropdown appears above overlay */

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 5px;
    }

    i[name="checked-circle"] {
      min-width: 16px;
    }

    @media screen and (max-width: ${Theme.break.mediumAlt}) {
      width: calc(100vw - 60px);
      right: 0;
      left: auto;
    }

    @media screen and (max-width: ${Theme.break.small}) {
      width: calc(100vw - 30px);
    }
  }
`;
