import {
  Button,
  Icon,
  LoaderBar,
  Popover,
  Search,
  TagList,
} from "@screencloud/screencloud-ui-components";
import { UUID } from "@screencloud/uuid";
import classNames from "clsx";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import {
  AllPlaylistsInOrgDocument,
  AllPlaylistsInOrgQuery,
  AllPlaylistsInSpaceDocument,
  AllPlaylistsInSpaceQuery,
  Playlist,
} from "src/types.g";
import { isOrgWidePath } from "src/utils/orgWideFeature";
import { SortingActions } from "../../constants/constants";
import { updateAndToggleSelectedItems } from "../../helpers/updateAndToggleSelectedItemsHelper";
import { useAppContext } from "../../hooks/useAppContext";
import EmptyState from "../EmptyState";
import PlaylistItemsPreview from "../Playlist/PlaylistItemsPreview";
import { ApolloProps, withData } from "./apollo";
import SearchPlaylistComponent from "./search";
import { Styled } from "./styles";
export interface PlaylistPickerProps {
  multiple?: boolean;
  allowSelectAll?: boolean;
  callback?: (playlistIds: string[], playlist: Playlist[]) => void;
  excludeShare?: boolean;
  spaceId?: UUID;
}

export interface PlaylistPickerState {
  isOrderByAscending: boolean;
  selectedArray: string[];
  selectedData: Playlist[];
  sortBy: SortingActions;
}

export const renderOrderCaretIcon = (
  isOrderByAscending: boolean,
): JSX.Element => {
  if (isOrderByAscending) {
    return (
      <Icon
        className="caret-order"
        name="caret-down"
        data-testid="caret-down"
      />
    );
  } else {
    return (
      <Icon className="caret-order" name="caret-up" data-testid="caret-up" />
    );
  }
};

export const PlaylistPickerComponent = (
  props: PlaylistPickerProps & ApolloProps,
): JSX.Element => {
  const [isOrderByAscending, setIsOrderByAscending] = useState(true);
  const [sortBy, setSortBy] = useState(SortingActions.SORT_BY_NAME);
  const [selectedArray, setSelectedArray] = useState<string[]>([]);
  const [selectedData, setSelectedData] = useState<any[]>([]);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const context = useAppContext();
  const {
    allowSelectAll,
    callback,
    multiple,
    searchString,
    query: searchQuery,
    updateSearchString,
    isSearch,
    clearSearch,
    playlistData,
  } = props;
  const currentSpaceId = context?.currentSpace?.id;
  const { playlists, renderFetchMoreButton, loading, isLoading } = playlistData;
  const isPlaylistSelected = (id: UUID): boolean => selectedArray.includes(id);

  const playlistPickerCallback = (id: string, playlist: Playlist) => {
    const result = updateAndToggleSelectedItems(
      id,
      playlist,
      selectedArray,
      selectedData,
      multiple,
    );
    if (callback) {
      callback(result.selectedArray, result.selectedData);
    }
    setSelectedArray(result.selectedArray);
    setSelectedData(result.selectedData);
  };

  const onSearchSelectAll = (ids: string[], selectedPlaylists: Playlist[]) => {
    setSelectedArray(ids);
    setSelectedData(selectedPlaylists);
  };

  const toggleSortAndOrderMediaItem = (action: SortingActions) => {
    if (sortBy === action) {
      setIsOrderByAscending(!isOrderByAscending);
    } else {
      setIsOrderByAscending(true);
    }
    setSortBy(action);
  };

  const onSelectAllChange = async () => {
    if (selectedArray.length <= 0) {
      let playlists = [] as Playlist[];
      if (!isSearch) {
        if (isOrgWidePath()) {
          const { data } = await props.client!.query<AllPlaylistsInOrgQuery>({
            fetchPolicy: "network-only",
            query: AllPlaylistsInOrgDocument,
            variables: {
              orgId: context.currentSpace?.id,
            },
          });
          playlists = data.orgById?.playlistsByOrgId?.nodes as Playlist[];
        } else {
          const { data } = await props.client!.query<AllPlaylistsInSpaceQuery>({
            fetchPolicy: "network-only",
            query: AllPlaylistsInSpaceDocument,
            variables: {
              spaceId: context.currentSpace?.id,
            },
          });
          playlists = data.spaceById?.publishedPlaylistsBySpaceId
            ?.nodes as Playlist[];
        }
      }
      const selectedArray = playlists
        ? playlists.map((playlist) => playlist?.id)
        : [];
      const selectedData = playlists as any;

      setSelectedArray(selectedArray);
      setSelectedData(selectedData);
      setIsSelectAll(true);
      if (callback) {
        callback(selectedArray, selectedData);
      }
    } else {
      setSelectedArray([]);
      setSelectedData([]);
      setIsSelectAll(false);
      if (callback) {
        callback([], []);
      }
    }
  };
  return (
    <Styled className="media-content playlist">
      <div className="content-header">
        <Search
          className="search"
          onClear={clearSearch}
          onChange={(e, data) => {
            updateSearchString(data.value);
          }}
          placeholder="Search Playlist"
          showNoResults={false}
          value={searchString ?? ""}
        />
        {allowSelectAll && (
          <div className="search-checkbox">
            <Popover
              trigger={
                <Button icon onClick={onSelectAllChange}>
                  {playlists.length === selectedArray.length &&
                  selectedArray.length > 0 ? (
                    <Icon name="checkbox-checked" />
                  ) : selectedArray.length > 0 ? (
                    <Icon name="checkbox-deselect" />
                  ) : (
                    <Icon name="checkbox-empty" />
                  )}
                </Button>
              }
              content={selectedArray.length > 0 ? "Deselect All" : "Select All"}
              className="checkbox"
              position="top center"
              inverted
            />
          </div>
        )}
      </div>
      <div className="container">
        <div className="media-item-header subheader">
          <div className="media-core">
            <div
              className="media-alpha"
              data-testid="media-alpha"
              onClick={() =>
                toggleSortAndOrderMediaItem(SortingActions.SORT_BY_NAME)
              }
            >
              <FormattedMessage
                id="subheader.label.name"
                defaultMessage="Name"
              />
              {sortBy === SortingActions.SORT_BY_NAME &&
                renderOrderCaretIcon(isOrderByAscending)}
            </div>
            <div className="media-playlist-preview" />
            <div
              className="media-starred"
              onClick={() =>
                toggleSortAndOrderMediaItem(SortingActions.SORT_BY_STARRED)
              }
            >
              <FormattedMessage
                id="subheader.label.starred"
                defaultMessage="Starred"
              />
            </div>
            <div className="media-tags">
              <FormattedMessage
                id="subheader.label.tags"
                defaultMessage="Tags"
              />
            </div>
          </div>
        </div>
        <div
          className="layout-list"
          data-testid="layout-list"
          id="scrollableDiv"
        >
          {isSearch ? (
            <SearchPlaylistComponent
              allowSelectAll={allowSelectAll}
              searchString={searchQuery}
              multiple={multiple}
              callback={callback}
              toggleSelect={playlistPickerCallback}
              selectedArray={selectedArray}
              selectedData={selectedData}
              isSelectAll={isSelectAll}
              spaceId={props.spaceId}
              onSearchSelectAll={onSearchSelectAll}
            />
          ) : (
            playlists.length > 0 && (
              <>
                {playlists.map((playlist, index) => {
                  const currentPlaylist = playlist?.draft || playlist.published;
                  const isPlaylistOwner = currentPlaylist
                    ? currentSpaceId === playlist?.spaceId
                    : true;

                  return (
                    currentPlaylist !== null && (
                      <div
                        className={
                          "media-item readonly " +
                          classNames({
                            selected: isPlaylistSelected(playlist?.id),
                            box: allowSelectAll,
                          })
                        }
                        key={`playlist-${currentPlaylist?.id}`}
                        data-testid={`playlist-item-${index}`}
                        onClick={() =>
                          playlistPickerCallback(
                            playlist?.id,
                            playlist as Playlist,
                          )
                        }
                      >
                        <div className="media-core">
                          <div className="media-alpha">
                            <div className="thumbnail-preview">
                              <div
                                className="wrapper"
                                style={{ backgroundColor: playlist?.color! }}
                              >
                                <Icon name="playlist" />
                              </div>
                            </div>
                            <div className="media-title">
                              <h3>{playlist?.name}</h3>
                              {!isPlaylistOwner ||
                              currentPlaylist?.isPublished ? (
                                <span className="pl-status published">
                                  <FormattedMessage
                                    id="ui_component.common.label.published"
                                    defaultMessage="Published"
                                  />
                                </span>
                              ) : (
                                <span className="pl-status">
                                  <Popover
                                    inverted
                                    position="top center"
                                    content={
                                      <FormattedMessage
                                        id="common.text.draft_content_message"
                                        defaultMessage="Changes have been made. Please publish to play the latest changes on your screens."
                                      />
                                    }
                                    trigger={
                                      <span>
                                        <Icon name="warning" />
                                        <FormattedMessage
                                          id="common.text.draft_saved"
                                          defaultMessage="Draft Saved. Publish required."
                                        />
                                      </span>
                                    }
                                  />
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="media-playlist-preview">
                            <PlaylistItemsPreview
                              playlist={currentPlaylist as Playlist}
                              isInPicker={true}
                              onPreviewClick={() => ""}
                            />
                          </div>
                          <div className="media-starred">
                            <Icon name={playlist?.isFavorite ? "star" : ""} />
                          </div>

                          <div className="media-tags">
                            <TagList taglist={playlist?.tags as string[]} />
                          </div>
                        </div>
                        {allowSelectAll ? (
                          <div
                            data-testid="playlist-check-all"
                            className="playlist-check"
                          >
                            <Button icon borderless>
                              {isPlaylistSelected(playlist?.id) ? (
                                <Icon
                                  data-testid={`checkbox-checked-${index}`}
                                  name="checkbox-checked"
                                />
                              ) : (
                                <Icon
                                  checkbox-checked={`checkbox-empty-${index}`}
                                  name="checkbox-empty"
                                />
                              )}
                            </Button>
                          </div>
                        ) : (
                          <div className="media-checked">
                            <Icon name="checked-circle" />
                          </div>
                        )}
                      </div>
                    )
                  );
                })}
                {renderFetchMoreButton}
              </>
            )
          )}
          {playlists.length === 0 && !loading && (
            <EmptyState section="playlist" className="empty">
              <h3>
                <FormattedMessage
                  id="playlists.empty.empty_text"
                  defaultMessage="No playlist added yet"
                />
              </h3>
              <p>
                <FormattedMessage
                  id="playlists.empty.help_text"
                  defaultMessage="Create a playlist to add to your channel"
                />
              </p>
            </EmptyState>
          )}
        </div>
      </div>
      {isLoading && <LoaderBar />}
    </Styled>
  );
};

export default withData(
  PlaylistPickerComponent,
) as React.ComponentType<PlaylistPickerProps>;
