import { DatetimeFormat } from "@screencloud/screencloud-ui-components";
import { FormattedMessage } from "react-intl";
import { AvailableAppInstanceFragment } from "src/types.g";
import DateTime from "src/components/DateTime";
import { TagLabelList } from "src/components/AppPicker/components/TagLabelList";
import { useGetScreenSize } from "src/hooks/useGetScreenSize";

interface MediaTitleProps {
  app: AvailableAppInstanceFragment;
  onClickTag?: (tag: string) => void;
}

export const MediaTitle = ({ app, onClickTag }: MediaTitleProps) => {
  const { isTabletView } = useGetScreenSize();

  const hasTags = app.tags.length > 0;
  return (
    <div className="media-title">
      <div
        className={`title-container ${!hasTags || isTabletView ? "title-container--block" : ""}`}
      >
        <div className="title-container__name">{app.name}</div>
        <span className="media-item__date media-item__date--new">
          <FormattedMessage
            id="common.text.created_on"
            defaultMessage="Created on"
          />{" "}
          <DateTime value={app.createdAt} format={DatetimeFormat.Long} />
        </span>
      </div>
      {!isTabletView && (
        <TagLabelList
          tags={app.tags}
          onClickHandler={onClickTag || (() => {})}
        />
      )}
    </div>
  );
};
