import { useCallback, useState } from "react";
import { Button, Icon } from "@screencloud/screencloud-ui-components";
import webPlayerImg from "src/components/DeviceInformationModal/images/web-player.png";
import allDevicesImg from "src/components/DeviceInformationModal/images/all-devices.png";
import allPlayersImg from "src/components/DeviceInformationModal/images/all-players.png";
import scOSdevicImg from "src/images/store/station-p1-pro.png";
import { PrimaryButton } from "src/helpers/whiteLabel";
import ScosStoreModal, { openScosStore } from "src/components/ScosStore";
import { useAppContext } from "src/hooks/useAppContext";

import {
  ContentWrapper,
  CardStyled,
  DeviceInformationModalStyled,
  Header,
  Footer,
} from "./style";

interface CardProps {
  imageSource: string;
  title: string;
  description: string;
  callbackButtonTitle: string;
  callback: () => void;
  backgroundColor?: string;
  isScOsCard?: boolean;
  className?: string;
}

interface DeviceInformationModalProps {
  onCloseDeviceInformationModal: () => void;
  isDuringOnboarding?: boolean;
  screenPairing?: any;
}

const Card = (props: CardProps) => {
  const {
    imageSource,
    title,
    description,
    callbackButtonTitle,
    callback,
    backgroundColor,
    className,
  } = props;
  const information = (
    <>
      <div className="thumbnail" />
      <div className="description">
        <div className="top-section">
          <div className="title">{title}</div>
          <div className="subtext">{description}</div>
        </div>
        <Button transparent>
          <span>{callbackButtonTitle}</span>
          <Icon name="arrow-up-right-circle" />
        </Button>
      </div>
    </>
  );
  return (
    <CardStyled
      className={className}
      backgroundColor={backgroundColor ?? "#fff"}
      imageSource={imageSource}
      onClick={callback}
    >
      {information}
    </CardStyled>
  );
};

export const DeviceInformationModal = (props: DeviceInformationModalProps) => {
  const context = useAppContext();
  const { onCloseDeviceInformationModal, isDuringOnboarding, screenPairing } =
    props;

  const [isPairingScreenModalOpen, setIsScreenPairingModalOpen] =
    useState(false);
  const [isScOsStoreModalOpen, setIsScOsStoreModalOpen] = useState(false);
  const onVisitStoreClick = () => {
    if (!isDuringOnboarding) {
      openScosStore({
        context,
        onCloseModal: onCloseDeviceInformationModal,
        isFromDeviceInformationModal: true,
      });
    } else {
      setIsScOsStoreModalOpen(true);
    }
  };

  const deviceInformationModal = () => {
    return (
      <DeviceInformationModalStyled data-testid="device-information-modal">
        <Header data-testid="device-information-header">
          <span>Find Your Perfect Hardware</span>
        </Header>
        <ContentWrapper data-testid="device-information-options">
          <Card
            imageSource={webPlayerImg}
            title="Web Player"
            description="Use your browser as the starting point to power your digital signage."
            callbackButtonTitle="Open in new tab"
            callback={() =>
              window.open(
                "https://player.screen.cloud/browser/index.html",
                "_blank",
              )
            }
            backgroundColor="#9FD9DC"
          />
          <Card
            imageSource={allPlayersImg}
            title="Download Player"
            description="Download ScreenCloud Player from your device's app store."
            callbackButtonTitle="Download now"
            callback={() =>
              window.open("https://screencloud.com/download", "_blank")
            }
            backgroundColor="#74C0FF"
          />
          <Card
            imageSource={allDevicesImg}
            title="All Devices"
            description="All compatible device options, from consumer to professional grade media player."
            callbackButtonTitle=" Browse compatible devices"
            callback={() =>
              window.open("https://screencloud.com/devices", "_blank")
            }
            backgroundColor="#FEF0C0"
          />
          <Card
            imageSource={scOSdevicImg}
            title="Keep it simple with ScreenCloud OS"
            description="A purpose-built operating system designed to deliver amazing performance and enterprise-grade features."
            callbackButtonTitle="Visit Store"
            callback={onVisitStoreClick}
            className="scos"
          />
        </ContentWrapper>
        <Footer data-testid="device-information-footer">
          <PrimaryButton
            data-testid="close-button"
            onClick={() => {
              if (!isDuringOnboarding) {
                onCloseDeviceInformationModal();
              } else {
                setIsScreenPairingModalOpen(true);
              }
            }}
          >
            {isDuringOnboarding ? "Continue" : "Got It"}
          </PrimaryButton>
        </Footer>
      </DeviceInformationModalStyled>
    );
  };

  if (!isDuringOnboarding) {
    return deviceInformationModal();
  }

  const getView = useCallback(() => {
    if (isScOsStoreModalOpen) {
      return (
        <ScosStoreModal
          customCloseButton={
            <Button
              className="custom-close-button"
              onClick={() => {
                setIsScreenPairingModalOpen(false);
                setIsScOsStoreModalOpen(false);
              }}
              data-testid="close-button"
              icon
            >
              <Icon name="close" />
            </Button>
          }
        />
      );
    }
    if (isPairingScreenModalOpen) {
      return screenPairing;
    }
    return deviceInformationModal();
  }, [isPairingScreenModalOpen, isScOsStoreModalOpen, screenPairing]);

  return getView();
};
