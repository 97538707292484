import { useAppContext } from "src/hooks/useAppContext";
import { PIXI_TRIALIST_PREFERENCES_KEYS } from "src/pages/Welcome/constants";
import { isTrialist } from "src/pages/Welcome/utils";
import { useUpdateOrgPixiTrialistDataMutation } from "src/types.g";
import { openVirtualScreen } from "src/utils/virtualScreen";

export function useVirtualScreen() {
  const context = useAppContext();
  const industry = context.currentOrg?.preferences?.settings?.industry_name;
  const [updateOrgPixiTrialistData] = useUpdateOrgPixiTrialistDataMutation();

  const hasLaunchedVirtualScreen =
    (context.currentOrg?.preferences?.pixi_trialist &&
      context.currentOrg?.preferences?.pixi_trialist[
        PIXI_TRIALIST_PREFERENCES_KEYS.LAUNCHED_VIRTUAL_SCREEN
      ]) === true;

  const shouldDisplay = isTrialist(context) || hasLaunchedVirtualScreen;

  const launchScreen = async () => {
    if (!hasLaunchedVirtualScreen) {
      await updateOrgPixiTrialistData({
        variables: {
          input: {
            key: PIXI_TRIALIST_PREFERENCES_KEYS.LAUNCHED_VIRTUAL_SCREEN,
            value: true,
          },
        },
      });
    }
    openVirtualScreen(industry);
  };

  return {
    shouldDisplay,
    launchScreen,
  };
}
