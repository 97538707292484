import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

const BaseCardStyle = styled.div`
  display: flex;
  padding: var(--spacing-xxl, 40px);
  flex-direction: column;
  width: 500px;
  height: 285px;
  gap: var(--spacing-lg, 16px);
  border-radius: var(--radius-lg, 16px);
  flex: 1 0 0;
  .card-button {
    margin: 0 !important;
    &.launch-button {
      display: flex;
      height: var(--size-lg, 44px);
      width: 250px;
      padding: 0px var(--spacing-xl, 24px);
      justify-content: center;
      align-items: center;
      border-radius: var(--radius-xl, 32px);
      border: 1px solid var(--color-border, #c5c6ca);
      background: var(--color-background, #fff);
      box-shadow:
        0px -4px 0px 0px rgba(0, 0, 0, 0.08) inset,
        0px 1px 2px 0px var(--color-black-5, rgba(0, 0, 0, 0.05));
      .button-content {
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }
  }
  margin: 40px 20px;
  @media (max-width: ${Theme.break.small}) {
    margin: 0;
  }
`;

export const PairScreenCardStyled = styled(BaseCardStyle)`
  justify-content: space-around;
  align-items: center;
  border: 4px solid #000;
  background: radial-gradient(
    384.35% 125.23% at 5.92% 8.63%,
    #565656 0%,
    #000 100%
  );
  @media (max-width: ${Theme.break.small}) {
    width: 350px;
  }
`;

export const Title = styled.div`
  color: var(--Logo-White, #fff);
  text-align: center;
  font-size: var(--font-size-lg, 20px);
  font-style: normal;
  font-weight: 900;
  line-height: var(--font-line-height-lg, 28px);
`;

export const Description = styled.div`
  color: var(--Logo-White, #fff);
  text-align: center;
  max-width: 250px;
  font-size: var(--font-size-sm, 14px);
  font-style: normal;
  font-weight: 400;
  line-height: var(--font-line-height-sm, 20px);
`;

export const VirtualScreenCardStyled = styled(BaseCardStyle)`
  justify-content: flex-start;
  background: linear-gradient(135deg, #8364ff 0%, #7451ff 100%);
`;

export const VirtualScreenCardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  width: 170px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: var(--spacing-xxl, 40px);
`;

export const VirtualScreenTitle = styled(Title)`
  text-align: left;
  align-self: stretch;
  font-size: 19px;
`;

export const VirtualScreenDescription = styled(Description)`
  text-align: left;
`;

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 210px;
  align-items: center;
`;
