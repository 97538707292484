import { shuffleArray } from "src/helpers/array";
import { AppConfig } from "..";
import { AppContextState } from "../../../../AppContextProvider";
import {
  AllAppsQuery,
  AllAppsQueryResult,
  AppDefaultInstallType,
  Maybe,
} from "../../../../types.g";
import {
  ADDITIONAL_INFO_PRO,
  ALL_APPS_CATEGORY_ID,
  DISCOVER_APPS_CATEGORY_ID,
  PREMIUM_APPS_CATEGORY_ID,
  SortingOption,
  SortingType,
} from "../constants";
import { RawPrismicApp } from "../types";

type App = NonNullable<
  NonNullable<AllAppsQueryResult["data"]>["allApps"]
>["nodes"][0];

const hasStoreData = (app: App): boolean => {
  return Boolean(app.appVersionByAppId?.storeData);
};

const matchesSearchCriteria = ({
  appName,
  criteria,
}: {
  appName: string;
  criteria: string;
}) => {
  const isCriteriaMatched = appName
    .toLowerCase()
    .includes(criteria.toLowerCase());
  return isCriteriaMatched;
};

const hasAllRequiredFeatureFlagsCheck = (
  context: AppContextState,
  app: App,
): boolean => {
  return app.requirements?.every(
    (requirement) => requirement && context.shouldShowFeature(requirement),
  );
};

const hasSomeRequiredFeatureFlagsCheck = (
  context: AppContextState,
  app: App,
): boolean => {
  return app.requirements?.some(
    (requirement) => requirement && context.shouldShowFeature(requirement),
  );
};

export const filterUnavailableAppsOut = ({
  context,
  app,
  criteria,
}: {
  context: AppContextState;
  app: App;
  criteria: string;
}): boolean => {
  const baseValidation =
    hasStoreData(app) && matchesSearchCriteria({ appName: app.name, criteria });
  const flattenAppName = (name: string) =>
    name.toLowerCase().split(" ").join("");
  const appName = flattenAppName(app.name);

  if (
    appName === flattenAppName("Team News (Legacy)") ||
    appName === flattenAppName("Team News Uploader (Legacy)")
  ) {
    return hasAllRequiredFeatureFlagsCheck(context, app) && baseValidation;
  }
  if (appName === flattenAppName("Team News")) {
    return hasSomeRequiredFeatureFlagsCheck(context, app) && baseValidation;
  }
  return baseValidation;
};

export const filterEmptyItems = function <T>(items: Maybe<T>[]): T[] {
  return items.filter((item) => !!item) as T[];
};

export const getTotalAppsCount = ({
  context,
  allApps,
  criteria,
}: {
  context: AppContextState;
  allApps: AllAppsQuery["allApps"];
  criteria: string;
}) => {
  if (!allApps) {
    return 0;
  }

  const appData = filterEmptyItems(allApps.nodes);
  const filterApps = appData.filter((app) =>
    filterUnavailableAppsOut({ context, app, criteria }),
  );

  return filterApps.length;
};

export const sortAppBy = (
  sortType: SortingType,
  apps: NonNullable<
    NonNullable<AllAppsQueryResult["data"]>["allApps"]
  >["nodes"],
) => {
  const sortOption = SortingOption[sortType];
  return apps.sort((appA, appB) => {
    if (appA[sortOption] < appB[sortOption]) {
      return -1;
    }
    if (appA[sortOption] > appB[sortOption]) {
      return 1;
    }
    return 0;
  });
};

export const isEmptyFilterResult = (
  appCategories: string[],
  filterCategory?: string,
): boolean => {
  const lowerCaseCategories = appCategories.map((cat) => cat.toLowerCase());
  return (
    filterCategory !== undefined &&
    filterCategory !== ALL_APPS_CATEGORY_ID &&
    filterCategory !== DISCOVER_APPS_CATEGORY_ID &&
    !lowerCaseCategories.includes(filterCategory.toLowerCase())
  );
};

export const extractUniqueCategoriesFromApps = (
  allApps: NonNullable<
    NonNullable<AllAppsQueryResult["data"]>["allApps"]
  >["nodes"],
): { id: string; name: string; count: number }[] => {
  const categories = {};

  allApps.forEach((app) => {
    // Use a Set to ensure each category is only counted once per app
    const uniqueCategories = new Set(app.categories);
    uniqueCategories.forEach((category) => {
      if (!categories[category as string]) {
        categories[category as string] = [app.name];
      } else {
        (categories[category as string] as any[]).push(app.name);
      }
    });
  });

  const result = Object.keys(categories).map((category) => ({
    id: category,
    name: category,
    count: categories[category].length,
  }));

  return result;
};

export const getAppInformationState = ({
  app,
  spaceId,
}: {
  app: App;
  spaceId: string;
}): AppConfig => {
  const manifest = app.appVersionByAppId?.manifestJson ?? {};
  const configuration = {
    ...manifest.configuration,
    schema: manifest.schema ?? {},
  };

  const teamAppInstalled = app.appInstallsByAppId?.nodes.find((appInstall) =>
    app.defaultInstall === AppDefaultInstallType.Always
      ? !appInstall?.spaceId
      : appInstall?.spaceId === spaceId,
  );

  const storeData = app.appVersionByAppId?.storeData ?? null;

  const appInstallURL =
    app.appInstallsByAppId?.nodes.length && teamAppInstalled
      ? `/apps/${teamAppInstalled.id}`
      : undefined;

  return {
    appInstallURL,
    categories: (app.categories ?? []).filter(
      (cat): cat is string => cat !== null,
    ),
    id: app.id,
    name: app.name,
    scrn: app.scrn,
    thumbnail: app.iconUrl ?? "",
    url: app.appVersionByAppId!.viewerUrl ?? "",
    configuration: JSON.stringify(configuration),
    additionalInfo: storeData?.additionalInfo ?? "",
    shortDescription: storeData?.shortDescription ?? "",
    longDescription: storeData?.description ?? "",
    screenshots: storeData?.screenshotUrls ?? [],
    supportUrl: storeData?.supportUrl ?? "",
    features: storeData?.features ?? [],
    howToUrl: storeData?.howToUrl ?? "",
    feedbackUrl: storeData?.feedbackUrl ?? "",
    faqs: storeData?.faqs ?? [],
    schemeColor: storeData?.schemeColor ?? "",
  };
};

export const getSimilarApps = ({
  appId,
  spaceId,
  allApps,
}: {
  appId: string;
  spaceId: string;
  allApps: NonNullable<
    NonNullable<AllAppsQueryResult["data"]>["allApps"]
  >["nodes"];
}): AppConfig[] => {
  const inputApp = allApps.find((app) => app.id === appId);

  if (!inputApp) {
    return [];
  }

  const inputCategories = inputApp.categories || [];

  const similarApps = allApps
    .filter((app) => app.id !== appId) // Exclude the input app
    .filter((app) => {
      const appCategories = app.categories || [];
      return appCategories.some((category) =>
        inputCategories.includes(category),
      );
    })
    .map((app) => getAppInformationState({ app, spaceId }));

  const proApps = similarApps.filter(
    (app) => app.additionalInfo === ADDITIONAL_INFO_PRO,
  );
  const nonProApps = shuffleArray<AppConfig>(
    similarApps.filter((app) => app.additionalInfo !== ADDITIONAL_INFO_PRO),
  );

  const result = [...proApps, ...nonProApps].slice(0, 6);

  return result;
};

export const isSpecialCategory = (categoryName: string): boolean => {
  const lowerCaseName = categoryName.toLowerCase();
  return (
    lowerCaseName === DISCOVER_APPS_CATEGORY_ID ||
    lowerCaseName === PREMIUM_APPS_CATEGORY_ID
  );
};

export const sortByPublicationDate = (a: RawPrismicApp, b: RawPrismicApp) => {
  if (!a.first_publication_date || !b.first_publication_date) {
    return 0;
  }
  return (
    new Date(b.first_publication_date).getTime() -
    new Date(a.first_publication_date).getTime()
  );
};
export const getAppId = (appName: string, allApps: AllAppsQuery["allApps"]) => {
  if (!allApps || !allApps.nodes) {
    return undefined;
  }
  const name = appName === "Broadcast" ? "ScreenCloud Broadcast" : appName;
  return allApps.nodes.find(
    (app) => app.name.toLowerCase() === name.toLowerCase(),
  )?.id;
};

export const getFilteredAndSortedApps = (
  rawPrismicApps: RawPrismicApp[],
  filterFn: (app: RawPrismicApp) => boolean,
  sortFn?: (a: RawPrismicApp, b: RawPrismicApp) => number,
) => {
  let filteredApps = rawPrismicApps.filter(filterFn);
  if (sortFn) {
    filteredApps = filteredApps.sort(sortFn);
  }
  return filteredApps.map(({ data }) => data.item_name[0].text);
};
