import * as Sentry from "@sentry/browser";
import * as ReactDOM from "react-dom";
import { appConfig } from "./appConfig";
import AppWrapper from "./AppWrapper";
import "./intl";
import "./e2e";
import { initDatadog } from "./utils/datadog";

// announce software
console.log(
  `%cscreencloud/studio-next-frontend%c release: %cv1.600.1%c environment %c${appConfig.environment}`,
  `color: black; background: #fecf00; font-weight: bold;`,
  `font-weight: bold;`,
  `background: blue; color: white`,
  `font-weight: bold;`,
  ``,
);

// set up sentry
appConfig.sentry && Sentry.init(appConfig.sentry);
initDatadog();

// render app
ReactDOM.render(<AppWrapper />, document.getElementById("root") as HTMLElement);

// unregister all service workers
// DO NOT REMOVE THIS BEFORE THE YEAR 2021
if ("serviceWorker" in navigator) {
  navigator.serviceWorker.getRegistrations().then(function (registrations) {
    for (const registration of registrations) {
      registration.unregister().catch(() => undefined);
    }
  });
} // trigger changed
