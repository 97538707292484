import styled from "styled-components";

export const Styled = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 4px;
`;

export const CapsuleLabelStyled = styled.span`
  cursor: pointer;
  background-color: #fff;
  color: #000 !important;
  border-radius: 15px;
  border: 1px solid var(--color-gray-100, "#E2E3E5");
  padding: 4px 8px;
  white-space: nowrap;
  font-weight: 500;
  font-size: var(--font-size-xs, 12px) !important;
  font-style: normal !important;

  &:hover {
    background-color: #f0f0f0;
  }
`;
